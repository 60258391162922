@import 'cms/components/search-results';

@mixin vans-search-results {
	section.searchresults {
		.article-search-input {
			display: inline-block;
		}

		#article-list-header-content {
			h1 {
				@include setFontStyle($FranklinCondFontStyle);
				font-size: em-calc(16, 13);
				letter-spacing: em-calc(1, 13);
				text-transform: uppercase;
			}

			.articles-found-results-summary {
				text-align: center;
				width: 100%;

				@include breakpoint(medium) {
					text-align: left;
					width: 20%;
				}

				@include breakpoint(large) {
					width: 34%;
				}

				label {
					display: inline;
					font-size: em-calc(13);
					padding: 0 0.5em;
				}
			}

			hr {
				display: none;
			}

			.search-count {
				font-size: 0.8125em;
			}
		}

		.sort-by-section {
			.prefix {
				float: right;
				padding-right: 0.5em;
			}
		}

		.search-results-summary {
			@include grid-column($columns: 12);

			@include breakpoint(medium) {
				@include grid-column($columns: 7);
			}

			@include breakpoint(large) {
				@include grid-column($columns: 6);
			}
		}

		.wall-filters {
			border-bottom: 1px solid get-color(regent-st-blue);
			border-top: 1px solid get-color(regent-st-blue);
			margin-bottom: 20px;
			margin-top: $vertSpace;
			overflow: hidden;
			padding: 0 20px;
			position: relative;

			@include breakpoint(medium) {
				padding: 0;
			}

			ul {
				display: none;
				list-style: none;
				margin: 0;
				overflow: hidden;
				padding: 0;

				&.active {
					display: block;
					margin: 20px 0;
				}

				@include breakpoint(medium) {
					display: block;
				}
			}

			li {
				color: $freeText;
				padding: 0;
				font-size: em-calc(12.81);
				float: left;
				cursor: pointer;
				line-height: 40px;

				&::after {
					content: '\00a0\00a0/\00a0\00a0';
					color: get-color(light-grey);
				}

				&:last-child {
					&::after {
						content: '';
					}
				}

				&.active {
					color: get-color(primary);
				}

				&:hover {
					color: get-color(primary);
				}
			}
		}

		.no-results-recommendation {
			text-align: center;
			font-size: 1.5em;
			font-weight: 700;
		}

		.section-title {
			font-size: 0.875em;
			line-height: 1;
			margin-bottom: 0;
			text-rendering: optimizeLegibility;
			letter-spacing: 2px;
			color: get-color(primary);
		}

		.num-articles {
			display: none;
		}
	}
}
