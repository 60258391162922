@import "global/toppanels";

.l-top {
	&.mini-cart-section {
		position: fixed;

		@include breakpoint(medium) {
			position: fixed;
		}
	}

	.mini-cart-content {
		.mini-cart-item-container {
			.mini-cart-remove-item-text {
				color: $black;
				font-size: 0.65em;
				padding: 0;
				position: absolute;
				right: 2px;
				text-decoration: none;
				top: 0;
				width: auto;
			}
		}

		.mini-cart-content-head {
			margin-top: 0;
		}

		.cart-list .item-price {
			color: get-color(dim-gray);
		}

		.cart-item {
			position: relative;
		}

		.mini-cart-actions {
			margin-top: 20px;

			.mini-cart-actions-item {
				margin: 0;
				padding: 0;

				.mini-cart-action {
					margin: 0;
				}
			}
		}
	}
}

#global-cart-wishlist-panels.l-top .mini-cart-content .cart-list {
	.cart-subtotal {
		margin-bottom: $vertSpaceSmall;
		padding: 0;
	}

	.cart-item-col {
		width: grid-column(6);
	}
}


