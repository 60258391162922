// *****************************
// * Past Events List
// *****************************

$tablet-column-gutter: em-calc(24px) !default;
$mobile-column-gutter: em-calc(40px) !default;

@mixin vans-listing-past-events {
	section.listingpastevents {
		#cd-related-wrap {
			background: $white;
			position: relative;
			@include clearfix;
			clear: both;
		}

		#cd-related-content {
			@include grid-column(12, 0);
			margin: 0 auto;
			padding: {
				top: 72px;
				bottom: 40px;
				left: $mobile-column-gutter;
				right: 0;
			}

			@include breakpoint(medium) {
				@include grid-column-f4($columns: 12, $float: false, $collapse: true);
				padding-left: $tablet-column-gutter;
			}

			@include breakpoint(large) {
				padding-left: 0;
			}

			.cd-related-item {
				@include grid-column($columns: 6);
				padding-top: 0;
				padding-right: $mobile-column-gutter;
				padding-bottom: 20px;
				padding-left: 0px;

				@include breakpoint(medium) {
					padding-right: $tablet-column-gutter;
				}

				@include breakpoint(large) {
					@include grid-column-f4($columns: 3, $collapse: false);
				}

				img {
					width: 100%;
					height: auto;
					margin-bottom: 15px;
				}

				p.description {
					color: $freeText;
					display: none;
					letter-spacing: 0.5px;
					font-size: em-calc(12);
					margin-bottom: 15px;

					@include breakpoint(medium) {
						display: block;
					}
				}

				.event-date {
					color: get-color(primary);
					font-size: em-calc(12);
					font-weight: normal;
					margin-bottom: 5px;

					sup {
						position: relative;
						top: -2px;
					}
				}

				.header {
					@include setFontStyle($FranklinCondFontStyle);
					color: $fontGeneric;
					font-size: em-calc(22);
					text-transform: uppercase;
					line-height: em-calc(24, 22);
					margin-bottom: 5px;
				}
			}
		}
	}
}
