// =================
// Right Mini Cart
// -----------------
$rightPanelEnabled: false !default;
$globalCartWishlistPanelMobilePosition: absolute !default;
$globalBodyOverlay: 203 !default;
$globalCartWishlistPanelMobileZIndex: $globalBodyOverlay + 1 !default;
$miniListCartActionsPayPalBtnMarginTop: 15px !default;
$miniListCartActionsOrTextMargin: 15px 0 0 !default;
$miniCartPaypalBtnMargin: 0 !default;
$miniCartPaypalBtnPadding: 15px 20px !default;
$miniCartRightPanelWavedSepBorderBottom: null !default;
$miniCartRightPanelSectionTitleFontSize: null !default;
$miniCartRightPanelSectionTitleLetterSpacing: null !default;
$miniCartRightPanelPageTitleLetterSpacing: null !default;
$miniCartRightPanelContentPadding: null !default;
$miniCartRightPanelAttrColor: null !default;
$miniCartRightPanelAttrFontWeight: null !default;
$miniCartItemNameColor: null !default;
$miniCartItemNameFontFamily: null !default;
$miniCartItemNameFontSize: null !default;
$miniCartItemNameFontWeight: null !default;
$miniCartItemAttrColor: null !default;
$miniCartItemPriceFontWeight: null !default;
$miniCartRightPanelItemPriceColor: null !default;
$miniCartWavedSepMargin: null !default;
$miniCartSubtotalColor: null !default;
$miniCartSubtotalFontFamily: null !default;
$miniCartSubtotalFontSize: null !default;
$miniCartSubtotalLetterSpacing: null !default;
$miniCartActionsPadding: null !default;
$mobile-topbar-width: null !default;
$mobile-topbar-left: null !default;
$mobile-topbar-right: null !default;
$miniListNotificationMobileFontWeight: null !default;
$miniListBorderRadius: null !default;
$mobile-topbar-left-medium: null !default;

@if($rightPanelEnabled) {
	#global-body-overlay {
		background-color: #000;
		cursor: pointer;
		height: 100%;
		opacity: 0.5;
		position: fixed;
		right: 0;
		top: 0;
		width: 0%;
		z-index: $globalBodyOverlay;
	}

	.mini-cart {
		&-tag {
			&-qty {
				clear: both;
				display: inline;
				line-height: 1em;
				margin: 0 auto;
			}
		}

		&-content {
			padding: $miniCartRightPanelContentPadding;
		}

		&-section.l-right {
			background-color: $miniListNotificationMobileBkgdColor;
			border-radius: $miniListBorderRadius;
			display: none;
			height: auto;
			left: $mobile-topbar-left;
			margin-right: 0;
			position: $globalCartWishlistPanelMobilePosition;
			right: $mobile-topbar-right;
			top: $mobile-topbar-height;
			width: $mobile-topbar-width;
			z-index: $globalCartWishlistPanelMobileZIndex;

			@include breakpoint(medium) {
				background-color: $rightMiniCartBackgroundColor;
				display: block;
				height: 100%;
				left: $mobile-topbar-left-medium;
				margin-right: -$miniCartWidth;
				position: fixed;
				right: 0;
				top: 0;
				width: $miniCartWidth;
			}

			@media only screen and (max-height: 639px) and (orientation: landscape) {
				background-color: $miniListNotificationMobileBkgdColor;
				display: none;
				height: auto;
				margin-right: 0;
				position: absolute;
				top: $mobile-topbar-height;
				width: 100%;
			}

			ul.tags {
				display: none;
				left: -35px;
				list-style: none;
				list-style-type: none;
				margin: 0;
				position: absolute;
				top: 275px;
				width: 35px;

				@include breakpoint(medium) {
					display: block;
				}

				li {
					background-color: $button-primary;
					color: #fff;
					cursor: pointer;
					float: none;
					list-style: none;
					margin: 0 0 1px;
					padding: 12px 0;
					text-align: center;

					&.cart {
						background-color: $button-cta;
					}

					&.wishlist {
						background-color: #1c1c1c;
					}

					&.inactive {
						opacity: 0.6;
					}
				}
			}

			.cart-list { //styling for right panel

				.mini-cart-ajax-messaging {
				  .message {
				    color: $fontSuccess;
				    font-size: 1em;
				    font-weight: normal;
				  }
				}

				ul.tags {
					list-style-type: none;
				}

				.content-head {
					margin-bottom: 30px;
				}

				.content-foot {
					margin: 20px 0;
				}

				.section-title {
					color: $button-cta;
					font-size: $miniCartRightPanelSectionTitleFontSize;
				}

				.waved-line-sep {
					background: $BGSectionSeperatorA;
					border: 0;
					border-bottom: $miniCartRightPanelWavedSepBorderBottom;
					display: $miniCartWavedSepDisplay;
					margin: $miniCartWavedSepMargin;
					padding-top: 5px;
				}

				.cart-item-nav {
					float: left;
					width: 100%;

					&.next {
						margin-top: 10px;
					}
				}

				.cart-item-sep {
					margin: 20px ($column-gutter / 2) 0;
				}

				.mini-cart-item-attr {
					color: $miniCartItemAttrColor;
				}

				.mini-cart-item-attr-span,
				.mini-cart-item-qty-span {
					color: $miniCartRightPanelAttrColor;
					font-weight: $miniCartRightPanelAttrFontWeight;
				}

				.mini-cart-item-name-text {
					color: $miniCartItemNameColor;
					font-family: $miniCartItemNameFontFamily;
					font-size: $miniCartItemNameFontSize;
					font-weight: $miniCartItemNameFontWeight;
				}

				.mini-cart-item-price {
					color: $miniCartRightPanelItemPriceColor;
					font-weight: $miniCartItemPriceFontWeight;
				}

				.cart-item-col {
					width: grid-column(6);

					.item-name {
						@include setFontStyle($miniCartItemTitleFontStyle);
					}

					.wishlist-original-price {
						color: $originalPriceColor;
						display: block;
						text-decoration: line-through;
					}

					.wishlist-current-price {
						color: $currentPriceFontColor;
						display: block;
					}
				}

				.cart-subtotal {
					font-size: $miniCartSubtotalFontSize;
					padding: 10px 0 20px;
					text-align: center;
					font-family: $miniCartSubtotalFontFamily;
					color: $miniCartSubtotalColor;
					letter-spacing: $miniCartSubtotalLetterSpacing;
				}

				.mini-cart-actions {
					padding: $miniCartActionsPadding;
				}
			}

			.mini-cart-promo-header {
				@include setFontStyle($miniCartPromoHeaderFontStyle);
				border-top: $BGSectionSeperatorB;
				margin-top: 40px;
				padding-top: 40px;
			}

			.notification {
				background-color: transparent;
				bottom: 0;
				box-sizing: border-box;
				color: $miniListNotificationMobileColor;
				font-weight: $miniListNotificationMobileFontWeight;
				padding: 30px 15px 20px;
				position: relative;

				@include breakpoint(medium) {
					background-color: $notificationBGColor;
					bottom: 15px;
    				padding: 15px 20px;
					position: absolute;
					z-index: 2;
				}

				.icon-close {
					@include close-button;
					@include close-button-size(small);
					color: $miniListNotificationMobileCloseBtnColor;

					@include breakpoint(medium) {
						display: none;
					}
				}

				.product-name,
				.shopping-cart-text {
					color: $miniListNotificationMobileItemAttributeColor;
				}
			}
		}
	}
}

.cart-actions-item {

	#orText {
		color: #8d9293;
		font-weight: bold;
		margin: $miniListCartActionsOrTextMargin;
	}
}

.share-cart-container {
	h2 {
		letter-spacing: 2px;
		margin: 20px 0;
	}

	.custom-item-notification {
		background-color: $backgroundColorGeneric;
		margin-bottom: 20px;
		padding: 15px;
	}

	label {
		font-weight: bold;
		text-transform: uppercase;
	}

	textarea {
		color: $fontLink;
		height: auto;
	}
}
