@import 'global/z-indexes';

//---------------------------------------------------------------------------
// bloomreach
//---------------------------------------------------------------------------
$relatedSearchesLineHeight: 1.2em !default;
$relatedSearchesLineHeightMedium: 1.2em !default;
$relatedSearchesTitleFontFamily: inherit !default;
$relatedSearchesTitleFontSize: em-calc(12px) !default;
$relatedSearchesTitleFontSizeMedium: em-calc(12px) !default;
$relatedSearchesTitleFontSizeLarge: null !default;
$relatedSearchesTitleFontColor: $black !default;
$relatedSearchesTitleFontStyle: inherit !default;
$relatedSearchesTitleFontWeight: bold !default;
$relatedSearchesTitleTextTransform: uppercase !default;
$relatedSearchesTitleLetterSpacing: inherit !default;
$relatedSearchesTitleLineHeight: null !default;
$relatedSearchesTitleMargin: 0 0 20px !default;
$relatedSearchesLinksFontFamily: inherit !default;
$relatedSearchesLinksFontSize: em-calc(10px) !default;
$relatedSearchesLinksFontSizeMedium: em-calc(10px) !default;
$relatedSearchesLinksFontColor: null !default;
$relatedSearchesMargin: null !default;
$relatedSearchesMarginMedium: $relatedSearchesMargin !default;
$relatedSearchesLinksFontStyle: inherit !default;
$relatedSearchesLinksFontWeight: inherit !default;
$relatedSearchesLinksMargin: inherit !default;
$relatedSearchesLinksTextTransform: null !default;
$relatedSearchesLinkHoverDecoration: null  !default;
$relatedSearchesLinkHoverColor: null  !default;
$relatedSearchesPadding: null !default;
$relatedSearchesMediumPadding: null !default;
$relatedSearchesbrrcPadding: null !default;
$relatedSearchesbrrcPaddingLarge: rem-calc(2) 0 !default;
$brRelatedCategoriesWidgetMinWidthLarge: null !default;

$relatedItemLineHeight: 1.2em !default;
$relatedItemsTitleFontFamily: inherit !default;
$relatedItemsTitleFontSize: em-calc(12px) !default;
$relatedItemsTitleFontSizeMedium: null !default;
$relatedItemsTitleFontColor: null !default;
$relatedItemsTitleFontStyle: inherit !default;
$relatedItemsTitleFontWeight: bold !default;
$relatedItemsTitleTextTransform: uppercase !default;
$relatedItemsPopupTitleTextTransform: uppercase !default;
$relatedItemsPopupTitleFontSize: rem-calc(17) !default;
$relatedItemsTitleLetterSpacing: null !default;
$relatedItemsTitleMarginBottom: rem-calc(20) !default;
$relatedItemsTitleMarginLeft: null !default;
$relatedItemsTitleMarginLeftMedium: null !default;
$relatedItemsTitleTextStyle: italic !default;
$relatedItemsProductFontFamily: inherit !default;
$brSfWidgetMerchantTitleFontFamily: $relatedItemsProductFontFamily !default;
$relatedItemsProductFontSize: em-calc(10px) !default;
$relatedItemsProductDescFontSize: $relatedItemsProductFontSize !default;
$relatedItemsProductDescFontSizeMedium: $relatedItemsProductFontSize !default;
$relatedItemsProductFontColor: null !default;
$relatedItemsProductFontStyle: inherit !default;
$relatedItemsProductFontWeight: null !default;
$relatedItemsProductFontWeight: null !default;
$relatedItemsDescFontWeight: $relatedItemsProductFontWeight !default;
$relatedItemsProductTextTransform: capitalize !default;
$relatedItemsTitlePadding: null !default;
$relatedItemsTitlePaddingLarge: $relatedItemsTitlePadding !default;
$relatedItemsTitleMarginRight: null !default;
$relatedItemsTitleLineHeight: null !default;
$relatedItemsTitleWidthMedium: null !default;
$relatedItemsProductLetterSpacing: null !default;
$relatedItemsProductLineHeight: null !default;

$relatedResultsTitlePadding: null !default;
$relatedResultsHeadingBorder: null !default;
$relatedResultsHeadingTextAlign: null !default;
$relatedResultItemsTitlePadding: null !default;

$showMoreTitleFontFamily: inherit !default;
$showMoreTitleFontWeight: inherit !default;
$showMoreTitleFontSize: em-calc(9px) !default;
$showMoreTitleTextDecoration: null !default;
$showMoreTitleFontColor: null !default;
$showMoreTitleFontStyle: normal !default;
$showMoreTitleLetterSpacing: inherit !default;

$brPopupCloseColor: grey  !default;
$brPopupCloseHoverColor: null  !default;
$brRelatedSearcheborderRight: 1px solid grey !default;
$brRelatedSearchWidthMedium: 17% !default;
$brProductCompareWidthMedium: 79% !default;
$brSearchWidgetDisplay: none !default;
$brSearchWidgetDisplayMedium: none !default;
$brSearchWidgetBackground: null !default;
$brSearchWidgetBorderTop: null !default;
$brSearchWidgetPadding: null !default;
$brSearchWidgetMediumPadding: null !default;
$brSearchWidgetMarginTop: 25px !default;
$brSearchWidgetRelatedCategoriesDisplay: null !default;
$brSearchWidgetMarginBottom: 25px !default;
$brSfWidgetImageBorder: null !default;
$brSfWidgetImageMargin: null !default;
$brSfWidgetImageWidth: 50% !default;
$brSfWidgetImageWidthMedium: null !default;
$brSfWidgetImageHeightMedium: null !default;
$brSfWidgetContentWidth: 50% !default;
$brSfWidgetContentWidthMedium: null !default;
$brSfWidgetMerchantTitleMedium: null !default;
$brSfWidgetMerchantDescPadding: null !default;
$brSfWidgetContentMargin: null !default;
$brSfWidgetProductWidth: null !default;
$brSfWidgetThreeProductWidthLarge: null !default;
$brSfWidgetFourProductWidthLarge: null !default;
$brSfWidgetFourProductWidthXLarge: null !default;
$brSfWidgetFourProductMarginRightLarge: null !default;
$brSfWidgetFourProductPaddingTopLarge: null !default;
$brSfWidgetProductWidthMedium: null !default;
$brSfWidgetProductWidthLarge: grid-column(3) !default;
$brSfWidgetProductCycleSlideMargin: null !default;
$brSfWidgetProductCycleSlidePaddingTop: null !default;
$brSfWidgetProductCycleSlideWidth: null !default;
$brSfWidgetFourProductMarginRight: null !default;
$brSfWidgetFourProductPadding: null !default;
$brSfWidgetTwoProductWidthLarge: null !default;
$xLarge: null !default;
$brSfWidgetThreeProductPadding: null !default;
$brSfWidgetMerchantContText: right !default;
$brSfWidgetMerchantTitleFontColor: $relatedItemsProductFontColor !default;
$brSfWidgetMerchantTitleFloat: left !default;
$brSfWidgetMerchantDescFloat: left !default;
$brSfWidgetMerchantQvFloat: right !default;
$brSfWidgetMerchantQvFontSize: null !default;
$brSfWidgetMerchantDescDisplay: null !default;
$brSfWidgetMerchantDescDisplayMedium: null !default;
$relatedItemsProductDescFontWeightMedium: null !default;
$brSfWidgetDescLineHeight: null !default;
$brSfWidgetDescLineHeightMedium: null !default;
$brSfWidgetDescLetterSpacing: null !default;
$brSfWidgetMerchantDescDisplayLarge: null !default;
$brSfWidgetMerchantDescHeightLarge: null !default;
$brSfWidgetMerchantDescHeightMedium: null !default;
$brRpQvShowMarginTop: -205px !default;
$brRpQvShowPosition: null !default;
$brRpQvShowTop: null !default;
$brRpQvShowTransform: translateX(-50%) !default;
$brRpQvShowBackground: $white !default;
$brRpQvShowWidth: 98% !default;
$brRpQvShowWidthMedium: rem-calc(450) !default;
$brRpQvShowWidthLarge: rem-calc(500) !default;
$brSfWidgetMerchantPopupLinkAsButton: false  !default;
$brSfWidgetMerchantPopupMainContPadding: null !default;
$brSfWidgetMerchantPopupImgBackgroundColorLarge: null !default;
$brSfWidgetMerchantPopupImgFloat: left !default;
$brSfWidgetMerchantPopupImgFloatLarge: null !default;
$brSfWidgetMerchantPopupImgMarginLarge: null !default;
$brSfWidgetMerchantPopupImgHeight: null !default;
$brSfWidgetMerchantPopupImgAlign: null !default;
$brSfWidgetMerchantPopupDescFloat: right !default;
$brSfWidgetMerchantPopupDescOverflow: null !default;
$brSfWidgetMerchantPopupDescPadding: 5px 20px !default;
$brSfWidgetMerchantPopupDescMarginMedium: null !default;
$brSfWidgetMerchantPopupDescWidth: 62% !default;
$brSfWidgetMerchantPopupDescMargin: null !default;
$brSfWidgetMerchantPopupDescPaddingMedium: null !default;
$brSfWidgetMerchantPopupDescWidthMedium: 100% !default;
$brSfWidgetMerchantPopupDescMaxHeight: null !default;
$brSfWidgetMerchantPopupViewFloat: right !default;
$brSfWidgetMerchantPopupViewPadding: null !default;
$brSfWidgetMerchantPopupViewMargin: 20px auto !default;
$brSfWidgetMerchantPopupViewMarginMedium: 20px 0 !default;
$brSfWidgetMerchantPopupViewPaddingMedium: null !default;
$brSfWidgetMerchantPopupViewDisplayLarge: null !default;
$brSfWidgetMerchantPopupViewLinkWeight: null !default;
$brSfWidgetMerchantPopupViewLinkMargin: null !default;
$brSfWidgetMerchantPopupViewLinkDisplay: null !default;
$brSfWidgetMerchantPopupImgWidth: 100 - $brSfWidgetMerchantPopupDescWidth !default;
$brSfWidgetMerchantPopupImgPadding: 35px 0 0 0 !default;
$brSfWidgetMerchantPopupImgTagWidth: null !default;
$brSfWidgetMerchantPopupTitlePadding: 35px 20px 10px !default;
$brSfWidgetMerchantPopupTitleMargin: null !default;
$brSfWidgetMerchantPopupTitlePaddingMedium: 50px 20px 10px !default;
$brSfWidgetMerchantPopupCloseBoder: null !default;
$brSfWidgetMerchantPopupClosePadding: null !default;
$brSfWidgetMerchantPopupCloseGutter: 12px !default;
$brRelatedSearchesWidgetMargin: 0 20px 20px !default;
$brRelatedSearchesWidgetMarginMedium: 0 20px 20px !default;
$brRelatedSearchesWidgetPadding: 0 $column-gutter / 2 !default;
$brRelatedSearchesWidgetPaddingMedium: 0 $column-gutter / 2 !default;
$brWidgetResultItemLastItemMargin: null !default;
$brWidgetResultItemMarginMedium: null !default;
$brWidgetResultItemPaddingMedium: null !default;
$brSearchWidgetContainerFlexDirectionLargeDisplay: block !default;
$brSearchWidgetContainerFlexDirectionMedium: null !default;
$brSearchWidgetContainerFlexDirectionLarge: row !default;
$brSearchRelatedItemsWidgetOrderMedium: null !default;
$brSearchRelatedCategoriesWidgetOrderMedium: null !default;
$brSearchMoreResultWidgetOrderMedium: null !default;
$brSearchWidgetContainerHeightMedium: null !default;
$brSearchRelatedCategoriesWidgetWidthMedium: null !default;
$brRelatedCategoriesWidgetWidthLarge: null !default;
$brSearchRelatedCategoriesWidgetPaddingMedium: null !default;
$brSearchRelatedCategoriesWidgetGridAreaMedium: null !default;
$brSearchRelatedCategoriesWidgetBorderTopMedium: null !default;
$brSearchRelatedItemsWidgetPaddingMedium: null !default;
$brSearchRelatedItemsWidgetGridAreaMedium: null !default;
$brRelatedItemsTitleMargin: null !default;
$brRelatedCategoriesWidgetMarginLeft: null !default;
$brRelatedCategoriesWidgetPaddingLeft: null !default;
$brRelatedItemsWidgetDisplay: null !default;
$relatedSearchesLinkDecoration: null !default;
$relatedSearchesLinksTextDecoration: null !default;
$brProductCompareMaxWidthMedium: null !default;
$brRelatedSearchMaxWidthMedium: null !default;
$brSfWidgetProductWidthMedium: null !default;
$brSfWidgetMerchantQvTextColor: null !default;
$brSfWidgetMerchantQvTextDecoration: null !default;
$brSfWidgetMerchantQvPadding: null !default;
$brSfWidgetProductMaxWidthMedium: null !default;

$brSfWidgetMerchantContainerDisplay: null !default;
$brSfWidgetMerchantContainerFlexDirection: null !default;
$brSfWidgetImageOrder: null !default;
$brSfWidgetMerchantQvOrder: null !default;
$brSfWidgetDescOrder: null !default;
$brSfWidgetMerchantTitleOrder: null !default;
$relatedSearchesLinksLetterSpacing: null !default;
$brWidgetMerchantTitleMinHeight: null !default;
$brSfWidgetMerchantQvTextTransform: null !default;
$brSfWidgetMerchantQvLineHeight: null !default;
$brSfWidgetMerchantQvDisplay: null !default;
$brSfWidgetMerchantQvFirstLineTextTransform: null !default;
$brSfWidgetContentMerchantTitleDisplay: null !default;
$brRelatedSideWidgetsPadding: null !default;
$brRelatedSideWidgetsWidth: null !default;
$relatedItemsProductTextDecoration: null !default;
$brSearchMoreResultWidgetWidthMedium: null !default;
$brSearchMoreResultWidgetPositionMedium: null !default;
$brSfWidgetMerchantQvFontFamily: inherit !default;

$brMobileCarouselAlignItems: null !default;
$brMobileCarouselArrowBackground: none !default;
$brMobileCarouselArrowDisplay: null !default;
$brMobileCarouselArrowHeight: null !default;
$brMobileCarouselArrowNextRight: 7px !default;
$brMobileCarouselArrowNextTextShadow: null !default;
$brMobileCarouselArrowPadding: null !default;
$brMobileCarouselArrowPrevLeft: null !default;
$brMobileCarouselArrowPrevTextShadow: null !default;
$brMobileCarouselArrowTop: 50% !default;
$brMobileCarouselArrowZindex: 99 !default;
$brMobileCarouselContentMargin: null !default;
$brMobileCarouselContentMargin: null !default;
$brMobileCarouselDescLineHeight: null !default;
$brMobileCarouselDisplay: null !default;
$brMobileCarouselHeight: rem-calc(300)!important !default;
$brMobileCarouselInnerContentMinHeight: null !default;
$brMobileCarouselInnerContentMinHeightMedium: null !default;
$brMobileCarouselInnerContentPadding: null !default;
$brMobileCarouselInnerContentPaddingMedium: null !default;
$brMobileCarouselMargin: 0 !default;
$brMobileCarouselOrder: null !default;
$brMobileCarouselWrapPadding: null !default;
$brMobileCarouselMarginWrap: 0 0 0 rem-calc(24)!important !default;
$brMobileCarouselMerchantDescPadding: null !default;
$brMobileCarouselMerchantQvFontFamily: null !default;
$brMobileCarouselMerchantQvFontSize: null !default;
$brMobileCarouselMerchantQvFontWeight: null !default;
$brMobileCarouselMerchantQvTextColor: null !default;
$brMobileCarouselMerchantQvDisplay: null !default;
$brMobileCarouselMerchantQvMargin: null !default;
$brMobileCarouselMerchantQvPadding: null !default;
$brMobileCarouselMerchantQvTextDecoration: null !default;
$brMobileCarouselMerchantTitleFontColor: $relatedItemsProductFontColor !default;
$brMobileCarouselMerchantTitleDisplay: null !default;
$brMobileCarouselMerchantTitleWidth: null !default;
$brMobileCarouselMerchantTitleWidthMedium: null !default;
$brMobileCarouselPadding: 0 !default;
$brMobileCarouselPaddingMedium: null !default;
$brMobileCarouselWidth: 100% !default;
$brMobileCarouselPosition: null !default;
$brMobileCarouselWidthMedium: auto !default;
$brMobileCarouselGridAreaMedium: null !default;
$brMobileCarouselDisplayMedium: null !default;
$brMobileCarouselFlexWrapMedium: null !default;
$brMobileCarouselRelatedItemsBackground: null !default;
$brMobileCarouselRelatedItemsDescFontWeight: null !default;
$brMobileCarouselRelatedItemsProductDescFontSize: null !default;
$brMobileCarouselRelatedItemsProductDescFontFamily: null !default;
$brMobileCarouselRelatedItemsProductFontFamily: null !default;
$brMobileCarouselRelatedItemsProductFontColor: null !default;
$brMobileCarouselRelatedItemsProductFontSize: null !default;
$brMobileCarouselRelatedItemsProductFontSizeMedium: null !default;
$brMobileCarouselRelatedItemsProductFontWeight: null !default;
$brMobileCarouselRelatedItemsProductLineHeight: null !default;
$brMobileCarouselRelatedItemsProductTextTransform: null !default;
$brMobileCarouselRelatedItemsTitleFontSize: null !default;
$brMobileCarouselRelatedItemsTitleFontSizeMedium: null !default;
$brMobileCarouselRelatedItemsTitleMargin: null !default;
$brMobileCarouselRelatedItemsTitleFontWeight: null !default;
$brMobileCarouselRelatedItemsTitleTextTransform: null !default;
$brCarouselSearchWidgetContainerDisplayMedium: null !default;
$brCarouselSearchWidgetContainerHeightMedium: null !default;
$brCarouselSearchWidgetContainerGridTemplateColumnsMedium: null !default;
$brCarouselSearchWidgetContainerGridTemplateAreaMedium: null !default;
$brCarouselSearchWidgetContainerMarginMedium: null !default;
$brMobileCarouselTopWrap: rem-calc(50)!important !default;
$brMobileCarouselRelatedItemsDisplay: block !default;
$brMobileCarouselRelatedItemsFlexWrap: null !default;
$brMobileCarouselRelatedItemsFlexWrapMedium: null !default;
$brFoundHeadingFontSize: null !default;
$brFoundHeadingFontSizeMedium: null !default;
$brFoundHeadingTop: 0 !default;
$brFoundHeadingZIndex: 100 !default;
$brSFWidgetMerchantImgBorder: null !default;
$brSFWidgetMerchantImgHeight: null !default;
$brSFWidgetMerchantImgMarginBottom: null !default;
$brSFWidgetMerchantImgMarginBottomMedium: null !default;
$brSFWidgetMerchantImgPadding: null !default;
$brSFWidgetImgMaxWidth: 100% !default;
$brSFWidgetImgMaxWidthMedium: null !default;
$brCarouselDescMarginTop: null !default;

%showmorelink {
    color: $showMoreTitleFontColor;
    font-family: $showMoreTitleFontFamily;
    font-size: $showMoreTitleFontSize;
    font-style: $showMoreTitleFontStyle;
    font-weight: $showMoreTitleFontWeight;
    letter-spacing: $showMoreTitleLetterSpacing;
    text-decoration: $relatedSearchesLinkDecoration;

    &:hover {
        color: $relatedSearchesLinkHoverColor;
        text-decoration: $relatedSearchesLinkHoverDecoration;
    }
}

//Quickview styling
$brQuickViewModalStyle: false !default;
$brCarouselMobileEnabled: false !default;
$brQuickViewModalBackground: null !default;
$brQuickViewModalLeft: 50% !default;
$brQuickViewModalMarginTop: rem-calc(-205) !default;
$brQuickViewModalPosition: absolute !default;
$brQuickViewModalTransform: translateX(-50%) !default;
$brQuickViewModalWidth: rem-calc(500) !default;
$brQuickViewModalMainContMargin: rem-calc(10) !default;
$brQuickViewModalMainContBackground: $white !default;
$brQuickViewModalMainContPosition: relative !default;
$brQuickViewModalContDisplay: inline-table !default;
$brQuickViewModalContHeight: 100% !default;
$brQuickViewModalContPadding: rem-calc(20) !default;
$brQuickViewModalImgWidth: 40% !default;
$brQuickViewModalImgHeight: 100% !default;
$brQuickViewModalImgFloat: left !default;
$brQuickViewModalOtherContWidth: 60% !default;
$brQuickViewModalOtherContFloat: right !default;
$brQuickViewModalOtherContPadding: 0 0 0 rem-calc(12);
$brQuickViewModalViewLinkMargin: 0 0 rem-calc(10) 0 !default;
$brQuickViewModalTitleTextDecoration: none !default;
$brQuickViewModalTitleFontWeight: bold !default;
$brQuickViewModalTitleFontSize: rem-calc(15) !default;
$brQuickViewModalTitleLineHeight: rem-calc(20) !default;
$brQuickViewModalTitleLetterSpacing: 0.55px !default;
$brQuickViewModalTitlePadding: 0 rem-calc(12) 0 rem-calc(12) !default;
$brQuickViewModalTitleMargin: null !default;
$brQuickViewModalOtherContBackground: null !default;
$brQuickViewModalDescFontSize: rem-calc(14) !default;
$brQuickViewModalDescLineHeight: rem-calc(21) !default;
$brQuickViewModalDescLetterSpacing: 0.7px !default;
$brQuickViewModalCloseLinkPosition: absolute !default;
$brQuickViewModalCloseLinkTop: rem-calc(20) !default;
$brQuickViewModalCloseLinkRight: rem-calc(5) !default;
$brQuickViewModalCloseLinkTextDecoration: none !default;
$brSfWidgetMerchantDescMinHeightMedium: null !default;
$brCarouselContainerFontSize: rem-calc(14) !default;
$brCarouselContainerDisplay: null !default;
$brCarouselContainerFlexDirection: null !default;
$brCarouselContainerMaxWidth: 45vw!important !default;
$brCarouselContainerPadding: null !default;
$brCarouselDescPadding: rem-calc(10) 0 !default;
$brCarouselDescLetterSpacing: null !default;
$brCarouselDescLineHeight: null !default;
$brCarouselTitleFontSize: rem-calc(15) !default;

$brCarouselSearchHeadingFontSize: null !default;
$brCarouselSearchHeadingFontSizeMedium: null !default;
$brCarouselSearchHeadingFontWeight: null !default;
$brCarouselSearchHeadingLineHeight: null !default;
$brCarouselSearchHeadingMargin: null !default;
$brCarouselSearchHeadingTextTransform: null !default;
$brCarouselSearchHeadingTextTransformMedium: null !default;
$brCarouselSearchSubHeadingFontSize: null !default;
$brCarouselSearchSubHeadingFontSizeMedium: null !default;
$brCarouselSearchSubHeadingLineHeight: null !default;
$brCarouselSearchSubHeadingMargin: null !default;
$brCarouselSearchSubHeadingDisplay: null !default;
$brCarouselSearchMoreResultsMargin: null !default;
$brCarouselSearchMoreResultsMaxWidth: null !default;
$brCarouselSearchMoreResultsMaxWidthMedium: null !default;
$brCarouselSearchMoreResultsNonCarouselDisplay: null !default;
$brCarouselSearchMerchContFirstChildPaddingLeft: null !default;
$brCarouselSearchMerchContFirstChildPaddingLeftMedium: null !default;
$brCarouselSearchMerchContPadding: null !default;
$brCarouselSearchMerchContPaddingMedium: null !default;
$brCarouselCycleWrapDisplay: null !default;
$brCarouselCycleWrapFlexDirection: null !default;
$brCarouselCycleWrapWhiteSpace: null !default;
$brCarouselSearchMerchContZIndex: null !default;
$brCarouselSearchMerchTitleLineHeight: null !default;
$brCarouselSearchMerchTitleMarginTop: null !default;
$brCarouselSearchMerchTitleLinkColor: null !default;
$brCarouselSearchMerchTitleLinkTextDecoration: null !default;
$brCarouselSearchMerchTitleLinkColorHover: null !default;
$brCarouselSearchMerchPricePaddingBottom: null !default;
$brCarouselSearchMerchPricePaddingBottomMedium: null !default;
$brCarouselSearchMerchPriceLineHeight: null !default;
$brCarouselSearchMerchPriceMarginTop: null !default;
$brCarouselSearchArrowBackground: null !default;
$brCarouselSearchArrowColor: null !default;
$brCarouselSearchArrowPadding: null !default;
$brCarouselSearchArrowPosition: null !default;
$brCarouselSearchArrowTop: null !default;
$brCarouselSearchArrowNextRight: null !default;
$brCarouselSearchArrowNextRightMedium: null !default;
$brSfWidgetMerchantPopupMainContMaxHeight: null !default;
$brSfWidgetMerchantPopupMainContOverflow: null !default;
$product-block-price-original-price-margin-right: 0 !default;
$product-block-price-original-price-margin-right-medium: null !default;
$product-block-price-original-price-margin-right-large: null !default;

@mixin bloomreach-product-quickview-modal {
    .br-rp-qv {
        background: $brQuickViewModalBackground;
        left: $brQuickViewModalLeft;
        margin-top: $brQuickViewModalMarginTop;
        position: $brQuickViewModalPosition;
        transform: $brQuickViewModalTransform;
        width: $brQuickViewModalWidth;

        .br-sf-widget-merchant-popup-maincont {
            background: $brQuickViewModalMainContBackground;
            margin: $brQuickViewModalMainContMargin;
            position: $brQuickViewModalMainContPosition;
        }


        .br-sf-widget-merchant-popup-cont {
            display: $brQuickViewModalContDisplay;
            height: $brQuickViewModalContHeight;
            padding: $brQuickViewModalContPadding;
        }

        .br-sf-widget-merchant-popup-img {
            float: $brQuickViewModalImgFloat;
            height: $brQuickViewModalImgHeight;
            width: $brQuickViewModalImgWidth;
        }

        .br-sf-widget-merchant-popup-title,
        .br-sf-widget-merchant-popup-desc,
        .br-sf-widget-merchant-popup-view {
            float: $brQuickViewModalOtherContFloat;
            padding: $brQuickViewModalOtherContPadding;
            width: $brQuickViewModalOtherContWidth;
        }

        .br-sf-widget-merchant-popup-view {
            margin: $brQuickViewModalViewLinkMargin;
        }

        .br-sf-widget-merchant-popup-title {
          padding: $brQuickViewModalTitlePadding;

            a {
                font-size: $brQuickViewModalTitleFontSize;
                font-weight: $brQuickViewModalTitleFontWeight;
                letter-spacing: $brQuickViewModalTitleLetterSpacing;
                line-height: $brQuickViewModalTitleLineHeight;
                text-decoration: $brQuickViewModalTitleTextDecoration;
            }
        }

        .br-sf-widget-merchant-popup-desc {
            font-size: $brQuickViewModalDescFontSize;
            letter-spacing: $brQuickViewModalDescLetterSpacing;
            line-height: $brQuickViewModalDescLineHeight;
        }

        .br-sf-widget-merchant-popup-close {
            position: $brQuickViewModalCloseLinkPosition;
            right: $brQuickViewModalCloseLinkRight;
            top: $brQuickViewModalCloseLinkTop;

            a {
                text-decoration: $brQuickViewModalCloseLinkTextDecoration;
            }
        }

    }
}

@mixin bloomreach-product-compare {
    .br_search_widget {
        @include grid-row;
        background: $brSearchWidgetBackground;
        border-top: $brSearchWidgetBorderTop;
        clear: both;
        display: $brSearchWidgetDisplay;
        float: none;
        margin-bottom: $brSearchWidgetMarginBottom;
        margin-left: auto;
        margin-right: auto;
        margin-top: $brSearchWidgetMarginTop;
        padding: $brSearchWidgetPadding;
        width: grid-column(12);

        #br-related-categories-widget {
            display: $brSearchWidgetRelatedCategoriesDisplay;
        }


        @include breakpoint(medium) {
            display: $brSearchWidgetDisplayMedium;
            flex-direction: $brSearchWidgetContainerFlexDirectionMedium;
            height: $brSearchWidgetContainerHeightMedium;
            padding: $brSearchWidgetMediumPadding;
        }

        @include breakpoint(large) {
            display: $brSearchWidgetContainerFlexDirectionLargeDisplay;
        }

        #br-related-searches-widget,
        #br-related-categories-widget,
        #br-related-items-widget {
            @include clearfix;
            border-right: 0;
            float: left;
            line-height: $relatedSearchesLineHeight;
            margin: $brRelatedSearchesWidgetMargin;
            padding: $brRelatedSearchesWidgetPadding;
            width: 100%;

            @include breakpoint(medium) {
              margin: $brRelatedSearchesWidgetMarginMedium;
              padding: $brRelatedSearchesWidgetPaddingMedium;
            }

            .br-related-heading,
            .br-ri-heading,
            .br-rc-heading {
                border-bottom: $relatedResultsHeadingBorder;
                color: $relatedSearchesTitleFontColor;
                font-family: $relatedSearchesTitleFontFamily;
                font-size: $relatedSearchesTitleFontSize;
                font-style: $relatedSearchesTitleFontStyle;
                font-weight: $relatedSearchesTitleFontWeight;
                letter-spacing: $relatedSearchesTitleLetterSpacing;
                line-height: $relatedSearchesTitleLineHeight;
                margin: $relatedSearchesTitleMargin;
                padding: $relatedResultsTitlePadding;
                text-align: $relatedResultsHeadingTextAlign;
                text-transform: $relatedSearchesTitleTextTransform;
                
                @include breakpoint(medium) {
                    font-size: $relatedSearchesTitleFontSizeLarge;
                }
            }

            .br-ri-heading {
                margin: $brRelatedItemsTitleMargin;
                padding: $relatedResultItemsTitlePadding;

                @include breakpoint(medium) {
                  font-size: $relatedSearchesTitleFontSizeMedium;
                }
            }

            .br-related-query {
                margin: $relatedSearchesLinksMargin;
            }

            .br-related-query,
            .br-ri,
            .br-rc {
                padding: $relatedSearchesbrrcPadding;

                a {
                    color: $relatedSearchesLinksFontColor;
                    display: inline-block;
                    font-family: $relatedSearchesLinksFontFamily;
                    font-size: $relatedSearchesLinksFontSize;
                    font-style: $relatedSearchesLinksFontStyle;
                    font-weight: $relatedSearchesLinksFontWeight;
                    letter-spacing: $relatedSearchesLinksLetterSpacing;
                    line-height: $relatedSearchesLineHeight;
                    margin: $relatedSearchesMargin;
                    padding: $relatedSearchesPadding;
                    text-decoration: $relatedSearchesLinksTextDecoration;
                    text-transform: $relatedSearchesLinksTextTransform;

                    @include breakpoint(medium) {
                      font-size: $relatedSearchesLinksFontSizeMedium;
                      line-height: $relatedSearchesLineHeightMedium;
                      margin: $relatedSearchesMarginMedium;
                    }

                    &:hover {
                        color: $relatedSearchesLinkHoverColor;
                        text-decoration: $relatedSearchesLinkHoverDecoration;
                    }
                }

                @include breakpoint(large) {
                    display: block;
                    padding: $relatedSearchesbrrcPaddingLarge;
                }

                @include breakpoint(medium) {
                    padding: $relatedSearchesMediumPadding;
                }
            }

            @include breakpoint(medium) {
                border-right: $brRelatedSearcheborderRight;
                margin: $brWidgetResultItemMarginMedium;
				max-width: $brRelatedSearchMaxWidthMedium;
                padding: $brWidgetResultItemPaddingMedium;
                width: $brRelatedSearchWidthMedium;
            }

            #more {
                @extend %showmorelink;
            }
        }

        #br-related-categories-widget {
            margin-left: $brRelatedCategoriesWidgetMarginLeft;
            padding-left: $brRelatedCategoriesWidgetPaddingLeft;
        }

        #br-related-items-widget {
            display: $brRelatedItemsWidgetDisplay;
        }

        @include breakpoint(medium) {
            #br-related-items-widget {
                order: $brSearchRelatedItemsWidgetOrderMedium;
                padding: $brSearchRelatedItemsWidgetPaddingMedium;
                grid-area: $brSearchRelatedItemsWidgetGridAreaMedium;
            }

            #br-related-categories-widget {
                order: $brSearchRelatedCategoriesWidgetOrderMedium;
                padding: $brSearchRelatedCategoriesWidgetPaddingMedium;
                grid-area: $brSearchRelatedCategoriesWidgetGridAreaMedium;
                width: $brSearchRelatedCategoriesWidgetWidthMedium;

                .br-rc-heading {
                    border-top: $brSearchRelatedCategoriesWidgetBorderTopMedium;
                }
            }

            #br-more-results-widget {
                order: $brSearchMoreResultWidgetOrderMedium;
                position: $brSearchMoreResultWidgetPositionMedium;
                width: $brSearchMoreResultWidgetWidthMedium;
            }
        }

        @include breakpoint(large) {
            #br-related-categories-widget {
                min-width: $brRelatedCategoriesWidgetMinWidthLarge;
                width: $brRelatedCategoriesWidgetWidthLarge;
            }
        }

        .br-found-heading {
            border-bottom: $relatedResultsHeadingBorder;
            color: $relatedItemsTitleFontColor;
            font-family: $relatedItemsTitleFontFamily;
            font-size: $relatedItemsTitleFontSize;
            font-style: $relatedItemsTitleFontStyle;
            font-weight: $relatedItemsTitleFontWeight;
            letter-spacing: $relatedItemsTitleLetterSpacing;
            line-height: $relatedItemsTitleLineHeight;
            margin-bottom: $relatedItemsTitleMarginBottom;
            margin-left: $relatedItemsTitleMarginLeft;
            padding: $relatedResultsTitlePadding;
            text-align: $relatedResultsHeadingTextAlign;
            text-transform: $relatedItemsTitleTextTransform;

            @include breakpoint(medium) {
                font-size: $relatedItemsTitleFontSizeMedium;
                margin-left: $relatedItemsTitleMarginLeftMedium;
            }
        }

        & #br-more-results-widget > div:nth-last-child(-n + 3) {
            margin-right: $brWidgetResultItemLastItemMargin;
        }

        .br-sf-widget {
            float: left;
            height: auto;
            line-height: $relatedItemLineHeight;
            margin-right: $relatedItemsTitleMarginRight;
            padding: $relatedItemsTitlePadding;
            width: 100%;

            @include breakpoint(medium) {
             width: $relatedItemsTitleWidthMedium;
            }

            &.br-sf-widget-product-4 {
                &.cycle-slide {
                    margin: $brSfWidgetProductCycleSlideMargin;
                    padding-top: $brSfWidgetProductCycleSlidePaddingTop;
                    width: $brSfWidgetProductCycleSlideWidth;
                }
                @include breakpoint(medium) {
                    float: left;
                    margin-right: $brSfWidgetFourProductMarginRight;
                    max-width: $brSfWidgetProductMaxWidthMedium;
                    padding: $brSfWidgetFourProductPadding;
                    width: $brSfWidgetProductWidthMedium;

                }

                @include breakpoint(large) {
                    margin-right: $brSfWidgetFourProductMarginRightLarge;
                    padding-top: $brSfWidgetFourProductPaddingTopLarge;
                    width: $brSfWidgetFourProductWidthLarge;
                }

                @include breakpoint(xlarge) {
                    width: $brSfWidgetFourProductWidthXLarge;
                }
            }

            &.br-sf-widget-product-3 {
                @include breakpoint(large) {
                    width: $brSfWidgetThreeProductWidthLarge;
                }
            }

            &.br-sf-widget-product-2,
            &.br-sf-widget-product-1 {
                @include breakpoint(large) {
                    width: $brSfWidgetTwoProductWidthLarge;
                }
            }

            @include breakpoint(medium) {
                width: $brSfWidgetProductWidthMedium;
            }

            @include breakpoint(large) {
                float: left;
                padding: $relatedItemsTitlePaddingLarge;
                width: $brSfWidgetProductWidthLarge;
            }

            .br-sf-widget-merchant-cont {
                @include clearfix;
                @include breakpoint(medium) {
                    text-align: $brSfWidgetMerchantContText;
                }

                @include breakpoint(large) {
                    text-align: left;
                }

                display: $brSfWidgetMerchantContainerDisplay;
                flex-direction: $brSfWidgetMerchantContainerFlexDirection;

                .br-sf-widget-merchant-img {
                    border: $brSfWidgetImageBorder;
                    float: left;
                    margin: $brSfWidgetImageMargin;
                    order: $brSfWidgetImageOrder;
                    width: $brSfWidgetImageWidth;

                    @include breakpoint(medium) {
                        height: $brSfWidgetImageHeightMedium;
                        width: $brSfWidgetImageWidthMedium;
                    }
                }

                .br-sf-widget-merchant-title {
                    display: $brSfWidgetContentMerchantTitleDisplay;
                    margin: $brSfWidgetContentMargin;
                    order: $brSfWidgetMerchantTitleOrder;
                    width: $brSfWidgetContentWidth;

                    a {
                        color: $brSfWidgetMerchantTitleFontColor;
                        float: $brSfWidgetMerchantTitleFloat;
                        font-family: $brSfWidgetMerchantTitleFontFamily;
                        font-size: $relatedItemsProductFontSize;
                        font-style: $relatedItemsProductFontStyle;
                        font-weight: $relatedItemsProductFontWeight;
                        letter-spacing: $relatedItemsProductLetterSpacing;
                        line-height: $relatedItemsProductLineHeight;
                        min-height: $brWidgetMerchantTitleMinHeight;
                        text-decoration: $relatedItemsProductTextDecoration;
                        text-transform: $relatedItemsProductTextTransform;
                    }

                    @include breakpoint(medium) {
                        padding: $brSfWidgetMerchantTitleMedium;
                        width: $brSfWidgetContentWidthMedium;
                    }
                }

                .br-sf-widget-merchant-desc {
                    color: $relatedItemsProductFontColor;
                    display: $brSfWidgetMerchantDescDisplay;
                    float: $brSfWidgetMerchantDescFloat;
                    font-family: $relatedItemsProductFontFamily;
                    font-size: $relatedItemsProductDescFontSize;
                    font-style: $relatedItemsProductFontStyle;
                    font-weight: $relatedItemsDescFontWeight;
                    letter-spacing: $brSfWidgetDescLetterSpacing;
                    line-height: $brSfWidgetDescLineHeight;
                    margin: $brSfWidgetContentMargin;
                    order: $brSfWidgetDescOrder;
                    padding:  $brSfWidgetMerchantDescPadding;
                    text-transform: $relatedItemsProductTextTransform;
                    width: $brSfWidgetContentWidth;

                    @include breakpoint(medium) {
                        display: $brSfWidgetMerchantDescDisplayMedium;
                        font-size: $relatedItemsProductDescFontSizeMedium;
                        font-weight: $relatedItemsProductDescFontWeightMedium;
                        line-height: $brSfWidgetDescLineHeightMedium;
                        max-height: $brSfWidgetMerchantDescHeightMedium;
                        min-height: $brSfWidgetMerchantDescMinHeightMedium;
                        overflow: hidden;
                        width: $brSfWidgetContentWidthMedium;
                    }

                    @include breakpoint(large) {
                        display: $brSfWidgetMerchantDescDisplayLarge;
                        max-height: $brSfWidgetMerchantDescHeightLarge;
                    }
                }

                .br-sf-widget-merchant-title a {
                    &:hover {
                        color: $relatedSearchesLinkHoverColor;
                    }
                }

                .br-sf-widget-merchant-qv {
                    float: $brSfWidgetMerchantQvFloat;
                    font-size: $brSfWidgetMerchantQvFontSize;
                    font-family: $brSfWidgetMerchantQvFontFamily;
                    margin: $brSfWidgetContentMargin;
                    order: $brSfWidgetMerchantQvOrder;
                    width: $brSfWidgetContentWidth;

                    @include breakpoint(medium) {
                        width: $brSfWidgetContentWidthMedium;
                    }

                    a {
                        @extend %showmorelink;

                        color: $brSfWidgetMerchantQvTextColor;
                        display: $brSfWidgetMerchantQvDisplay;
                        line-height: $brSfWidgetMerchantQvLineHeight;
                        padding: $brSfWidgetMerchantQvPadding;
                        text-decoration: $brSfWidgetMerchantQvTextDecoration;
                        text-transform: $brSfWidgetMerchantQvTextTransform;

                        &::first-line {
                          text-transform: $brSfWidgetMerchantQvFirstLineTextTransform;
                        }
                    }
                }
            }
        }

        .br-rp-qv-show {
            background: $brRpQvShowBackground;
            box-shadow: 0 0 10px #888888;
            left: 50%;
            margin-top: $brRpQvShowMarginTop;
            position: $brRpQvShowPosition;
            top: $brRpQvShowTop;
            transform: $brRpQvShowTransform;
            width: $brRpQvShowWidth;
            z-index: 1001;

            @include breakpoint(medium) {
                width: $brRpQvShowWidthMedium;
            }

            @include breakpoint(large) {
                width: $brRpQvShowWidthLarge;
            }

            .br-sf-widget-merchant-popup-maincont {
                line-height: 1.2em;
                max-height: $brSfWidgetMerchantPopupMainContMaxHeight;
                overflow: $brSfWidgetMerchantPopupMainContOverflow;
                padding: $brSfWidgetMerchantPopupMainContPadding;
                position: relative;
                width: 100%;

                .br-sf-widget-merchant-popup-img {
                    border: $brSfWidgetImageBorder;
                    float: $brSfWidgetMerchantPopupImgFloat;
                    height: $brSfWidgetMerchantPopupImgHeight;
                    text-align: $brSfWidgetMerchantPopupImgAlign;
                    width: $brSfWidgetMerchantPopupImgWidth;

                    @include breakpoint(large) {
                        background-color: $brSfWidgetMerchantPopupImgBackgroundColorLarge;
                        float: $brSfWidgetMerchantPopupImgFloatLarge;
                        margin: $brSfWidgetMerchantPopupImgMarginLarge;
                    }

                    img {
                        padding: $brSfWidgetMerchantPopupImgPadding;
                        width: $brSfWidgetMerchantPopupImgTagWidth;
                    }
                }

                .br-sf-widget-merchant-popup-title {
                    background: $brQuickViewModalOtherContBackground;
                    margin: $brSfWidgetMerchantPopupTitleMargin;
                    padding: $brSfWidgetMerchantPopupTitlePadding;


                    @include breakpoint(medium) {
                        padding: $brSfWidgetMerchantPopupTitlePaddingMedium;
                    }

                    a {
                        color: $relatedItemsTitleFontColor;
                        font-family: $relatedItemsTitleFontFamily;
                        font-size: $relatedItemsPopupTitleFontSize;
                        font-style: $relatedItemsTitleTextStyle;
                        font-weight: $relatedItemsTitleFontWeight;
                        letter-spacing: $relatedItemsTitleLetterSpacing;
                        text-transform: $relatedItemsPopupTitleTextTransform;
                    }
                }

                .br-sf-widget-merchant-popup-desc {
                    background: $brQuickViewModalOtherContBackground;
                    float: $brSfWidgetMerchantPopupDescFloat;
                    font-size: $brQuickViewModalDescFontSize;
                    line-height: $brQuickViewModalDescLineHeight;
                    margin: $brSfWidgetMerchantPopupDescMargin;
                    overflow: $brSfWidgetMerchantPopupDescOverflow;
                    padding: $brSfWidgetMerchantPopupDescPadding;
                    width: $brSfWidgetMerchantPopupDescWidth;

                    @include breakpoint(medium) {
                        float: none;
                        margin: $brSfWidgetMerchantPopupDescMarginMedium;
                        padding: $brSfWidgetMerchantPopupDescPaddingMedium;
                        width: $brSfWidgetMerchantPopupDescWidthMedium;
                    }

                    @include breakpoint(large) {
                        max-height: $brSfWidgetMerchantPopupDescMaxHeight;
                    }

                    a {
                        color: $relatedItemsProductFontColor;
                        font-family: $relatedItemsProductFontFamily;
                        font-size: 11px;
                        font-style: $relatedItemsProductFontStyle;
                        font-weight: $relatedItemsProductFontWeight;
                        text-transform: $relatedItemsProductTextTransform;
                    }
                }

                .br-sf-widget-merchant-popup-view {
                    background: $brQuickViewModalOtherContBackground;
                    float: $brSfWidgetMerchantPopupViewFloat;
                    margin: $brSfWidgetMerchantPopupViewMargin;
                    padding: $brSfWidgetMerchantPopupViewPadding;

                    @include breakpoint(medium) {
                        float: none;
                        margin: $brSfWidgetMerchantPopupViewMarginMedium;
                        padding: $brSfWidgetMerchantPopupViewPaddingMedium;
                    }

                    @include breakpoint(large) {
                        display: $brSfWidgetMerchantPopupViewDisplayLarge;
                    }

                    a {
                        @if $brSfWidgetMerchantPopupLinkAsButton {
                            @include button;
                            font-weight: $brSfWidgetMerchantPopupViewLinkWeight;
                            margin: $brSfWidgetMerchantPopupViewLinkMargin;
                            display: $brSfWidgetMerchantPopupViewLinkDisplay;
                        } @else {
                            color: $showMoreTitleFontColor;
                            font-size: $showMoreTitleFontSize;
                            font-style: normal;
                            font-weight: $showMoreTitleFontWeight;
                            letter-spacing: $showMoreTitleLetterSpacing;
                            text-decoration: $showMoreTitleTextDecoration;
                        }
                    }
                }

                .br-sf-widget-merchant-popup-close {
                    border: $brSfWidgetMerchantPopupCloseBoder;
                    padding: $brSfWidgetMerchantPopupClosePadding;
                    position: absolute;
                    right: $brSfWidgetMerchantPopupCloseGutter;
                    top: $brSfWidgetMerchantPopupCloseGutter;

                    a {
                        color: transparent;
                        position: relative;

                        &:before {
                          color: $brPopupCloseColor;
                          content: $icon-close;
                          font-family: icomoon;
                          position: absolute;
                          right: 0;
                        }

                        &:hover {
                            color: $brPopupCloseHoverColor;
                        }
                    }
                }
            }
        }
    }

    .br-rp-qv-hide {
        display: none;
    }

    #br-related-side-widgets {
        padding: $brRelatedSideWidgetsPadding;
        width: $brRelatedSideWidgetsWidth;
    }

    @if ($brQuickViewModalStyle) {
        @include bloomreach-product-quickview-modal;
    }

    @if ($brCarouselMobileEnabled) {
       @include bloomreach-carousel-mobile;
    }
}

@mixin bloomreach-product-compare-flexbox {
    #br-more-results-widget {
        @include clearfix;
        width: 79%;

        @include breakpoint(medium) {
          max-width: $brProductCompareMaxWidthMedium;
          width: $brProductCompareWidthMedium;
        }
    }

    // Moves this element to the end of the row for proper wrapping
    #br-related-categories-widget {
        order: 1;
    }

    .br_search_widget {
        @include breakpoint(large) {
        display: flex;
        flex-direction: $brSearchWidgetContainerFlexDirectionLarge;
        flex-wrap: wrap;
        }
    }

    #br-related-searches-widget,
    #br-related-categories-widget,
    #br-related-items-widget {
        float: none;
        width: auto;
    }

    .br-sf-widget-merchant-title {
        float: left;
    }
}

@mixin bloomreach-carousel-mobile {

    #br_search_widget {

      #br-related-items-widget .br-ri-heading,
      #br-related-categories-widget .br-rc-heading,
      #br-more-results-widget .br-found-heading {
        font-size: $brMobileCarouselRelatedItemsTitleFontSize;
        font-weight: $brMobileCarouselRelatedItemsTitleFontWeight;
        margin: $brMobileCarouselRelatedItemsTitleMargin;
        text-transform: $brMobileCarouselRelatedItemsTitleTextTransform;
        @include breakpoint(medium) {
            font-size: $brMobileCarouselRelatedItemsTitleFontSizeMedium;
        }
      }

      background: $brMobileCarouselRelatedItemsBackground;
      display: $brMobileCarouselRelatedItemsDisplay;
      flex-wrap: $brMobileCarouselRelatedItemsFlexWrap;

        @include breakpoint(medium) {
            display: $brCarouselSearchWidgetContainerDisplayMedium;
            flex-wrap: $brMobileCarouselRelatedItemsFlexWrapMedium;
            height: $brCarouselSearchWidgetContainerHeightMedium;
            grid-template-columns: $brCarouselSearchWidgetContainerGridTemplateColumnsMedium;
            grid-template-areas: $brCarouselSearchWidgetContainerGridTemplateAreaMedium;
            margin: $brCarouselSearchWidgetContainerMarginMedium;
        }

        #br-more-results-widget {
            height: $brMobileCarouselHeight;
            margin: $brMobileCarouselMargin;
            order: $brMobileCarouselOrder;
            padding: $brMobileCarouselPadding;
            position: $brMobileCarouselPosition;
            width: $brMobileCarouselWidth;

            @include breakpoint(medium) {
              display: $brMobileCarouselDisplayMedium;
              flex-wrap: $brMobileCarouselFlexWrapMedium;
              grid-area: $brMobileCarouselGridAreaMedium;
              padding: $brMobileCarouselPaddingMedium;
              width: $brMobileCarouselWidthMedium;
            }

            .br-found-heading {
                font-size: $brFoundHeadingFontSize;
                position: absolute;
                top: $brFoundHeadingTop;
                z-index: $brFoundHeadingZIndex;

                @include breakpoint(medium) {
                    font-size: $brFoundHeadingFontSizeMedium;
                }
            }

            .cycle-carousel-wrap {
                align-items: $brMobileCarouselAlignItems;
                display: $brMobileCarouselDisplay;
                margin: $brMobileCarouselMarginWrap;
                padding: $brMobileCarouselWrapPadding;
                top: $brMobileCarouselTopWrap;
                white-space: normal !important;
            }

            .arrow {
                background: $brMobileCarouselArrowBackground;
                color: $black;
                position: absolute;
                top: $brMobileCarouselArrowTop;
                padding: $brMobileCarouselArrowPadding;
                display: $brMobileCarouselArrowDisplay;
                height: $brMobileCarouselArrowHeight;
                z-index: $brMobileCarouselArrowZindex;
            }

            .arrow-next {
                right: $brMobileCarouselArrowNextRight;
            }

            .arrow-prev {
                left: $brMobileCarouselArrowPrevLeft;
            }

            .br-sf-widget-merchant-cont {
                display: $brCarouselContainerDisplay;
                flex-direction: $brCarouselContainerFlexDirection;
                font-size: $brCarouselContainerFontSize;
                max-width: $brCarouselContainerMaxWidth;
                padding: $brCarouselContainerPadding;
                // Not able to use the mixing since we need the important to override the inline value
                z-index: 90 !important; // sass-lint:disable-line no-important

                .br-sf-widget-merchant-img {
                    border: $brSFWidgetMerchantImgBorder;
                    height: $brSFWidgetMerchantImgHeight;
                    margin: 0;
                    margin-bottom: $brSFWidgetMerchantImgMarginBottom;
                    padding: $brSFWidgetMerchantImgPadding;

                    @include breakpoint(medium) {
                        margin-bottom: $brSFWidgetMerchantImgMarginBottomMedium;
                    }

                    img {
                         max-width: $brSFWidgetImgMaxWidth;
                         @include breakpoint(medium) {
                            max-width: $brSFWidgetImgMaxWidthMedium;
                        }
                    }
                }

                .br-sf-widget-merchant-title {
                    font-size: $brCarouselTitleFontSize;
                }

                .br-sf-widget-merchant-desc {
                    letter-spacing: $brCarouselDescLetterSpacing;
                    line-height: $brCarouselDescLineHeight;
                    margin-top: $brCarouselDescMarginTop;
                    padding: $brCarouselDescPadding;
                }

                .br-sf-widget-merchant-img,
                .br-sf-widget-merchant-title,
                .br-sf-widget-merchant-desc,
                .br-sf-widget-merchant-qv {
                    float: none;
                    min-height: $brMobileCarouselInnerContentMinHeight;
                    padding: $brMobileCarouselInnerContentPadding;
                    width: 100%;

                    @include breakpoint(medium) {
                        min-height: $brMobileCarouselInnerContentMinHeightMedium;
                        padding: $brMobileCarouselInnerContentPaddingMedium;
                    }
                }
            }

            .br-sf-widget-merchant-title {
                margin: $brMobileCarouselContentMargin;

                a {
                    color: $brMobileCarouselMerchantTitleFontColor;
                    display: $brMobileCarouselMerchantTitleDisplay;
                    font-family: $brMobileCarouselRelatedItemsProductFontFamily;
                    font-size: $brMobileCarouselRelatedItemsProductFontSize;
                    font-weight: $brMobileCarouselRelatedItemsProductFontWeight;
                    line-height: $brMobileCarouselRelatedItemsProductLineHeight;
                    width: $brMobileCarouselMerchantTitleWidth;
                    @include breakpoint(medium) {
                        font-size: $brMobileCarouselRelatedItemsProductFontSizeMedium;
                        width: $brMobileCarouselMerchantTitleWidthMedium;
                    }
                }
            }

            .br-sf-widget-merchant-desc {
                color: $brMobileCarouselRelatedItemsProductFontColor;
                font-family: $brMobileCarouselRelatedItemsProductDescFontFamily;
                font-size: $brMobileCarouselRelatedItemsProductDescFontSize;
                font-weight: $brMobileCarouselRelatedItemsDescFontWeight;
                line-height: $brMobileCarouselDescLineHeight;
                margin: $brMobileCarouselContentMargin;
                padding: $brMobileCarouselMerchantDescPadding;
                text-transform: $brMobileCarouselRelatedItemsProductTextTransform;
            }

            .br-sf-widget-merchant-qv {
                a {
                    color: $brMobileCarouselMerchantQvTextColor;
                    display: $brMobileCarouselMerchantQvDisplay;
                    font-family: $brMobileCarouselMerchantQvFontFamily;
                    font-size: $brMobileCarouselMerchantQvFontSize;
                    font-weight: $brMobileCarouselMerchantQvFontWeight;
                    margin: $brMobileCarouselMerchantQvMargin;
                    padding: $brMobileCarouselMerchantQvPadding;
                    text-decoration: $brMobileCarouselMerchantQvTextDecoration;
                }
            }
        }
    }
}

@mixin bloomreach-recommendation-carousel {
    #br_search_widget {
        .br-search-heading {
            font-size: $brCarouselSearchHeadingFontSize;
            font-weight: $brCarouselSearchHeadingFontWeight;
            line-height: $brCarouselSearchHeadingLineHeight;
            margin: $brCarouselSearchHeadingMargin;
            text-transform: $brCarouselSearchHeadingTextTransform;

            @include breakpoint(medium) {
                font-size: $brCarouselSearchHeadingFontSizeMedium;
                text-transform: $brCarouselSearchHeadingTextTransformMedium;
            }
        }

        .br-search-sub-text {
            display: $brCarouselSearchSubHeadingDisplay;
            font-size: $brCarouselSearchSubHeadingFontSize;
            line-height: $brCarouselSearchSubHeadingLineHeight;
            margin: $brCarouselSearchSubHeadingMargin;

            @include breakpoint(medium) {
                font-size: $brCarouselSearchSubHeadingFontSizeMedium;
            }
        }

        #br-more-results-widget {
            margin: $brCarouselSearchMoreResultsMargin;
            max-width: $brCarouselSearchMoreResultsMaxWidth;

            @include breakpoint(medium) {
                max-width: $brCarouselSearchMoreResultsMaxWidthMedium;
            }

            &:not(.carousel-container) {
                display: $brCarouselSearchMoreResultsNonCarouselDisplay;
            }

            .cycle-carousel-wrap {
                display: $brCarouselCycleWrapDisplay;
                flex-direction: $brCarouselCycleWrapFlexDirection;
                white-space: $brCarouselCycleWrapWhiteSpace;
            }

            .cycle-carousel-wrap .br-sf-widget-merchant-cont:first-child {
                padding-left: $brCarouselSearchMerchContFirstChildPaddingLeft;

                @include breakpoint(medium) {
                    padding-left: $brCarouselSearchMerchContFirstChildPaddingLeftMedium;
                }
            }

            .br-sf-widget-merchant-cont {
                padding: $brCarouselSearchMerchContPadding;
                z-index: $brCarouselSearchMerchContZIndex;

                @include breakpoint(medium) {
                    padding: $brCarouselSearchMerchContPaddingMedium;
                }

                .br-sf-widget-merchant-title {
                    line-height: $brCarouselSearchMerchTitleLineHeight;
                    margin-top: $brCarouselSearchMerchTitleMarginTop;

                    a {
                        color: $brCarouselSearchMerchTitleLinkColor;
                        text-decoration: $brCarouselSearchMerchTitleLinkTextDecoration;
                        white-space: normal;

                        &:hover {
                            color: $brCarouselSearchMerchTitleLinkColorHover;
                        }
                    }
                }

                .br-sf-widget-merchant-price {
                    line-height: $brCarouselSearchMerchPriceLineHeight;
                    margin-top: $brCarouselSearchMerchPriceMarginTop;
                    padding-bottom: $brCarouselSearchMerchPricePaddingBottom;

                    @include breakpoint(medium) {
                        padding-bottom: $brCarouselSearchMerchPricePaddingBottomMedium;
                    }

                    .product-price {
                        .original-price {
                            color: $originalPriceColor;
                            margin-right: $product-block-price-original-price-margin-right;
                            text-decoration: line-through;
 
                            @include breakpoint(medium) {
                                margin-right: $product-block-price-original-price-margin-right-medium;
                            }

                            @include breakpoint(large) {
                                margin-right: $product-block-price-original-price-margin-right-large;
                            }
                        }

                        p {
                            display: inline-block;
                        }
                
                        .current-price {
                            color: $currentPriceFontColor;
                        }
                    }
                }
            }

            .arrow-icon {
                background: $brCarouselSearchArrowBackground;
                color: $brCarouselSearchArrowColor;
                padding: $brCarouselSearchArrowPadding;
                position: $brCarouselSearchArrowPosition;
                top: $brCarouselSearchArrowTop;

                &.arrow-prev {
                  text-shadow: $brMobileCarouselArrowPrevTextShadow;
                }

                &.arrow-next {
                    right: $brCarouselSearchArrowNextRight;
                    text-shadow: $brMobileCarouselArrowNextTextShadow;

                    @include breakpoint(medium) {
                        right: $brCarouselSearchArrowNextRightMedium;
                    }
                }
            }
        }
    }
}
