@import 'catalog/quickview';

$productImgA: 583px !default;
$btnFontWeight: bold !default;

// Quick View
$quickviewModalAlternativeDesign: true !default;

//mobile-first
#quickview-box {
	.product-content-info-header {
		@include setFontStyle($productTitleH1Style);
	}

	.product-price {
		span {
			&.no-discount {
				color: $originalPriceColor;
			}
		}
	}

	#product-views-container {
		.product-img-full {
			cursor: pointer;

			.promo-badge {
				position: absolute;
				right: 20px;
				top: 20px;
			}
		}

		.product-view-control {
			margin: 0;
			width: $productImgA;
		}

		.product-img-alt-view {
			.s7-view {
				margin-right: 20px;

				.attr-box {
					height: auto;
					margin-right: 0;
					width: auto;

					&.selected {
						border-radius: 0;
					}

					img {
						width: 100%;
					}

					a {
						display: block;
						width: 100%;
						height: 100%;
					}

				}
			}
		}

		.product-view-control {
			display: none !important;
		}

		.link-button {
			font-size: 0.85em;
			text-transform: none;
		}
	}

	.product-cpmtemt-info-container {
		margin-bottom: $pdpSpacingLarge;
	}

	#product-form .product-actions .product-actions-secondary {
		width: auto;

		.find-a-store {
			font-weight: bold;
			margin-bottom: 0;
		}

		button.link-button {
			line-height: rem-calc(20);
		}
	}

	// this is only a temporary fix just to avoid touching platform code
	// TODO: refactor this later to leverage the code from vans/sass/product/_product-form.scss
	#product-form {
		section.step-container.size-step-container .step-header {
			h2,
			span {
				line-height: 1;
			}
		}

		.product-attr-form > section.step-container:not(.attr-color) {
			@include clearfix();

			// mobile first
			.step-header {
				@include grid-column-collapse;
				float: $global-left;
				width: grid-column(4);
			}

			.step-content {
				@include grid-column-collapse;
				float: $global-left;
				width: grid-column(8);
			}
		}

		.product-actions {
			@include breakpoint(medium) {
				.product-actions-primary {
					float: left;
					margin-right: 20px;
				}
			}

			.product-actions-secondary,
			.utility-actions-primary {
				margin-left: 0;
				width: auto;

				.link-button {
					line-height: 24px;

					[class^='icon-'],
					[class*=' icon-'] {
						line-height: 24px;
					}
				}
			}

			.product-actions-secondary .icon-wishlist {
				font-size: em-calc(11, 13.6);
			}

			.product-actions-tertiary {
				float: left;
				width: 96px;

				.link-button {
					display: block;
					height: 24px;
					line-height: 24px;

					&:hover {
						color: $topbar-link-color-hover;
					}
				}
			}
		}
	}

	.button,
	button {
		font-weight: $btnFontWeight;
	}
}

.quickview-modal,
.quickview-box {
	#product-info h1 a {
		color: $black;
	}

	:not(.step-header) > .size-chart {
		display: none;
		text-align: center;

		.product-content-form-size-chart-close {
			position: relative;
		    float: right;
		    padding-right: 15px;
		}
	}

	.find-in-store-container {
		display: none;
	}

	.product-content-form-find-in-store-close {
		float: right;
		padding-right: 15px;
		position: relative;
		z-index: z-index(reveal);
	}
}

// Quick view modal Alternate design
@if($quickviewModalAlternativeDesign) {
	#quickview-box {
		.more-details {
			display: none;

			@include breakpoint (medium) {
				display: block;
			}
		}

		.product-price span.original-price {
			margin-right: rem-calc(8);
			text-decoration: line-through;
		}

		.product-content-info-header {
			@include setFontStyle($quickModalTitleH1Style);
			height: auto;
			margin-bottom: 0;
			margin-top: 0;
			padding-bottom: rem-calc(10);
			padding-top: rem-calc(8);

			@include breakpoint (medium) {
				@include setFontStyle($productTitleH1Style);
				margin-bottom: rem-calc(21);
				margin-top: rem-calc(21);
				padding-bottom: rem-calc(0);
				padding-top: rem-calc(0);
			}
		}

		#product-views-container {
			padding-top: rem-calc(36);
			@include breakpoint (medium) {
	         	padding-top: 0;
			}
		}

		#product-content-container {
			padding: rem-calc(24) rem-calc(10) rem-calc(10) rem-calc(10);

			@include breakpoint(medium) {
			 	padding: 0;
			 }
	}

		#product-info {

			.product-content-info-price {
				display: flex;
				height: auto;
				letter-spacing: rem-calc(0.44);
				width: rem-calc(70);
			}
		}

		#product-form {
			.product-attr-form > section.step-container:not(.attr-color) {
				padding: rem-calc(16) rem-calc(10) rem-calc(24) rem-calc(10);
					@include breakpoint(medium) {
						padding: rem-calc(20) 0 rem-calc(20) 0;
					}
			}

			.product-attr-form > section.step-container.attr-color {
				padding: rem-calc(16) 0;

				.attr-box {
					height: rem-calc(40);
					margin: rem-calc(3);
					width: rem-calc(40);

					img {
						height: 40px;
    					width: 40px;

						@include breakpoint (medium) {
							height: 100%;
    						width: 100%;
						}
					}

					@include breakpoint(medium) {
			 			height: rem-calc(50);
						margin-right: 0;
						width: rem-calc(50);
					}
				}

				.attr-container {
					height: auto;
					margin: 0;
					margin-bottom: rem-calc(8);

					@include breakpoint(medium) {
						height: 57px;
			 			margin: rem-calc(20);
						margin-bottom: 0;
			 		}
				}

				.step-header {
					padding-bottom: rem-calc(8);

					@include breakpoint(medium) {
			 			padding-bottom: 0;
			 		}
				}
			  }

			.product-attr-form {
				.select-size-error-message {
					padding-top: rem-calc(4);

					@include breakpoint(medium) {
						padding-top: 0;
					}
				}
			}
		}

		.product-cpmtemt-info-container {
			margin-bottom: 0;
			padding-bottom: rem-calc(16);
		}

		.product-reviews-container {
			padding-bottom: 0;
		}
	}

	.quickview-modal,
	.quickview-box
	.reveal {
		.close-button {
			color: $black;
			@include breakpoint (medium) {
				color: $dark-gray;
			}
		}
		@include breakpoint($-zf-zero-breakpoint only) {
		  width: 100%;
		  padding: 0;

		  @include breakpoint (medium) {
			width: $reveal-width;
		  }
		}

		#smart-gift-button {
			display: none;
			@include breakpoint (medium) {
				display: block;
			}
		}

		.s7controlbar.ZoomView {
			opacity: 1 !important;
		}
	}
}
