//
// Dynamic Media banner
//
@import 'global/z-indexes';

@mixin vans-dynamic-media-banner {
	section.dynamicmediabanner {

		.garnish-full-width {
			&.top-pad-default {
				padding-top: 0;
			}

			&.bottom-pad-default {
				padding-bottom: 0;
			}
		}

		.cycle-prev,
		.cycle-next {
			background: transparent;
			color: $body-font-color;
		}

		// Primary Carousel
		.dmb-feature {
		margin-top: 30px;
			position: relative;

			.carousel {
				z-index: 0;
				overflow: hidden;
			}

			.slide {
				width: 100%;

				img {
					width: 100%;
				}

			}

			//Controls overlaying main hero
			.cycle-prev,
			.cycle-next {
				font-family: icomoon;
				height: 36px;
				line-height: 36px;
				margin: 0;
				padding: 0;
				position: absolute;
				top: calc(50% - 18px);
				width: 36px;
				z-index: z-index(carousels) + 1;

				span {
					line-height: inherit;
				}
			}

			.cycle-prev {
				left: 0;
			}

			.cycle-next {
				right: 0;
			}
		}

		// Secondary Carousel
		.dmb-support {
			.carousel {
				z-index: 0;
				overflow: hidden; // Prevent scrollbar on cycle
				@include clearfix; // Prevent the div from collapsing

				.slide {
					@include clearfix; // Prevent the div from collapsing
					width: 100%; // Prevents scaling effect during horizontal animation of tray

					// Quickshop button is smaller here
					.button {
						left: calc(50% - 55px);
						top: calc(50% - 22px);
						padding: 10px;
						width: 110px;
					}

					// Avoid vertical jumpiness VFDP-1794
					.product.wrapper {
						display: inline-block;
						vertical-align: top;
						width: 100%;
					}
				}
			}
		}

		// Carousel Controls under main hero
		.controls {
			color: $body-font-color;
			height: 43px;
			line-height: 43px;
			position: relative;

			.inner {
				display: inline; // .show-for-touch uses display: inherit !important; So we need somthing for it to inherit properly
			}

			.control-container {
				display: inline-block;
			}

			.cycle-prev,
			.cycle-next {
				display: inline;
				font-size: em-calc(11);
				margin: 0 em-calc(2, 11);
				cursor: pointer;
				padding: em-calc(16, 11) 1em em-calc(16, 11) 1em;

				&:hover {
					color: $button-color;
					background: $button-hover;
				}
			}

			.cycle-pager {
				display: inline;

				span {
					display: inline-block;
					vertical-align: middle;
					height: 12px;
					width: 12px;
					border-radius: 50%;
					overflow: hidden;
					background-color: get-color(pink-swan);
					margin: 0 3px;
					cursor: pointer;

					&.cycle-pager-active {
						background-color: $body-font-color;
					}

					&.disabled {
						border: 1px solid get-color(pink-swan);
						background: transparent;
						cursor: not-allowed;
					}
				}
			}
		}

		// Optional middle garnish that is different from the usual top and bottom garnish used globally
		.middlegarnish {
			margin-bottom: 50px; // We don't want it overlapping product images.
		}
	}
}
