//
// Browser upgrade message
//

$include-html-browserupgrade-classes: $include-html-classes !default;

// We use this mixin to create a default base message style
@mixin browserupgrade {
    margin: 0.2em 0;
    background: $body-background;
    color: $body-font-color;
    padding: 0.2em 0;
    text-align: center;
}

@if $include-html-browserupgrade-classes != false {

// ==========================================================================
// Browser Upgrade Prompt
// ==========================================================================
  .browserupgrade {
    @include browserupgrade;
  }
}
