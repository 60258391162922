@import 'widgets/autocomplete';

.autosuggest-results__featured-results-item__title {
	text-transform: uppercase;
}

.autosuggest-results-container {
	background-color: $white;
	color: $black;

	&::before {
		background-color: rgba(0, 0, 0, 0.5);
		content: " ";
		display: block;
		height: calc(100vh - 85px);
		left: 0;
		position: fixed;
		top: rem-calc(115);
		width: 100vw;
		z-index: -1;

		@include breakpoint(medium) {
			top: rem-calc(85);
		}
	}

	&::after {
		background: inherit;
		content: "";
		display: block;
		height: calc(100% + 10px);
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		z-index: -1;

		@include breakpoint(medium) {
			height: 100%;
		}
	}

	@include breakpoint(medium) {
		margin-left: rem-calc(73);
		top: rem-calc(53) !important; // sass-lint:disable-line no-important
	}

	.autosuggest-results__suggestions-list-header-item {
		color: get-color(suva-grey);
		font-size: rem-calc(12);

		> .search-term {
			color: $black !important;
			font-weight: 400;
		}
	}

	.autosuggest-results__suggestions-list {
		&-item  {
			&.title {
				font-family: get-font('franklin-gothic');
				font-weight: 600;
				line-height: rem-calc(18);
				margin-top: rem-calc(5);
				text-transform: uppercase;
			}
		}
	}

	.search-term-link {
		color: $black;
		font-size: rem-calc(14);
		font-weight: 700;

		.search-term {
			color: $black;
			font-weight: 400;
		}

		@include breakpoint(medium) {
			font-size: rem-calc(12);
		}
	}

	.autosuggest-results__featured-results {
		&-container {
			padding: 0 rem-calc(10);
		}

		&-title {
			color: $black;
			font-size: rem-calc(16);
			font-weight: 600;
			line-height: rem-calc(18);
			padding: rem-calc(15) rem-calc(10) rem-calc(10) 0;
			@include breakpoint(medium) {
				padding: rem-calc(15) rem-calc(10) rem-calc(15) 0;
			}
		}

		&-item {
			.autosuggest-results__featured-results-item-link {
				align-items: center;
				display: flex;

				.autosuggest-results__featured-results-item__info-container {
					display: inline-flex;
					flex-direction: column;
				}
			}
		}
	}

	.autosuggest-overlay-close {
		display: none;
	}
}
.vans-top-nav-new-design.peek-a-boo-menu {
	.autosuggest-results-container {
		&::before {
			top: rem-calc(182);
		}
	}
}
