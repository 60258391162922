// ********
// *
// * Global Variables Setup
// *
// * Set all brand-specific Foundation and SCSS variables here
// *
// * This file should be variable deffinitions only! No styling allowed!
// *
// ********

@import '../../fonts/icomoon/variables'; // Icon fonts
@import 'global/default-variables'; // Platform defaults


$scene7-path: '//images.vans.com';

$store-logo: url('../images/logos/logo.svg');
$store-logo-white: url('../images/logos/logo-white.svg');

//---------------------------------------------------------------
//          Custom Variables
//---------------------------------------------------------------
// Leave default values commented out so it's
// easy to tell what the result will be if a variable is undone.
//
// Always use Foundation variables first before creating new variables below!
//
// See shared/sass/global/default-variables.scss for more details
//---------------------------------------------------------------
$column-gutter: column-gutter();
$is-redesign: false;

// Colors for buttons
$button-cta: get-color(fire-engine-red);
$button-primary: get-color(primary);
$button-hover: $black;

//store selection modal
$store-selection-modal-title-border-bottom-width: 0;
$store-selection-modal-title-border-bottom-style: none;
$store-selection-modal-title-border-bottom-color: transparent;
$store-selection-modal-store-list-border-width: 0 0;
$store-selection-modal-store-list-border-style: none;
$store-selection-modal-store-list-border-color: transparent;
$store-selection-modal-search-top-padding: 0;
$store-selection-modal-continue-button-width: 47%;
$store-selection-modal-cancel-button-width: 47%;
$store-selection-modal-continue-button-right-margin: 8px;
$store-selection-modal-cancel-button-float: right;

$store-selection-modal-store-list-item-store-name-font-color: $header-color;
$store-selection-modal-store-list-item-city-distance-font-color: $header-color;
$store-selection-modal-store-list-item-city-distance-font-size: 1rem;
$store-selection-modal-store-list-item-store-name-font-size: em-calc(header-font-size(h4, medium));
$store-selection-modal-store-list-item-store-name-font-family: $header-font-family;
$store-selection-modal-store-list-item-name-text-transform: uppercase;
$store-selection-modal-store-list-item-name-font-weight: bold;
$store-selection-modal-store-list-item-name-line-height: 1.3;

$store-selection-modal-store-list-item-city-distance-display-type: block;
$store-selection-modal-store-list-item-city-distance-margin-left: 30px;
$store-selection-modal-store-list-item-container-display-type: flex;
$store-selection-modal-store-list-item-store-name-display-type: inline-block;
$store-selection-modal-store-list-item-label-display-type: flex;
$store-selection-modal-store-list-item-radio-container-display-type: inline;
$store-selection-modal-store-list-item-text-container-display-type: inline;
$store-selection-modal-store-list-item-radio-container-padding-top: 0;
$store-selection-text-container-pickup-instructions-font-size: 1rem;
$store-selection-text-container-pickup-instructions-color: $header-color;
$store-selection-modal-medium-screen-store-list-max-height: 350px;

$store-selection-text-disabled-color: get-color(vans-grey-2);

$store-input-autocomplete-container-padding: 0 $column-gutter/2 0 0 !default;
$store-input-autocomplete-container-top: 122px !default;
$store-selection-modal-store-list-item-margin-bottom: 0.5rem;
//---------------------------------------------------------------
// CMS-Hosted Top Nav
//
// Manages to the top nav and does NOT use Foundation TopBar
//---------------------------------------------------------------
// $include-html-topnav-logo-classes: $include-html-classes;
// $topnav-logo: url('../images/common/logo.svg');
$topnav-logo: $store-logo;
$topnav-accordion-item--indent: $white;
$topnav-accordion-item--height: rem-calc(48);
$topnav-accordion-item--padding-base: rem-calc(15);
$topnav-accordion--font-size__medium: rem-calc(12);
$topnav-accordion-item--line-height__medium: rem-calc(13);
$topnav-mainnav-more-dropdown-shadow: 0 rem-calc(2) rem-calc(4) rgba($black, 0.2);
$topnav-font: get-font('franklin-gothic');
$topnav-search-container-width__medium: 15%;
$topnav-dropdown-item-color: $black;
$topnav-accordion-item--padding__medium: rem-calc(15) rem-calc(20) rem-calc(19) rem-calc(20);
$topnav-logo-height__medium: rem-calc(40);
$topnav-logo-top__medium: rem-calc(-15);
$topnav-logo-width__medium: rem-calc(116);
//---------------------------------------------------------------
// CMS Hover Caption component
//
// Displays captions on images via a hover behavior
//---------------------------------------------------------------

// $hover_caption_transition_duration: 0.25s;
// $hover_caption_overlay_color: $black;
$hover_caption_overlay_color: get-color(nero);
// $hover_caption_overlay_font_color: $white;
// $hover_caption_overlay_font_family: $header-font-family;
$hover_caption_overlay_font_family: $body-font-family;
// $hover_caption_overlay_font_size: $global-font-size;
$hover_caption_overlay_font_weight: bold;
// $hover_caption_overlay_text_transform: normal;


//---------------------------------------------------------------
// CMS Image Overlay component
//
// Displays an image with a content box overlaid
//---------------------------------------------------------------

// $image_overlay_inset_small: 5%;
// $image_overlay_inset_medium: $image_overlay_inset_small * 2;
// $image_overlay_inset_large: $image_overlay_inset_medium * 2;
// $image_overlay_padding: $column-gutter;
// $image_overlay_background: $body-background;
// $image_overlay_color: $body-font-color;
// $image_overlay_headline_color: $header-color;
// $image_overlay_text_align: left;


//---------------------------------------------------------------
// CMS Previous / Next Bar
//
// Displays a previous next bar for articles
//---------------------------------------------------------------

// $prev_next_border_top: null;
// $prev_next_border_bottom: null;
$prev_next_border_bottom: 1px solid get-color(feta);
// $prev_next_row_background: null;
// $prev_next_row_padding: null;
// $prev_next_link_height_desktop: 50px;
// $prev_next_link_height_mobile: 100px;
// $prev_next_arrow_color: null;
$prev_next_arrow_color: get-color(nero);
// $prev_next_arrow_color_hover: null;
// $prev_next_arrow_font: 'icomoon';
// $prev_next_arrow_font_size: 1em;
// $prev_next_arrow_width: 1em;
// $prev_next_link_previous_arrow_code: $icon-arrow-left-indicator-open;
// $prev_next_link_previous_mobile_border: null; // TNF
$prev_next_link_previous_mobile_border: 1px get-color(feta) dashed;
// $prev_next_link_next_arrow_code: $icon-arrow-right-indicator-open;
// $prev_next_link_use_arrow_borders: false; // Vans
$prev_next_link_use_arrow_borders: true;
// $prev_next_link_previous_arrow_border_padding: null; // Vans
$prev_next_link_previous_arrow_border_padding: 0 10px 0 20px;
// $prev_next_link_next_arrow_border_padding: null; // Vans
$prev_next_link_next_arrow_border_padding: 0 20px 0 10px;
// $prev_next_link_arrow_border: null; // Vans
$prev_next_link_arrow_border: 1px solid get-color(feta);
// $prev_next_link_image_margin_inside: 1em;
// $prev_next_link_image_margin_outside: 1em;
$prev_next_link_image_margin_outside: 0;
// $prev_next_middle_border_left: null;
$prev_next_middle_border_left: 1px solid get-color(feta);
// $prev_next_middle_border_right: null;
$prev_next_middle_border_right: 1px solid get-color(feta);
// $prev_next_middle_border_top_on_mobile: null;
$prev_next_middle_border_top_on_mobile: 1px solid get-color(feta);

//---------------------------------------------------------------
// CMS Product Thumbnails
//
// Displays an product thumbnail with details
//---------------------------------------------------------------

$product-block-info-text-align: left;
$productRatingButtonFontSize: rem-calc(13);
$productRatingButtonFontSizeMedium: rem-calc(16) ;
$productBlockStarRatingPositionLeft: rem-calc(1);
// $product_teaser_padding: null;
$product_teaser_padding: 0 0 30px 0;
// $product_teaser_image_padding: null;
// $product_teaser_image_margin: null;
$product_teaser_image_margin: 0 0 15px 0;
// $product_teaser_info_padding: null;
// $product_teaser_info_margin: null;
// $product_teaser_text_align: center;
// $product_teaser_title_font_family: $header-font-family;
// $product_teaser_title_font_weight: $header-font-weight;
$product_teaser_title_font_weight: normal;
// $product_teaser_title_color: $header-color;
// $product_teaser_title_font_size: em-calc(header-font-size(h5, medium));
$product_teaser_title_font_size: em-calc(13);
// $product_teaser_title_line_height: $header-lineheight;
// $product_teaser_title_letter_spacing: inherit;
// $product_teaser_title_top_margin: $header-margin-bottom;
// $product_teaser_title_bottom_margin: 0.2em;
// $product_teaser_title_text_transform: null;
// $product_teaser_swatch_height: 25px;
// $product_teaser_swatch_gutter: 3px;
// $product_teaser_swatch_count: 5;
// $product_teaser_swatch_more_font_family: $header-font-family;
// $product_teaser_swatch_more_font_weight: $header-font-weight;
// $product_teaser_swatch_more_color: $header-color;
// $product_teaser_swatch_more_font_size: inherit;
// $product_teaser_swatch_more_letter_spacing: inherit;
// $product_teaser_swatch_more_border: 1px solid $body-font-color;
// $product_teaser_swatch_more_background: $white;
//$product_ratings_color: get-color(primary) !default;
// $product_ratings_off_color: get-color(secondary);
$product_ratings_off_color: get-color(very-light-grey);
$product_ratings_size: rem-calc(11);
$product_ratings_spacing: -4px; // Fine-tune the gap between stars
$product_ratings_line-height: rem-calc(13);
// $product_teaser_eyebrow_font_family: null;
$product_teaser_eyebrow_font_family: get-font('franklin-gothic');
// $product_teaser_eyebrow_color: null;
$product_teaser_eyebrow_color: get-color(primary);
// $product_teaser_eyebrow_font_size: null;
$product_teaser_eyebrow_font_size: em-calc(16);
// $product_teaser_eyebrow_line_height: null;
// $product_teaser_eyebrow_letter_spacing: null;
$product_teaser_eyebrow_letter_spacing: 0.1em;
// $product_teaser_eyebrow_text_transform: null;
// $product_teaser_promo_font_family: inherit;
// $product_teaser_promo_color: inherit;
// $product_teaser_promo_font_size: inherit;
// $product_teaser_promo_line_height: inherit;
// $product_teaser_promo_letter_spacing: inherit;
// $product_teaser_price_font_family: inherit;
$product_teaser_price_font_family: $header-font-family;
// $product_teaser_price_color: inherit;
$product_teaser_price_color: get-color(primary);
// $product_teaser_price_font_size: inherit;
$product_teaser_price_font_size: em-calc(13);
// $product_teaser_price_font_weight: null;
$product_teaser_price_font_weight: normal;
// $product_teaser_price_line_height: inherit;
// $product_teaser_price_letter_spacing: inherit;
// $product_teaser_price_previous_font_family: null;
// $product_teaser_price_previous_color: null;
$product_teaser_price_previous_color: get-color(bombay);
// $product_teaser_price_previous_font_size: null;
// $product_teaser_price_previous_font_weight: null;
// $product_teaser_price_previous_line_height: null;
// $product_teaser_price_previous_letter_spacing: null;
// $product_teaser_price_previous_text-decoration: line-through;
// $product_teaser_price_current_font_family: null;
// $product_teaser_price_current_color: null;
// $product_teaser_price_current_font_size: null;
// $product_teaser_price_current_font_weight: null;
// $product_teaser_price_current_line_height: null;
// $product_teaser_price_current_letter_spacing: null;
// $product_teaser_qty_font_family: inherit;
// $product_teaser_qty_color: inherit;
// $product_teaser_qty_font_size: inherit;
// $product_teaser_qty_line_height: inherit;
// $product_teaser_qty_letter_spacing: inherit;
// $product_teaser_mobile_quickshop-button-font-size: null;
// $product_teaser_badge-vertical-align: top;
// $product_teaser_badge-horizontal-align: right;

//---------------------------------------------------------------
// Gift Card Carousel
//
// Displays Gift Cards
//---------------------------------------------------------------
$giftCardNameFontFamily: get-font('franklin-gothic');
$giftCardNameColor: $black;

$giftCardPriceColor: get-color(primary);
$giftCardPriceFontFamily: get-font('franklin-gothic');

$giftCardLinkWordColor: $black;
$giftCardLinkWordFamily: get-font('franklin-gothic');
$giftCardLinkHoverColor: none;
$giftCardLinkBackground: $white;

//---------------------------------------------------------------
// CMS Email Signup
//
// Displays an email signup form
//---------------------------------------------------------------

// $email_signup_form_email_input_text_align_mobile: center;
// $email_signup_form_email_input_text_align_tablet_up: left;
// $email_signup_form_background_color: null;
// $email_signup_form_max_width: null;
// $email_signup_form_padding: 1em;
// $email_signup_form_submit_button_background_color: null;
// $email_signup_form_submit_button_background_color_hover: null;
// $email_signup_form_submit_button_border: null;
$email_signup_form_submit_button_border: 2px solid $white;
// $email_signup_form_submit_button_border_hover: null;
// $email_signup_form_submit_button_font_color: null;
// $email_signup_form_submit_button_font_color_hover: null;
// $email_signup_form_submit_button_before_content: null;
// $email_signup_form_submit_button_before_font_family: null;
// $email_signup_form_submit_button_after_content: null;
// $email_signup_form_submit_button_after_font_family: null;
// $email_signup_form_label_font_family: null;
$email_signup_form_label_font_family: get-font('franklin-gothic');
// $email_signup_form_label_font_color: null;
$email_signup_form_label_font_color: $header-color;
// $email_signup_form_label_font_size: null;
$email_signup_form_label_font_size: em-calc(15px);
// $email_signup_form_label_font_weight: null;
// $email_signup_form_label_text_transform: null;
$email_signup_form_label_text_transform: uppercase;
// $email_signup_form_label_text_align_mobile: center;
// $email_signup_form_label_text_align_tablet: right;
// $email_signup_form_label_text_align_desktop: right;
// $email_signup_form_label_letter_spacing: null;
$email_signup_form_label_letter_spacing: 1px;
// $email_signup_form_text_input_background_color: null;
$email_signup_form_text_input_background_color: $white;
// $email_signup_form_text_input_border: null;
// $email_signup_form_text_input_font_color: null;
// $email_signup_form_alert_box_margin: 1.5em auto 0;
// $email_signup_form_input_button_gutter_percent: 0; // Can be used as a fallback for gutter width below
// $email_signup_form_input_button_gutter_width: 0;
$email_signup_form_input_height: 40px;
$email_signup_form_input_border_right: 0;
$email_signup_form_button_border_left: 0;




//---------------------------------------------------------------
// CMS Article Teaser Grid
//
// Displays a grid of article teasers with thumnbails
//---------------------------------------------------------------
//$article-teaser-filters-align: null;
$article-teaser-filters-border: 1px solid get-color(regent-st-blue);
$article-teaser-filters-display: inline-block;
$article-teaser-filters-font-size: em-calc(12.81);
$article-teaser-filters-item-color: $medium-gray;
$article-teaser-filters-item-color-hover: get-color(primary);
$article-teaser-filters-item-color-active: get-color(primary);
$article-teaser-filters-item-padding: 0;
$article-teaser-filters-margin-bottom: 20px;
$article-teaser-filters-margin-top: 0;
$article-teaser-filters-padding-bottom: 0;
$article-teaser-filters-padding-top: 0;
$article-teaser-filters-seperator-color: get-color(light-grey);
$article-teaser-filters-seperator-content: "\00a0\00a0/\00a0\00a0";
$article-teaser-filters-seperator-enabled: true;
//$article-teaser-filters-seperator-padding: null;
$article-teaser-filters-text-transform: none;

//---------------------------------------------------------------
// CMS Article Teaser
//
// Article preview with thumnbails
//---------------------------------------------------------------
$articleTeaserPaddingBottom: 30px;
$articleTeaserTextAlign: left;
$articleTeaserVerticalAlign: top;
$articleTeaserImageMarginBottom: 15px;
$articleTeaserDetailsHideOnSmall: true;
$articleTeaserDetailsColor: $medium-gray;
$articleTeaserDetailsFontSize: em-calc(13);
$articleTeaserDetailsLetterSpacing: 0.5px;
// $articleTeaserDetailsLineHeight: null;
$articleTeaserDetailsMargin: 0;
// $articleDetailCaptionColor: $body-font-color;
$articleDetailCaptionFontFamily: get-font('franklin-gothic');
$articleDetailCaptionFontSize: em-calc(10);
$articleDetailCaptionFontSizeMedium: em-calc(22);
$articleDetailCaptionFontWeight: normal;
$articleDetailCaptionLineHeight: em-calc(24, 22);
$articleDetailCaptionMargin: em-calc(15) 0;
$articleDetailCaptionTransform: uppercase;
$articleTeaserReadMoreHideOnSmall: true;
$articleTeaserReadMoreFontSize: em-calc(12);


//---------------------------------------------------------------
// CMS Content List
//
// Displays a list of articles suitable for a sidebar
//---------------------------------------------------------------
// $contentlist_margin: $column-gutter 0 $column-gutter 0;
// $contentlist_list_style: none;


//---------------------------------------------------------------
// CMS Carousel Controls
//
// Global shared styles for CMS Carousels
//---------------------------------------------------------------
//
// -------- Overlay Arrows --------------------------------------
// $carousel-overlay-arrow-size: em-calc(16);
// $carousel-overlay-arrow-color: $header-color;
$carousel-overlay-arrow-color: $white;
// $carousel-overlay-arrow-background: null;
$carousel-overlay-arrow-background: get-color(secondary);
// $carousel-overlay-arrow-color-hover: null;
// $carousel-overlay-arrow-background-hover: null;
$carousel-overlay-arrow-background-hover: get-color(nero);
// $carousel-overlay-arrow-width: 2 * $column-gutter;
$carousel-overlay-arrow-width: 36px;
// $carousel-overlay-arrow-height: $carousel-overlay-arrow-width;
// $carousel-overlay-arrow-opacity: 1 !default;
// $carousel-overlay-arrow-opacity-hover: 1 !default;
//
// -------- Arrows that display below the carousel --------------
// $carousel-lower-arrow-size: em-calc(16);
$carousel-lower-arrow-size: em-calc(11);
// $carousel-lower-arrow-color: $header-color;
$carousel-lower-arrow-color: $body-font-color;
// $carousel-lower-arrow-color-hover: null;
$carousel-lower-arrow-color-hover: $white;
// $carousel-lower-arrow-padding: 0;
$carousel-lower-arrow-padding: em-calc(16, 11) 1em em-calc(16, 11) 1em;
// $carousel-lower-arrow-background: null;
// $carousel-lower-arrow-background-hover: null;
$carousel-lower-arrow-background-hover: get-color(secondary);
// $carousel-lower-container-margin: 0 $column-gutter/2;
//
// -------- Pager that displays below the carousel --------------
// $carousel-pager-indicator-size: em-calc(12);
$carousel-pager-indicator-size: 12px;
// $carousel-pager-indicator-margin: 0 em-calc(3);
// $carousel-pager-indicator-border-radius: 50%;
// $carousel-pager-indicator-background: null;
$carousel-pager-indicator-background: get-color(suva-grey);
// $carousel-pager-indicator-border: null;
// $carousel-pager-indicator-active-background: null;
$carousel-pager-indicator-active-background: $body-font-color;
// $carousel-pager-indicator-active-border: null;
// $carousel-pager-indicator-hover-background: null;
// $carousel-pager-indicator-hover-border: null;


//---------------------------------------------------------------
// CMS Product Carousel
//
// Displays products in a carousel
//---------------------------------------------------------------
//
// -------- Overlay Arrows --------------------------------------
// $product-carousel-overlay-arrow-size: $carousel-overlay-arrow-size;
// $product-carousel-overlay-arrow-color: $carousel-overlay-arrow-color;
// $product-carousel-overlay-arrow-background: $carousel-overlay-arrow-background;
// $product-carousel-overlay-arrow-color-hover: $carousel-overlay-arrow-color-hover;
// $product-carousel-overlay-arrow-background-hover: $carousel-overlay-arrow-background-hover;
// $product-carousel-overlay-arrow-width: $carousel-overlay-arrow-width;
// $product-carousel-overlay-arrow-height: $carousel-overlay-arrow-height;
// $product-carousel-overlay-arrow-opacity: $carousel-overlay-arrow-opacity;
// $product-carousel-overlay-arrow-opacity-hover: $carousel-overlay-arrow-opacity-hover;
//
// -------- Product Styles --------------------------------------
// $product-carousel-product-wrapper-padding: 0 $column-gutter/2;


//---------------------------------------------------------------
// CMS Carousel Container
//
// Displays complex CMS slides in a carousel
//---------------------------------------------------------------
//
// -------- Overlay Arrows --------------------------------------
// $carousel-container-overlay-arrow-size: $carousel-overlay-arrow-size;
// $carousel-container-overlay-arrow-color: $carousel-overlay-arrow-color;
// $carousel-container-overlay-arrow-background: $carousel-overlay-arrow-background;
// $carousel-container-overlay-arrow-color-hover: $carousel-overlay-arrow-color-hover;
// $carousel-container-overlay-arrow-background-hover: $carousel-overlay-arrow-background-hover;
// $carousel-container-overlay-arrow-width: $carousel-overlay-arrow-width;
// $carousel-container-overlay-arrow-height: $carousel-overlay-arrow-height;
// $carousel-container-overlay-arrow-opacity: $carousel-overlay-arrow-opacity;
// $carousel-container-overlay-arrow-opacity-hover: $carousel-overlay-arrow-opacity-hover;
//
// -------- Arrows that display below the carousel --------------
// $carousel-container-lower-arrow-size: $carousel-lower-arrow-size;
// $carousel-container-lower-arrow-color: $carousel-lower-arrow-color;
// $carousel-container-lower-arrow-color-hover: $carousel-lower-arrow-color-hover;
// $carousel-container-lower-arrow-padding: $carousel-lower-arrow-padding;
// $carousel-container-lower-arrow-background: null;
// $carousel-container-lower-arrow-background-hover: null;
// $carousel-container-lower-container-margin: 0 $column-gutter/2;
//
// -------- Pager that displays below the carousel --------------
// $carousel-container-pager-indicator-size: $carousel-pager-indicator-size;
// $carousel-container-pager-indicator-margin: $carousel-pager-indicator-margin;
// $carousel-container-pager-indicator-border-radius: $carousel-pager-indicator-border-radius;
// $carousel-container-pager-indicator-background: $carousel-pager-indicator-background;
// $carousel-container-pager-indicator-border: $carousel-pager-indicator-border;
// $carousel-container-pager-indicator-active-background: $carousel-pager-indicator-active-background;
// $carousel-container-pager-indicator-active-border: $carousel-pager-indicator-active-border;
// $carousel-container-pager-indicator-hover-background: $carousel-pager-indicator-hover-background;
// $carousel-container-pager-indicator-hover-border: $carousel-pager-indicator-hover-border;


//---------------------------------------------------------------
// Garnish for AEM components
//---------------------------------------------------------------
// $garnish-full-width-top-padding: null !default;
// $garnish-full-width-bottom-padding: null !default;
// $garnish-full-width-top-margin: null !default;
// $garnish-full-width-bottom-margin: null !default;
// $garnish-full-width-top-padding-medium-and-up: null !default;
// $garnish-full-width-bottom-padding-medium-and-up: null !default;
// $garnish-full-width-top-margin-medium-and-up: null !default;
// $garnish-full-width-bottom-margin-medium-and-up: null !default;
// $garnish-full-width-top-padding-large-and-up: null !default;
// $garnish-full-width-bottom-padding-large-and-up: null !default;
// $garnish-full-width-top-margin-large-and-up: null !default;
// $garnish-full-width-bottom-margin-large-and-up: null !default;


//---------------------------------------------------------------
// Single Category Tile Component
//
// Displays categories as single title
//---------------------------------------------------------------

// $single_category_title_font_family: null !default;
// $single_category_title_font_weight: null !default;
// $single_category_title_color: null !default;
// $single_category_title_font_size: null !default;
// $single_category_title_line_height: null !default;
// $single_category_title_letter_spacing: null !default;
// $single_category_title_top_margin: null !default;
// $single_category_title_bottom_margin: null !default;
// $single_category_title_text_transform: null !default;

//---------------------------------------------------------------
// CMS Shop the Look
//
// Displays cards or grid tiles representing shop the look bundles
//---------------------------------------------------------------

$shop-the-look-background-color: $body-background;
$shop-the-look-nav-li-border-hover: 0;
$shop-the-look-nav-li-border-active: 0;
$shop-the-look-body-color: get-color(secondary);
$shop-the-look-nav-li-text-transform: uppercase;
$shop-the-look-nav-li-active-text-decoration: underline;
$shop-the-look-nav-li-active-font-weight: bold;
$shop-the-look-nav-li-margin-large: 0 rem-calc(15) 0 0;
$shop-the-look-nav-width: auto;
$shop-the-look-nav-width-medium: auto;
$shop-the-look-nav-width-large: auto;
$shop-the-look-nav-font-size: rem-calc(20);
$shop-the-look-nav-li-button-border-radius: 5px;
$shop-the-look-nav-li-button-border: 1px solid get-color(light-grey-2);
$shop-the-look-nav-li-button-font-size: rem-calc(11);
$shop-the-look-nav-li-button-font-weight: 700;
$shop-the-look-nav-li-button-padding: 0;
$shop-the-look-nav-li-button-height: rem-calc(40);
$shop-the-look-header-color: $black;
$shop-the-look-header-font-size: rem-calc(40);
$shop-the-look-header-font-size-large: rem-calc(40);
$shop-thelook-header-h1-margin: rem-calc(21) 0 0 0;
$shop-the-look-subheader-text-transform: uppercase;
$shop-the-look-subheader-font-weight: 700;
$shop-the-look-font-face: get-font('franklin-gothic');
$shop-the-look-overlay-button-font-size: rem-calc(19);
$shop-the-look-overlay-button-font-weight: 700;
$shop-the-look-grid-mobile-button-font-size: rem-calc(13);
$shop-the-look-grid-mobile-button-font-weight: 700;

// =================
// Default Variables
// -----------------
////////////Default Variables and Style////////////

$freeText: $medium-gray;

$backgroundGlobalSprite: url('../images/common/global-sprite.png');


///////////////////////// BELOW COPIED FROM ZOBRIST SVN /////////////////////////

// *** Start Foundation Variables **

$logo-width: 115px;
$logo-height: 66px;

// *** End Foundation Variables ***

// =================
// Default Variables
// -----------------
////////////Default Variables and Style////////////


$secondaryFont: $body-font-family;
$mediumFontFamily: $body-font-family;
$OTWHeaderTitleFontFamily: Arial, sans-serif;

$loading-spinner-font: (
  'font': (
    'font-family': get-font('franklin-gothic')
  )
);

$loading-spinner-inline-width: 150px;

$productGridSortByFontStyle: (
  'font': (
    'font-family': get-font('franklin-gothic'),
    'color': $medium-gray,
    'font-size': em-calc(20px),
    'font-weight': 400,
    'font-style': normal
  )
) !default;


//background and accent colors
$pageBackground: get-color(twilight-blue);
$moduleBackground: $white;
$hairlineRules: get-color(feta);
$dashedRules: get-color(feta);
$filterHairlineRules: get-color(regent-st-blue);
$blueHighlight: get-color(alice-blue);
$errorHighlight: get-color(wisp-pink);
$successHighlight: get-color(success);
$genericHighlight: get-color(black-squeeze);

//text color palette
$darkText: $black;
$headlineText: get-color(primary);
$defaultText: get-color-(vans-grey-2);
$negativeText: $white;
$negativeLightText: get-color(suva-grey);
$errorText: get-color(alert);
$hyperlinkText: get-color(picton-blue);
$searchFieldInputText: get-color(nero);
$formText: $medium-gray;

// Form Input Background
$formInValidBackground: get-color(wewak);

$fontInvert: $white;
$fontInvertLight: get-color(suva-grey);
$fontGrey: get-color(vans-silver);
$fontGreyDark: $medium-gray;
$fontGreyLight: get-color(submarine);
$fontDark: get-color(eclipse-3);
$fontLink: get-color(picton-blue);
$fontLinkHover: get-color(primary);

$fontError: get-color(alert);
$fontInfo: get-color(lily-white);
$fontGeneric: get-color(secondary);
$fontSuccess: get-color(mountain-meadow);
$fontWarning: get-color(lily-white);

$pageUtilNavContent: recursive-map-merge(
  $pageUtilNavContent, (
    'font': (
      'font-family': $header-font-family
    )
  )
);
$pageUtilNavHeaderContent: recursive-map-merge(
  $pageUtilNavContent, (
    'font': (
      'font-weight': 700
    )
  )
);

//background color palette
$backgroundColor: get-color(twilight-blue);
$backgroundColorDark: get-color(nero);
$backgroundColorLight: $white;
$backgroundColorError: get-color(wisp-pink); //light red
$backgroundColorInfo: get-color(lily-white); //light green
$backgroundColorSuccess: get-color(lily-white); //light green
$backgroundColorUtility: get-color(pattens-blue); //light blue
$backgroundColorHighlight: get-color(cumulus); //light yellow
$backgroundColorGeneric: get-color(black-squeeze);
$suggested-address-highlighted-background-color: get-color(cumulus);

//spacing
$vertSpaceSmall: 15px;
$vertSpace: 30px;
$vertSpaceMed: 35px;
$vertSpaceLarge: 40px;
$vertSpaceJumbo: 80px;

//button background colors
$btnPrimaryBGColor: get-color(primary);
$btnSecondaryBGColor: get-color(secondary);
$btnHoverBGColor: $black;
$btnDisabledBGColor: $fontGreyLight;

//border style
$borderColor: get-color(feta-2);
$borderFocusColor: get-color(primary);
$borderDotted: 1px dotted $borderColor;
$borderSolid: solid $borderColor;

//  button configurations
$btnLinkFontStyle: (
  'font': (
    'font-family': $button-font-family,
    'font-size': em-calc(16px),
    'color': $white,
    'font-weight': 500,
    'letter-spacing': 1px,
    'text-transform': uppercase,
    'font-style': normal
  )
);

//PDP and Quickshop
$outOfStockOption: get-color(periwinkle);
$fontPdpPrice: get-color(primary);

//PDP drawer swatch size
$swatch-size: 55px;
$tablet-swatch-size: $swatch-size + 16px;
$mobile-swatch-size: $swatch-size - 5px;
$swatch-margin: 7px;
$colorSwatchWidth: rem-calc(50);
$colorSwatchHeight: rem-calc(50);


$brandColorPalette1: get-color(secondary);
//PDP Alt Views Variables
$altViewColorBorder: get-color(white-smoke-2);
$altViewsHeight: 80px;
$altImgSizeForDesktop: 68px;

//PDP Videos Variables
// $wcs_video_player_icon_color: #e72b1e !default;
// $wcs_video_player_max_width: 72px !default;
// $wcs_video_player_max_height: 72px !default;
// $wcs_video_player_margin_top: 1px !default;
// $wcs_video_player_margin_left: 1px !default;
// $wcs_video_player_tablet_margin_left: 1px !default;
// $wcs_video_player_icon_font_size: 46px !default;
// $wcs_video_player_icon_Line_Height: 74px !default;
// $wcs_video_player_container_top: 0 !default;
// $wcs_video_player_container_top_mobile: 0 !default;
// $wcs_video_player_tablet_max_width: 72px !default;
// $wcs_video_player_tablet_min_width: 72px !default;

// // YouTube Video button display
// $wcs_video_player_mobile_button_float: right !default;
// $wcs_video_player_mobile_button_padding_right: 1em !default;
// $wcs_video_player_mobile_button_margin_top: -25px !default;
// $wcs_video_player_mobile_button_font_size: 40px !default;
// $wcs_video_player_mobile_button_color: #7f7f7f !default;
// $wcs_video_player_margin_bottom: null !default;
// $wcs_video_player_mobile_positioning: null !default;
// $wcs_video_player_mobile_zindex: null !default;
// $wcs_video_player_mobile_right_position: null !default;
// $wcs_video_player_button_640_plus_display: none !default;
$wcs_video_player_button_1024_plus_display: block !default;

// $wcs_video_player_control_bar_display_property: null !default;

// $wcs_video_player_background_color_mobile: null !default;
// $wcs_video_player_background_color: null !default;
$wcs_video_player_container_background: $body-background !default;

// $wcs_video_player_thumbnail_image_background_size: 100% !default;
// $wcs_video_player_thumbnail_image_display: block !default;
// $wcs_video_player_thumbnail_image_position: relative !default;
// $wcs_video_player_thumbnail_icons_background: rgba(0, 0, 0, 0.3) !default;
// $wcs_video_player_thumbnail_icons_display: block !default;
// $wcs_video_player_thumbnail_icons_opacity: 0.9 !default;
// $wcs_video_player_thumbnail_icons_text_align: center !default;
// $wcs_video_player_thumbnail_icons_hover_background: rgba(0, 0, 0, 0.1) !default;
// $wcs_video_player_thumbnail_icons_hover_opacity: 1 !default;
// $wcs_video_player_icons_cursor: pointer !default;

$include_html_wcs_video_player_classes: true;

$wcs_video_player_container_top: 0;
$wcs_video_player_container_top_mobile: 10px;
$wcs_video_player_mobile_positioning: absolute;
$wcs_video_player_mobile_right_position: 0;
$wcs_video_player_mobile_zindex: 201;
$wcs_video_player_mobile_button_margin_top: -40px;
$wcs_video_player_button_640_plus_display: inline;
$wcs_video_player_margin_top: 0;
$wcs_video_player_margin_left: 0;
$wcs_video_player_icon_color: get-color(primary);
$wcs_video_player_tablet_max_width: 65px;
$wcs_video_player_tablet_max_width: 65px;
$wcs_video_player_background_color_mobile: $white;
$wcs_video_player_background_color: $body-background;
$wcs_video_player_mobile_button_padding_right: 0.75em;

$wcsPDPVideoQuickviewMarginTop: 0;
$wcsPDPVideoQuickviewMarginLeft: 0;


//Product Zoom Image
$productImgZoomWidth: 1280px;
$productImgZoomHeight: 750px;

//PDP Product Full Image - Tablet View
//$productImgFullMediumScreen: 590px;

//PDP Product Full Image - Mobile View
//$productImgFullSmallScreen: 360px;
//$productImgFullExtraSmallScreen: 300px !default;

//PDP Product Description
$overviewFontColor: get-color(fiord);
$overviewHeight: auto;

//PDP Product Detail Size Grid
$pdpAttributeBoxBorderOutOfStockSwatchImageBackgroundUrl: url('data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%20100%20100%27%3E%3Cpath%20d%3D%27M100%200%20L0%20100%20%27%20stroke%3D%27%23000000%27%20stroke-width%3D%271%27%2F%3E%3Cpath%20d%3D%27M0%200%20L100%20100%20%27%20stroke%3D%27%23000000%27%20stroke-width%3D%271%27%2F%3E%3C%2Fsvg%3E');
//PDP Product Form
$product-actions-secondary-link-button-color: get-color(picton-blue);
$product-actions-secondary-link-button-color-hover: get-color(primary);
$product-actions-secondary-link-button-font-weight: 700;

$quickview-box-margin-bottom: rem-calc(40);
$quickview-box-margin-bottom-medium: 0;

$quickview-product-actions-primary-position: fixed;
$quickview-product-actions-primary-position-left: 0;
$quickview-product-actions-primary-position-bottom: 0;
$quickview-product-actions-primary-width: 100%;
$quickview-product-actions-primary-padding: rem-calc(7);
$quickview-product-actions-primary-background-color: get-color(twilight-blue);
$quickview-product-actions-primary-margin-bottom: rem-calc(20);
$quickview-product-actions-primary-box-shadow: 0 rem-calc(-4) rem-calc(10) 0 rgba($black, 0.15);
$quickview-product-action-primary-add-to-cart-button-margin: 0;

$quickview-product-actions-primary-background-color-medium: none;
$quickview-product-actions-primary-position-bottom-medium: 0;
$quickview-product-actions-primary-box-shadow-medium: none;
$quickview-product-actions-primary-position-left-medium: 0;
$quickview-product-actions-primary-padding-medium: 0;
$quickview-product-actions-primary-position-medium: relative;
$quickview-product-actions-primary-width-medium: auto;
$quickview-product-actions-primary-margin-bottom-medium: rem-calc(20);

//PDP Product Detail Drawer
$productDetailsDrawerImageBorderColor: get-color(feta);

//PDP Cross-Sell
$pdpCrossSellBgColor: $white;
$pdpCrossSellExtrasFontFamily: get-font('franklin-gothic');
$pdpCrossSellExtrasFontSize: rem-calc(14);
$pdpCrossSellExtrasFontSizeMedium: rem-calc(16);
$pdpCrossSellExtrasFontWeight: bold;
$pdpCrossSellExtrasHeight: rem-calc(16);
$pdpCrossSellExtrasLetterSpacing: rem-calc(0);
$pdpCrossSellExtrasLineHeight: rem-calc(16);
$pdpCrossSellExtrasTextTransform: uppercase;
$pdpCrossSellExtrasWidth: rem-calc(46);
$pdpCrossSellFlexShrinkLarge: 0;
$pdpCrossSellFlexShrinkMedium: 1;
$pdpCrossSellFontSize: rem-calc(12);
$pdpCrossSellFontSizeMedium: rem-calc(13);
$pdpCrossSellFontWeight: 400;
$pdpCrossSellGridColumnEnd: 3;
$pdpCrossSellGridColumnStart: 1;
$pdpCrossSellLabelMargin: 0;
$pdpCrossSellLabelMarginRightMedium: 0;
$pdpCrossSellLabelTxtContainerPadding: rem-calc(15);
$pdpCrossSellMargin: 0 0 0 rem-calc(-8);
$pdpCrossSellMarginLeftLarge: rem-calc(6);
$pdpCrossSellMarginLeftMedium: rem-calc(7);
$pdpCrossSellMarginLeftXlarge: rem-calc(8);
$pdpCrossSellMarginMedium: 0;
$pdpCrossSellMarginTopLarge: 0;
$pdpCrossSellContainerMarginMedium: rem-calc(20) 0 0 0;
$pdpCrossSellMarginTopMedium: 0;
$pdpCrossSellMarginTopSmall: rem-calc(45);
$pdpCrossSellMarginTopXlarge: 0;
$pdpCrossSellPadding: rem-calc(10) rem-calc(10) 0 rem-calc(15);
$pdpCrossSellPaddingMedium: 0 0 rem-calc(25) 0 !important;
$pdpCrossSellPriceMarginLeftMedium: rem-calc(10);
$pdpCrossSellPriceMarginMedium: 0 0 0 rem-calc(7);
$pdpCrossSellTextColor: $black;
$pdpCrossSellTextDecoration: underline;
$pdpCrossSellTextDetailsDisplayXLarge: flex;
$pdpCrossSellTextDetailsGridTemplateColumns: auto 75%;
$pdpCrossSellTextDetailsPaddingTopMedium: rem-calc(6);
$pdpCrossSellTextDetailsPaddingXLarge: rem-calc(16) 0 0 0;
$pdpCrossSellWhiteSpaceMedium: nowrap;
$pdpCrossSellWidthMedium: 100%;
//Bloomreach MLT
$bloomreach_mlt_grid_icon_container_background: $white;
$bloomreach_mlt_grid_title_icon_font_color: $white;
$bloomreach_mlt_product_title_font_family: $body-font-family;

// Bloomreach Search Widget
$brSfWidgetImageWidth: calc(50% - #{$column-gutter});
$brSfWidgetImageMargin: auto $column-gutter auto auto;
$relatedSearchesTitleFontFamily: get-font(franklin-gothic);
$relatedSearchesTitleFontSize: 1rem;
$relatedSearchesLinksFontSize: rem-calc(16);
$relatedItemsTitleFontFamily: get-font(franklin-gothic);
$relatedItemsTitleFontSize: rem-calc(20);
$relatedItemsProductFontSize: rem-calc(16);
$showMoreTitleFontSize: rem-calc(16);
$relatedItemsTitleFontWeight: 600;
$brSfWidgetMerchantPopupImgMarginLarge: auto $column-gutter auto auto;
$brSfWidgetMerchantPopupViewMarginMedium: 5px 20px;
$brSfWidgetMerchantPopupDescPadding: 5px 20px;
$relatedItemsTitlePadding: 5px;
$brSfWidgetFourProductPadding: 0;
$brSfWidgetFourProductMarginRight: rem-calc(60);

$relatedItemsTitleMarginBottom: 0;
$brSfWidgetContentWidthMedium: 100%;
$relatedSearchesTitleFontSize: rem-calc(20);
$brRelatedSearchWidthMedium: rem-calc(332);
$brProductCompareWidthMedium: rem-calc(988);
$relatedSearchesTitleMargin: 0;
$relatedSearchesLineHeight: rem-calc(24);
$brRelatedSearcheborderRight: 0;
$brSfWidgetProductWidthMedium: rem-calc(201);
$brSfWidgetProductWidthLarge: $brSfWidgetProductWidthMedium;
$brSfWidgetImageWidthMedium: 100%;
$brSfWidgetContentWidthMedium: 100%;
$relatedItemsProductFontWeight: 600;
$relatedItemsProductDescFontSize: rem-calc(16);
$relatedItemsProductDescFontWeightMedium: 400;
$brSfWidgetDescLineHeight: rem-calc(24);
$brSfWidgetMerchantDescPadding: rem-calc(14) 0 rem-calc(20) 0;
$brSfWidgetImageHeightMedium: 100%;
$brSfWidgetMerchantQvFontSize: rem-calc(16);
$brSfWidgetMerchantTitleMedium: rem-calc(16) 0 0 0;
$brWidgetResultItemLastItemMargin: 0;
$brWidgetResultItemMarginMedium: 0;
$brWidgetResultItemPaddingMedium: 0 rem-calc(113) 0 0;
$brSearchWidgetContainerFlexDirectionMedium: column;
$brSearchWidgetContainerFlexDirectionLarge: $brSearchWidgetContainerFlexDirectionMedium;
$brSearchRelatedItemsWidgetOrderMedium: 1;
$brSearchRelatedCategoriesWidgetOrderMedium: 2;
$brSearchMoreResultWidgetOrderMedium: 3;
$brSearchWidgetContainerHeightMedium: rem-calc(500);
$brSearchRelatedCategoriesWidgetPaddingMedium: 0 rem-calc(113) 0 rem-calc(31);
$brSearchRelatedCategoriesWidgetBorderTopMedium: solid rem-calc(1) $light-gray;
$brSearchRelatedItemsWidgetPaddingMedium: 0 rem-calc(113) rem-calc(20) rem-calc(31);
$relatedSearchesTitleFontWeight: 600;
$relatedSearchesTitleMargin: 0;
$relatedResultsTitlePadding: rem-calc(24) 0 rem-calc(24) 0;
$relatedResultItemsTitlePadding: rem-calc(24) 0 rem-calc(16) 0;
$brRelatedItemsTitleMargin: 0;


// PDP Attributes
$pdpAttributeBoxFont: $body-font-family;
$productFormAttrFontSize: $form-label-font-size;



//PDP select a size error message
$pdpSelectSizeErrorBg: get-color(wisp-pink);
$pdpQuickViewCrossSellCloseButtonColor: get-color(picton-blue);

// PDP robis
$robis-accent-background: $white;
$robis-select-size-padding: 0;
$robis-finished-message-content-background: transparent;
$robis-finished-message-link-color: $anchor-color;
$robis-icon-success-color: get-color(islamic-green);
// form validation
$errorWidth: 100%;

//Background images
$BGImgWavyLineSeperator: url('../images/common/bg-wavyline.png') repeat-x top center;
$BGSectionSeperatorA: $BGImgWavyLineSeperator;

//Facet Breadcrumbs
$facetValueFontSize: 16px;
$facetCounterFontColor: $fontGreyDark;
$facetSelectedBackroundColor: $white;

$gridHeaderMarginTop: 0;
$gridHeaderRadioControlsMarginBottomMobile: 15px;

// grid filter UI
$filterAttrboxBorder: 1px solid $fontGrey !default;
$filterAttrboxBorderHover: $filterAttrboxBorder;
$filterAttrboxBgHover: $black;
//$filter-options-item-border-color: transparent;
$filterCounterColor: $facetCounterFontColor;
$filterLinkColor: $fontLink;
$filterLinkColorHover: $filterLinkColor;

//checkout header
$checkoutHeaderLiveChatAvailablePadding: 9px 0 0;
$checkoutHeaderLiveChatAvailablePaddingMedium: 2px 10px 0;
$checkoutHeaderStepsMarginTopSmall: 12px;

//Shipping and PickUp
$shipPickUpThumbnailLinkTextTransform: none;
$shipPickUpThumbnailStoreNameFontSize: rem-calc(12px);
$shipPickUpThumbnailStoreNameLineHeight: 12px;
$shipPickUpThumbnailLinkTextFontSize: rem-calc(12px);
$pickUpThumbnailLinkTextPaddingBottom: 50px;
$shipPickUpThumbnailLinkTextIconDisplay: inline-block;
$shipPickUpThumbnailHeaderTextPaddingBottom: 10px;
$shipPickUpThumbnailHeaderTextPaddingTop: 12px;
$pickUpInStoreFormInlineReasonFontSize: em-calc(12);
$pickUpInStoreFormEmailInlineReasonFontSize: rem-calc(12);
$pickUpInStoreHeaderFontSize: rem-calc(20);
$pickUpInStoreTitlePaddingTop: 15px;
$pickUpInStoreTitlePaddingBottom: 15px;
$pickUpInStoreSectionIndicatorBgColor: $white;
$pickUpInStoreSectionIndicatorBorder: 1px solid $light-gray;
$pickUpInStoreSectionIndicatorFontSize: 1rem !default;
$pickUpInStoreSectionIndicatorMargin: 0 10px 0 0 !default;
$pickUpInStoreSectionIndicatorPadding: 5px !default;
$pickUpInStoreSectionIndicatorDisplay: inline !default;
$pickUpInStoreSectionIndicatorWidth: null !default;
$pickUpInStoreNoteFontSize: em-calc(13);
$pickUpInStoreNoteFontColor: $medium-gray;
$pickUpInStoreEmailNoteFontColor: $medium-gray;
$pickUpInStoreRequiredFieldsNoteDisplay: none;

//shipping method advance checkout
$advanceCheckoutDeliveryMsgFontSize: 0.9em;
$advanceCheckoutDeliveryMsgFontWeight: normal;
$advanceCheckoutDeliveryMsgFontColor: $medium-gray;

// checkout content section title
$checkoutContentSectionTitleWidth: 100%;
$checkoutContentSectionTitleHeightPaddingLeft: 0;
$checkoutPromoCodeFormBackgroundColorSmall: $backgroundColor;
$checkoutPromoCodeFormBackgroundColor: $backgroundColorUtility;
$checkoutPromoCodeContainerMarginTopLarge: $form-label-line-height * $form-label-font-size;
$checkoutPromoCodeContainerMargin: 10px 10px 10px;
$checkoutPromoCodeContainerOverflow: hidden;
$checkoutPromoCodeContainerFloatLabelActiveOverflow: visible;
$checkoutPromoCodeLabelWhiteSpace: nowrap;
$checkoutPromoCodeTitleFontSize: rem-calc(24px);
$checkoutPromoCodeTitleFontWeight: 700;
$checkoutPromoCodeTitlePadding: 0;
$checkoutPromoCodeTitleLetterSpacing: normal;

$checkoutGiftCardCheckTop: 100px;
$checkoutGiftCardCheckTopMedium: 80px;
$checkoutGiftCardCheckTopLarge: inherit;
$checkoutGiftCardCheckLeft: 65%;
$checkoutGiftCardCheckLeftMedium: 50%;
$checkoutGiftCardCheckMarginBottom: 6px;
$checkoutGiftCardCheckMarginBottomMedium: 0;
$checkoutGiftCardPinErrorIconPaddingRight: 5px;
$checkoutGiftCardErrorTextPaddingTop: 0;
$checkoutGiftCardNumberMarginTop: $form-label-line-height * $form-label-font-size;
$checkoutGiftCardPinMarginTop: $form-label-line-height * $form-label-font-size;
$checkoutGiftCardNumberWidth: 80%;
$checkoutGiftCardNumberMediumWidth: 60%;
$checkoutGiftCardNumberLargeWidth: 30%;

$checkoutSavedCreditCardInfoPaddingTop: 10px;
$checkoutSavedCreditCardInfoPaddingBottom: 10px;
$checkoutTermsAndConditionsTextColor: $fontInvert;
$checkoutTermsAndConditionsTextFontSize: em-calc(13);
$creditCardSecurityCodeToolTipPadding: 10px;

$checkoutShippingAvsOverrideResponseLabelTextAlign: left;
$checkoutShippingAvsOverrideResponseLabelWidth: auto;

//Checkout Section Indicator
$checkoutSectionIndicatorBgColor: $white;
$checkoutSectionIndicatorBorder: 1px solid $light-gray;

//checkout - Shipping
$checkoutShippingForgotPasswordPaddingTop: rem-calc(35);

// order review
$order_review_checkout_page_background: none;
$order_review_checkout_page_padding_top_small: 0;

// order confirmation
$order_confirmation_info_detail_contact_name_width: 9em;
$order_confirmation_info_detail_container_display_medium: flex;
$order_confirmation_checkout_shipment_info_header_background: $BGImgWavyLineSeperator;
$order_confirmation_checkout_shipment_info_header_padding_top: 25px;
$order-summary-title-padding-top: 20px;
$order-summary-title-padding-top-medium: 0;
$orderItemDetailInfoMarginBottom: 16px;
$orderItemDetailInfoMarginTop: 16px;
$orderItemDetailAvailableNowMessagePaddingLeft: 10px;
$orderItemDetailAvailableNowMessagePaddingRight: 10px;
$orderItemDetailAvailableNowMessageFontColor: get-color(mountain-meadow);
$order_confirmation_billing_info_detail_container_display_medium: grid;
$order_confirmation_billing_info_detail_container_grid_columns: auto auto;

$checkoutEditButtonColor: $fontLink;
$checkoutEditButtonFontSize: rem-calc(12);
$checkoutEditButtonTextTransform: none;
$checkoutEditButtonFocusColor: get-color(primary);
$checkoutEditButtonLetterSpacing: normal;

//Product Block
$priceFontColor: get-color(primary);
$originalPriceColor: $black;
$originalPriceFloat: left;
$originalPriceMarginRight: rem-calc(7);
$originalPriceTextDecoration: line-through;
$offerPriceColor: $priceFontColor;
$badgeFontColor: get-color(primary);
$productNameFontColor: $fontGeneric;
$productNamePriceDividerFontColor: $negativeLightText;
$counterFontColor: get-color(trout);
$viewMoreFontColor: $hyperlinkText;
$refineSearchFontColor: get-color(white-smoke-3);
$refineSearchBtnBG: get-color(secondary);
$productPriceFontSize: 16px;
$productPriceFontSize-Mobile: 16px;
$product-block-image-helper-text-display: none;
$productPriceFontStyle: (
  'font': (
    'font-size': $productPriceFontSize-Mobile
  ),
  'medium': (
    'font-size': $productPriceFontSize
  )
);
$primaryFontBold: $header-font-family;
$productInfoPriceFontStyle: (
  'font': (
    'font-size': em-calc(16px)
  )
);
$product-block-price-display: flex;
$product-block-price-original-price-margin-right: rem-calc(8);
$product-block-price-original-price-margin-right-large: rem-calc(16);
$productBlockIconDisplay: none;
$productIconFavoriteFontSize: rem-calc(16);
$productIconFavoriteMarginTop: rem-calc(8);
$categoryHeaderTitleFontSize: 41.84px;
// $resultCounterFontSize: em-calc(13px);
$resultCounterInnerFontSize: 1em;
$productBlockMargin: 38px;
$productBlockInfoMargin: 12px;

$headerHeroDescFontSize: 13px;
$viewMoreResultsFontSize: 13px;
$OTWHeaderTitleFontSize: 108px;
$refineSearchBtnFontSize: 18px;
$quickshopBtnFontSize: 15px;
$quickshopBtnFontWeight: 300;
$quickshopBtnFontSizeSmall: 0.8em;
$productNameFontSize: 13px;
$catologProductNameFontSize: 16px;
$heroProductNameFontSize: 16px;
$heroBadgeFontSize: 20px;

$heroQuickshopBtnTop: 65%;
$heroQuickshopBtnWidth: 40%;
$heroQuickshopBtnLeft: 30%;

$quickshopBtnTop: 70%;
$quickshopBtnWidth: 72%;
$quickshopBtnLeft: 14%;

//Quick View
$quickViewBGColor: $reveal-background;
$notificationBGColor: get-color(pattens-blue);
$notificationFontSize: 13px;
$pdpContentFormFavouritesNotificationPosition: unset;
$pdpContentFormFavouritesNotificationTop: 0;
$pdpContentFormFavouritesNotificationLeft: 0;
$pdpContentFormFavouritesNotificationLeftMedium: rem-calc(250);
$pdpContentFormFavouritesNotificationLeftLarge: rem-calc(420);
$pdpContentFormFavouritesNotificationMarginLeft: rem-calc(10);
$pdpContentFormFavouritesNotificationMarginRight: rem-calc(10);
$dot-wave-color: black;
$dot-wave-top: rem-calc(-32);
//Quick Shop
$quickViewProductViewsContainerDisplay: block;

//Mini CArt & Wishlist
$miniCartArrowMobileRight: rem-calc(77);
$wishlistMobileLinksEnabled: true;
$wishlistProductPhotoBkgdColor: inherit;
$wishlistRemoveWishlistIcon: $icon-close;
$topPanelShopCartPadding: 8px 0;
$topPanelShopCartPaddingTablet: 8px 10px;
$wishlistBodyPaddingTop: 20px;
$topMiniCartIconCloseDisplay: none;
$miniCartCloseIconPosition: static;
$miniCartNotificationTextAlign: center;
$miniCartNotificationTextTransform: none;
$miniListCartActionsOrTextMargin: 15px 0;
$miniCartNotificationIconCloseDisplay: none;

//address book
$addressEntryFontFamily: $header-font-family;
$addressEntryFontWeight: bold;
$addressFormInlineReasonSmallFontSize: em-calc(12, 16);
$addressFormInlineReasonMediumWidth: 78%;
$addressForminlineReasonMediumFloat: left;
$addressFormInlineReasonMediumMarginTop: 0;
$addressForminlineReasonMediumMarginBottom: rem-calc(20px);
$addressFormInlineReasonDisplay: block;
$addressFormInlineReasonSmallDisplay: none;
$billingAddressFormInlineReasonMediumWidth: $addressFormInlineReasonMediumWidth;
$billingAddressFormInlineReasonMediumMarginBottom: $column-gutter/2;

//Espots Grid
$espotFullGridDescFontColor: get-color(dim-gray);
$espotSecondaryDescFontColor: $medium-gray;
$espotSplitBG: get-color(white-smoke-4);
$espotFullGridDescFontSize: 13px;
$espotSplitDescFontSize: 15px;

//Where To Get It
$locationMapPrimaryFontColor: get-color(secondary);
$locationMapSecondaryFontColor: get-color(picton-blue-2);
$locationAddressNameFontSize: 15px;
$locationAddressFontSize: 13px;

//Hopup
$closeBtnColor: get-color(star-dust);
$closeBtnColorHover: darken(get-color(secondary), 60%) !default;
$closeBtnFontSize: 22px;



//Feature Heading/Footing
$featureContentFontStyle: (
  'font': (
    'font-family': get-font('franklin-gothic'),
    'font-size': em-calc(12px),
    'font-weight': 700,
    'line-height': 13px,
    'font-style': normal
  )
);

//Breadcrumb
$breadcrumbFontColor: get-color(picton-blue);
$breadcrumbFontSize: 13px;
$breadcrumbFontSizeMobile: 12px;
$breadcrumbContent: "\2044";
$breadcrumbLastItemFontColor: $medium-gray;
$breadcrumbLastItemFontSize: 13px;
$breadcrumbLastItemContent: "\2044";
$breadcrumbLastItemLineHeight: 1.4em;
$breadcrumbHyperLinkHoverTextDecoration: none;
$breadcrumbLastSpanHoverTextDecoration: none;

//my account - navigation
$accountNavBkgdColor: get-color(submarine);
$accountNavColor: $white;
$accountNavDropdownBkgdColor: get-color(nero);
$accountNavDropdownColor: $white;
$accountNavDropdownActiveBkgdColor: get-color(submarine);
$accountNavDropdownActiveFontStyle: italic;

$myaccountProfileSinglePasswordRequirementMarginBottom: 20px;
$myaccountProfileSinglePassColor: get-color(fiord);
$myaccountProfileSinglePassFontSize: rem-calc(14);

// Enhance Registration
$enhancedRegistrationPasswordRequirementsRuleColor: $light-gray;
$enhancedRegistrationPasswordRequirementsValidRuleColor: get-color(mountain-meadow);
$enhancedRegistrationPasswordRequirementsTextContainerPadding: 5px 0;
$enhancedRegistrationPasswordRequirementsInputPaddingRight: 0;
$enhancedRegistrationPasswordRequirementsPrivacyPolicyFontSize: 14px;
$enhancedRegistrationPasswordRequirementsPrivacyPolicyMarginBottom: 13px;
$enhancedRegistrationPasswordRequirementsFontSize: 14px;
$enhancedRegistrationPasswordRequirementsLineHeight: 21px;
$enhancedRegistrationPasswordRequirementsTextTitleColor: get-color(fiord);
$enhancedRegistrationPasswordRequirementsValidRuleTextColor: get-color(fiord);
$enhancedRegistrationPasswordRequirementsEmailContainerWidthMedium: 100%;
$enhancedRegistrationPasswordRequirementsPasswordContainerWidthMedium: 100%;
$enhancedRegistrationPasswordRequirementsEmailSignUpFontSize: rem-calc(14);
$enhancedRegistrationPasswordRequirementsPrivacyPolicyWidthMedium: 100%;
$enhancedRegistrationPasswordRequirementsFirstNamePaddingRightMedium: 5px;

// my account- Credit Card and Add Credit Card Pages
$myAccountCCEntryMobileHeight: auto;
$myAccountCCEntryMobilePadding: 4px 10px 10px;
$myAccountCCEntryTop: 10px;
$myAccountCCAddressPhoneColumnWidthSmall: 4.5;
$myAccountCCAddressPhoneColumnWidthMedium: 3.5;
$myaccountCCAddressFullAddressColumnWidthSmall: 7.5;
$myaccountCCAddressFullAddressColumnWidthMedium: 8.5;
$myaccountCCImagesContainerMarginLeftLarge: 0;
$myaccountCCImagesContainerMarginBottom: $column-gutter;

//My Account Create Account
$myAccountCreateAccountFirstNamePaddingRightMedium: 5px;


//My Account Loyalty Create Acccount
$myAccountRegistrationSplitContentTopMedium: 50%;
$myAccountRegistrationMinHeightMedium: 400px;
$myAccountRegistrationLoyaltyJoinContainerImageUrl: url('#{$scene7-path}/is/image/VansBrand/list-star?$SCALE-ORIGINAL$');
$myAccountRegistrationLoyaltyListImageUrl: url('#{$scene7-path}/is/image/VansBrand/list-star?$SCALE-ORIGINAL$');
$myAccountRegistrationLoyaltyImageUrl: url('#{$scene7-path}/is/image/VansBrand/Login-Mobile?$SCALE%2DORIGINAL$');
$myAccountRegistrationSplitContentHrMargin: 12px;
$myAccountRegistrationSplitContentMinHeight: 0;
$myAccountRegistrationLoyaltyJoinLogoWidth: 250px;
$myAccountRegistrationLoyaltyJoinTextFontSize: 20px;
$myAccountRegistrationLoyaltyJoinExclusiveContentFontSize: 13px;
$myAccountRegistrationSplitContentPaddingBottom: 0;
$myAccountRegistrationSplitContentMargin: 0;
$myAccountRegistrationSplitContentMarginLeft: 20px;
$myAccountRegistrationSplitContentHrMarginTop: 10px;


//My Account Sign In
$myAccountSignInMarginBottom: 20px;

// account order history
$orderItemDetailDescriptionWidthTablet: 55%;
$orderItemDetailQtyWidthTablet: 17%;
$orderItemDetailPriceWidthTablet: 14%;
$orderItemDetailTotalWidthTablet: 14%;
$orderItemInfoPaddingLeftMobile: 5px;
$orderItemInfoLabelWidthMobile: 40%;
$orderItemInfoValueWidthMobile: 60%;
$lookUpBorderColor: '';
$policyBordercolor: '';
$orderItemShipmentInfoHeaderBackground: url('../images/common/bg-wavyline.png') repeat-x top center;
$orderItemShipmentInfoHeaderPadding: 10px;
$orderItemTableMargin: 20px;
$orderItemShipmentDetailsFontFamily: get-font('franklin-gothic');
$orderItemPickupColor: get-color(mountain-meadow);

// Loyalty Enrollment Form
$loyalty-enrollment-form-background: url('#{$scene7-path}/is/image/VansBrand/vans-paper-white?wid=400&qlt=80');
$loyalty-container-padding: 0;
// $loyalty-enrollment-logo-margin: 0px auto 0.5em !default;
// $loyalty-enrollment-marker-size: 10px !default;
// $loyalty-enrollment-marker-margin: 0px auto 0.5em !default;
// $loyalty-enrollment-marker-border-color: null !default;
$loyalty-enrollment-marker-border-color: get-color(very-light-grey);
// $loyalty-enrollment-marker-active-color: null !default;
$loyalty-enrollment-marker-active-color: get-color(secondary);
// $loyalty-enrollment-marker-transition-speed: 0.3s !default;
// $loyalty-enrollment-tab-inactive-opacity: 0.7 !default;
// $loyalty-enrollment-form-button-margins: 1em !default;
//$loyalty-enrollment-myaccount-signup-link-color: null !default;
$loyalty-enrollment-myaccount-signup-link-color: get-color(secondary);
$loyalty-enrollment-enhanced-password-requirements-single-pass-color: get-color(fiord);
$loyalty-enrollment-enhanced-password-requirements-single-pass-font-style: normal;
$loyalty-enrollment-enhanced-password-requirements-single-pass-font-size: rem-calc(14);

$loyalty-enrollment-background-image-medium: url('#{$scene7-path}/is/image/VansBrand/Step1-Desktop?$SCALE%2DORIGINAL$');
$loyalty-enrollment-background-min-height-medium: 775px;
$loyalty-enrollment-text-align-medium: center;
$loyalty-enrollment-top-medium: 0;
$loyalty-enrollment-logo-padding: 100px;
$loyalty-enrollment-text-padding: 10px;
$loyalty-enrollment-text-padding-medium: 15px;
$loyalty-enrollment-list-style-image: url('#{$scene7-path}/is/image/VansBrand/list-star?$SCALE-ORIGINAL$');
$loyalty-enrollment-list-padding-bottom: 5px;
$loyalty-enrollment-list-padding-bottom-medium: 10px;
$loyalty-enrollment-right-background-color: $white;
$loyalty-enrollment-right-margin-bottom: 30px;
$loyalty-enrollment-right-float-medium: right;
$loyalty-enrollment-right-control-margin-bottom: 25px;
$loyalty-enrollment-right-tooltip-font-size: 13px;
$loyalty-enrollment-right-tooltip-position: absolute;
$loyalty-enrollment-right-position-right: 5px;
$loyalty-enrollment-right-position-top: -15px;
$loyalty-enrollment-right-error-padding-top: 23px;
$loyalty-enrollment-right-error-padding-top-medium: 23px;
$loyalty-enrollment-right-registration-button-margin: 0;
$loyalty-enrollment-right-email-margin-bottom: 39px;
$loyalty-enrollment-right-email-margin-bottom-medium: 30px;
$loyalty-enrollment-right-checkbox-margin-top: 5px;
$loyalty-enrollment-right-policy-left: 0;
$loyalty-enrollment-password-hint-font-size: 11px;
$loyalty-enrollment-password-hint-margin-bottom: 8px;
$loyalty-enrollment-password-input-margin-bottom: 5px;
$loyalty-enrollment-checkbox-label-margin-left: 30px;
$loyalty-enrollment-checkbox-error-margin-top: 7px;
$loyalty-enrollment-country-phone-flag-padding-left: 0;
$loyalty-enrollment-country-phone-flag-padding-top: 12px;
$loyalty-enrollment-mobile-phone-label-margin-left: 50px;
$loyalty-enrollment-mobile-phone-margin-left: 50px;
$loyalty-enrollment-mobile-phone-width: 86%;
$loyalty-enrollment-mobile-phone-width-large: 92%;
$loyalty-enrollment-form-error-margin-top: 0;
$loyalty-enrollment-country-phone-flag-line-height: 22px;
$loyalty-enrollment-country-phone-flag-image-padding-left: 0;
$loyalty-enrollment-country-phone-flag-image-padding-top: 5px;
$loyalty-enrollment-country-phone-flag-image-width: 25px;
$loyalty-enrollment-country-flag-phone-label-margin-left: 50px;
$loyalty-enrollment-country-flag-phone-margin-left: 50px;
$loyalty-enrollment-country-flag-phone-width: 86%;
$loyalty-enrollment-country-flag-phone-width-medium: 92%;
$loyalty-enrollment-country-prefix-phone-label: 80px;
$loyalty-enrollment-country-prefix-phone-flag-label-padding-left: 30px;
$loyalty-enrollment-country-prefix-phone-flag-label-padding-top: 10px;
$loyalty-enrollment-country-prefix-phone-flag-margin-left: 80px;
$loyalty-enrollment-country-prefix-phone-flag-width: 78%;
$loyalty-enrollment-country-prefix-phone-flag-width-large: 87%;
$loyalty-enrollment-reduced-form-min-height: 655px;
$loyalty-enrollment-position-medium: absolute;
$loyalty-enrollment-left-medium: 0;
$loyalty-enrollment-list-text-align: left;
$loyalty-enrollment-welcome-width: 65%;
$loyalty-enrollment-welcome-text-width: 80%;



// Loyalty Shoe Size Selector
// $loyalty-shoe-size-selector-option-height: 5em !default;
// $loyalty-shoe-size-selector-background-color: $white !default;
// $loyalty-shoe-size-selector-border-color: $black !default;
// $loyalty-shoe-size-selector-font-color: $black !default;
// $loyalty-shoe-size-selector-font-size: 1em !default;
// $loyalty-shoe-size-selector-font-hover-color: null !default;
// $loyalty-shoe-size-selector-font-active-color: null !default;
// $loyalty-shoe-size-selector-category-height: 41px !default;
// $loyalty-shoe-size-selector-category-wrapper-padding: 0.5em 2em 0.5em 0.5em !default;
// $loyalty-shoe-size-selector-option-shadow: 1px 2px 1px 1px $black inset !default;
// $loyalty-mobile-shoe-size-selector-option-height: 4em !default;

$loyalty-shoe-size-selector-option-height: 4em;
$loyalty-shoe-size-selector-background-color: $white;
$loyalty-shoe-size-selector-border-color: get-color(whisper-2);
$loyalty-shoe-size-selector-font-color: $black;
$loyalty-shoe-size-selector-font-size: 1em;
$loyalty-shoe-size-selector-font-hover-color: get-color(dim-gray);
$loyalty-shoe-size-selector-font-active-color: get-color(primary);

$loyalty-shoe-size-selector-category-height: 41px;
$loyalty-shoe-size-selector-category-wrapper-padding: 0.5em 2em 0.5em 0.5em;
$loyalty-shoe-size-selector-option-shadow: 1px 2px 1px 1px get-color(white-smoke-5) inset;
$loyalty-mobile-shoe-size-selector-option-height: 3em;

//Pikaday Color Variables
$pd-day-selected-bg: get-color(primary);
$pd-day-hover-bg: get-color(primary);
$pd-day-selected-shadow: get-color(twilight-blue);

// Loyalty Dashboard
$loyalty-dashboard-background: url('#{$scene7-path}/is/image/VansBrand/vans-paper-beige?wid=400&qlt=80');

$loyalty-dashboard-header-background: $white;
$loyalty-dashboard-header-height: 100px;
$loyalty-dashboard-header-font-size: 1em;
$loyalty-dashboard-header-name-font-size: 1.5em;
$loyalty-dashboard-header-font-active: get-color(primary);
$loyalty-dashboard-header-font-hover: get-color(dim-gray);
$loyalty-dashboard-header-logo-width: 90px;
$loyalty-dashboard-header-link-color: $black;
$loyalty-dashboard-header-link-border-color: get-color(white-smoke-4);
$loyalty-dashboard-header-link-borders: 1px solid $loyalty-dashboard-header-link-border-color;
$loyalty-dashboard-header-link-padding: 42px 20px;

$loyalty-dashboard-header-mobile-top-height: 80px;
$loyalty-dashboard-header-mobile-bottom-height: 45px;
$loyalty-dashboard-mobile-header-link-padding: 15px 20px;

$loyalty-dashboard-title-margin: 20px 0;
$loyalty-dashboard-title-text-padding: 0 20px;
$loyalty-dashboard-title-text-size: 2em;
$loyalty-dashboard-title-mobile-text-size: 1.3em;

$loyalty-dashboard-basecomponent-title-padding: 40px 0;
$loyalty-dashboard-garnish-wrapper-background: $white;
$loyalty-dashboard-garnish-wrapper-padding: 20px 0;

// Loyalty Multi-Image Selector
$loyalty-multi-image-selector-option-vertical-spacing: 20px;
$loyalty-multi-image-selector-option-border: 1px solid $black;
$loyalty-multi-image-selector-option-opacity: 0.6;
$loyalty-multi-image-selector-active-color: get-color(jade);

$loyalty-multi-image-selector-font-color: $medium-gray;
$loyalty-multi-image-selector-font-size: 0.75em;
$loyalty-multi-image-selector-label-padding: 8px 5px;
$loyalty-multi-image-selector-label-background: rgba($white, 0.8);


$loyalty-multi-image-selector-icon-top: 5px;
$loyalty-multi-image-selector-icon-right: 5px;
$loyalty-multi-image-selector-icon-size: 1em;

$loyalty-mobile-multi-image-selector-option-vertical-spacing: 10px;

// grid filter UI

$filterOverlayBg: $backgroundColor;
$filterGroupBorderBottom: $borderDashed;
$filterGroupMarginBottom: 5px;
//$filterGroupPadding: 10px 0px 10px 0px !default;
$filterGroupPadding: 10px;
//$filterTitleColor: null !default;
//$filterTitleHeight: 30px !default;
//$filterTitleFontSize: convert-to-em(20px) !default;
//$filterTitleFontWeight: null !default;
//$filterTitleTextTransform: inherit !default;
//$filterTitlePadding: 0 0 0 10px !default;
$filterTitlePadding: 0;
//$filterTitletoggleColor: $fontLink !default;
//$filterOptionsMargin: 0 !default;
$filterOptionsPadding: 0;
// $filterOptionsItemHeight: 40px !default;
$filterOptionsItemHeight: auto;
// $filterOptionsItemLineHeight: 40px !default;
$filterOptionsItemLineHeight: normal;
// $filterOptionsItemHeightMobile: 40px !default;
$filterOptionsItemHeightMobile: auto;
// $filterOptionsItemLineHeightMobile: 40px !default;
$filterOptionsItemLineHeightMobile: normal;
// $filterOptionsItemPadding: 0 0 0 10px !default;
$filterOptionsItemPadding: 8px 0 8px 10px;
// $filterOptionsItemMargin: 0 0 5px 0 !default;
// $filterOptionsItemBorder: 2px solid #fff !default;
// $filterOptionsItemFontSize: convert-to-em(16px) !default;
// $filterOptionsMoreItemFontSize: convert-to-em(13px) !default;
// $filterOptionsItemSizeFontSize: convert-to-em(14px) !default;
// $filterOptionsSelectedItemBgColor: #fff !default;
// $filterOptionsSelectedItemBgColorHover: #fff !default;
// $filterOptionsSelectedItemFontColor: $body-font-color !default;
// $filterOptionsSelectedItemFontColorHover: $body-font-color !default;
// $filterAttrboxSize: 41px !default;
// $filterAttrboxBorder: 1px solid $fontGrey !default;
// $filterAttrboxBorderHover: 1px solid $fontGrey !default;
// $filterAttrboxInsetBorderHover: 0 0 0 1px get-color(secondary) inset !default;
// $filterAttrboxBgHover: $backgroundColor !default;
// $filterAttrboxFontColor: $fontLink !default;
// $filterAttrboxFontColorHover: #fff !default;
// $filterAttrboxSelectedBg: get-color(secondary) !default;
// $filterAttrboxSpacing: 1px !default;
// $filterLinkColor: $fontLink !default;
// $filterLinkColorHover: $fontLink !default;
// $filterLinkSizeAttrColorHover: $fontLink !default;
// $filterCounterColor: $fontLinkHover !default;
// $filterLinkHeight: convert-to-em(25) !default;
// $filterLinkActionsMargin: 5px 0 0 10px !default;
$filterLinkActionsMargin: 5px 0px 0px 0px;
// $filterRefineSearchButtonIcon: $icon-arrow-down-indicator !default;
// $filterRefineSearchButtonIconSelected: $icon-arrow-up-indicator !default;
// $filterRefineSearchButtonIconColor: null !default;
// $filterBoxHeightMobile: 38px !default;
// $filterBoxLineHeightMobile: 38px !default;
// $filterBoxPaddingTopMobile: 0 !default;
// $filterAttrBoxCenterLineHeight: null !default;
$filterOptionsItemTextAlign: center;
$filterOptionsItemHeightReset: 100%;
$filterOptionsTitleIconTopPosition: 50%;
$filterOptionsTitleIconTransform: translateY(-50%);
$filterOptionsTitleMinHeight: rem-calc(50);


//variables for filter accordion.
//$filterBarAccordionEnabled:false !default;
$filterBarAccordionEnabled: true;
// $filterAccordianExpandedStateIconfont: null !default;
$filterAccordianExpandedStateIconfont: $icon-minus;
// $filterAccordianCollapsedStateIconfont: null !default;
$filterAccordianCollapsedStateIconfont: $icon-plus;
//$filterAccordianIconfontsize: 16px  default;
//$filterAccordianIconRightPosition: 10px !default;
$filterAccordianIconRightPosition: 0;
// $filterOptionOpacity: null !default;
$filterOptionOpacity: 1;
// $filterOptionMaxHeight: null !default;
$filterOptionMaxHeight: 2000px;
// $filterOptionCollapsedMaxHeight: null !default;
$filterOptionCollapsedMaxHeight: 0;
// $filterOptionCollapsedOverflow: null !default;
$filterOptionCollapsedOverflow: hidden;
// $filterOptionCollapsedOpacity: null !default;
$filterOptionCollapsedOpacity: 0;
//$filterTitleAccordianCursor: null !default;
$filterTitleAccordianCursor: pointer;

$mobileSearchBtnColor: get-color(secondary);
$filterRefineSearchPaddingTop: 17.06;
//$filterRefineSearchPaddingLeft: 27 !default;
$filterRefineSearchPaddingBottom: 17.06;

$filterRefineSearchFontStyle: (
  'font': (
    'font-family': $header-font-family,
    'font-size': rem-calc(15),
    'color': $white,
    'letter-spacing': trackingToEm(0),
    'text-transform': uppercase
  )
);

// topbar navigation.
$mobile-topbar-height: 59px;
$mobile-topbar-button-width: 54px;
$mobile-topbar-button-width-narrow: 54px !default;
$topbar-link-font-size: em-calc(16, 16);
$topbar-upper-font-color: get-color(nobel-4);
$topbar-upper-font-size: 12px;
$topbar-upper-mobile-link-size: 15px;


$topbar-search-field-border: 1px solid $light-gray;
$topbar-search-field-background: transparent;


$topbar-search-form-width: 180px;
$topbar-search-form-width-medium: 180px;
$topbar-search-form-zindex-medium: null !default;
$topbar-search-form-margin: 0;
$topbar-search-container-icon-font-size: em-calc(15);
$topbar-search-container-icon-color: $medium-gray;
$topbar-search-tablet-top-position: 50px;
$topbar-search-top: 50px;

$topbar-height: 95px;
$topbar-link-color-hover: get-color(fire-engine-red);
$topbar-link-font-size: 1em;
$topbar-breakpoint: 640px;
$topbar-nav-seperator: "'/'";
$upperNavText: $light-gray;
$topbar-upper-font-color: $upperNavText;
$topbar-dropdown-mega-column-width: 140px;
$topbar-dropdown-featured-items: 277x;
$borderShadow: get-color(bastille);
$topbar-utility-cart-icon: $icon-shopcart;
$topbar-L1-image-hover-filter: invert(26%) sepia(75%) saturate(5353%) hue-rotate(342deg) brightness(80%) contrast(100%);
$topbar-L1-image-top: -2px;

$topbar-cart-badge-size: 18px;
$topbar-cart-badge-color: get-color(primary);
$topbar-cart-badge-text: $white;
$topbar-wish-badge-size: 18px;
$topbar-wish-badge-color: get-color(vans-grey-3);
$topbar-wish-badge-text: $white;

$returnHistorySubTitleFontSize: convert-to-em(14);
$upperLinksColor: $topbar-upper-font-color;
$topPanelShopCartStyleBackground: transparent;
$topPanelShopCartStyleColor: $topbar-upper-font-color;
$topPanelCountrySelectorMargin: 0;

$searchResultKeywordLink: block;

// Shop the look
$vfdpShopTheLookEnabled: true;

$stlProductFormContainerBottomMargin: 40px;

//checkout
$iconQuestionColor: get-color(pink-swan-3);
$checkoutTotalDetailIconHelpColor: $white;
$checkoutTotalDetailBackground: $backgroundColorDark;
$checkoutTotalDetailLoyaltyBackground: url('#{$scene7-path}/is/image/VansBrand/checkers-desktop?wid=34&qlt=80');
$checkoutTotalDetailLoyaltyBackgroundColor: $backgroundColorLight;
$checkoutTotalDetailLoyaltySmallBackground: url('#{$scene7-path}/is/image/VansBrand/checkers-mobile?wid=31&qlt=80');
$checkoutTotalListBackground: $backgroundColorGeneric;
$checkoutSummaryLabelSmallFontSize: convertPxToVw(header-font-size(h3, small), 320);
$checkoutSummaryLabelMediumFontSize: em-calc(header-font-size(h3, medium));
$checkoutSummaryLabelLargeFontSize: em-calc(header-font-size(h3, medium));

//Checkout Order Summary
$checkout-summary-item-detail-delivery-pickup-info-color: get-color(jade);
$checkout-summary-item-detail-info-overflow-wrap: break-word;

//checkout billing
$savedCCDisplayImgHeight: 50px;
$entryItemDetailTextLineHeight: initial;
$checkoutSpacingBelowPaymentMethods: 0;
$bilingEntryItemDetailWidthMedium: 90%;
$formLabelMediumUpTextAlign: left;
$formLabelMediumUpWidth: 15%;
$formValidationIconWidth: auto;
$formValidationErrorMessagePaddingTopMedium: 0;
$floatLabelInputValidationErrorMarginBottom: 0;
$floatLabelInputValidationErrorMarginBottomLarge: 10px;
$formCountryLabelFloat: none;

//STS
$ship_to_store_find_a_store_title_margin_bottom: $column-gutter;

//Shopcart
$shopcart_item_quantity_dropdown_arrow_position_top: 20px;
$shopcart_item_quantity_dropdown_arrow_position_top_small: 17px;
$orderItemDetailPromotionColor: get-color(eclipse);
$orderItemDetailPromotionFloat: left !important; // sass-lint:disable-line no-important
$orderItemDetailPromotionFontSize: rem-calc(16);
$orderItemDetailPromotionMarginTop: 20px;
$orderItemDetailPromotionWidth: 100%;
$orderItemDetailPromotionMarginLeftMedium: grid-column(3);
$orderItemDetailPromotionPaddingLeft: rem-calc(10);
$orderItemDetailPromotionWidthMedium: grid-column(9);
$orderItemDetailPromotionMarginLeftLarge: grid-column(2);
$orderItemPromotionTitleFontWeight: 400;
$orderItemPromotionTitleTextTransform: uppercase;
$orderItemPromotionTextColor: get-color(primary);

//---------------------------------------------------------------
// Delivery Tile Wrapper Component
//
// Creates container/tiles for delivery options on delivery/shipping page
//---------------------------------------------------------------
//$include-delivery-tile-wrapper-classes: false;
$include-delivery-tile-wrapper-classes: true;
//$delivery_tile_border_color: none;
$delivery_tile_border_color: get-color(nero);
//$delivery_tile_bg_color: none;
$delivery_tile_bg_color: get-color(twilight-blue);
//$delivery_tile_active_bg_color: none;
$delivery_tile_active_bg_color: $white;
$topPanelEnabled: false;



//---------------------------------------------------------------
//
// Address book tile layout
//
//---------------------------------------------------------------
// $include-address-book-tile-layout-html-classes: false;
$include-address-book-tile-layout-html-classes: true;
// $default-address-brand-color: null;
$default-address-brand-color: get-color(nero);

//---------------------------------------------------------------
//
// Search Results Tabs
//
//---------------------------------------------------------------
// $include-html-search-results-tabs-classes: false;
// $searchTabBackground: null;
$searchTabBackground: $white;
// $searchTabFont: null;
$searchTabFont: $header-font-family;
// $searchTabFontColor: null;
// $searchTabBorderActive: null;
$searchTabBorderActive: 0;
// $searchTabBorderDefault: null;
$searchTabBorderDefault: 0;


///////////////////////////////////////
//
// Price Slider
//
///////////////////////////////////////
// $include-html-range-slider-classes: $include-html-classes !default;
$include-html-range-slider-classes: true;
// $range-slider-margin: 20px 0;
// $range-slider-track-height: 6px;
// $range-slider-track-full-color: #ddd;
$range-slider-track-full-color: get-color(feta);
// $range-slider-track-included-color: #f00;
$range-slider-track-included-color: get-color(feta);
// $range-slider-thumb-diameter: 30px;
$range-slider-thumb-diameter: 40px;
// $range-slider-thumb-diameter-medium: $range-slider-thumb-diameter;
$range-slider-thumb-diameter-medium: 30px;
// $range-slider-thumb-diameter-large: $range-slider-thumb-diameter-medium;
$range-slider-thumb-diameter-large: 20px;
// $range-slider-thumb-color: #555;
$range-slider-thumb-color: get-color(nero);
// $range-slider-output-color: get-border-value($input-border, 'color');
$range-slider-output-color: $black;
// $range-slider-output-border: 1px solid $range-slider-output-color;
// $range-slider-output-border-radius: 4px;
// $range-slider-output-background: null;
$range-slider-output-background: $body-background;
// $range-slider-output-height: 2.5em;
// $range-slider-output-height-medium: $range-slider-output-height;
// $range-slider-output-height-large: $range-slider-output-height-medium;
// $range-slider-output-bottom-spacing: 1em;
$range-slider-output-bottom-spacing: 2em;
// $range-slider-output-bottom-spacing-medium: $range-slider-output-bottom-spacing;
$range-slider-output-bottom-spacing-medium: 1em;
// $range-slider-output-bottom-spacing-large: $range-slider-output-bottom-spacing-medium;
// $range-slider-output-zindex: null;
// $range-slider-thumb-margin-top: ($range-slider-thumb-diameter / -2 + $range-slider-track-height / 2); // used for centering verically
// $range-slider-thumb-margin-top-medium: ($range-slider-thumb-diameter-medium / -2 + $range-slider-track-height / 2); // used for centering verically
// $range-slider-thumb-margin-top-large: ($range-slider-thumb-diameter-large / -2 + $range-slider-track-height / 2); // used for centering verically





//---------------------------------------------------------------
//
//Account Landing Page Layout
//
//---------------------------------------------------------------
$include-html-account-landing-page-classes: true;
//$account_landing_tile_border_bottom: null;
//$account_landing_tile_border_bottom_hover: null;
//$account_landing_tile_border_top: null !default;
$account_landing_tile_border_top: 2px solid $white;
//$account_landing_tile_border_top_hover: null;
$account_landing_tile_border_top_hover: 2px solid get-color(primary);
$account_landing_tile_section_background_color: $backgroundColorLight;
//$account_landing_tile_font_family: null;
$account_landing_tile_font_family: $body-font-family;
//account_landing_tile_font_color: null;
$account_landing_tile_font_color: $medium-gray;
//$account_landing_icomoon_font_size: null;
$account_landing_icomoon_font_size: em-calc(30px);
$account_landing_tile_font_weight: bold;
//$account_landing_tile_line_height: 1;
//$account_landing_tile_border: none;
//$account_landing_tile_heading_font_color: null !default;
$account_landing_tile_heading_font_color: get-color(nero);
//$account_landing_tile_heading_font_family: null !default;
$account_landing_tile_heading_font_family: $header-font-family;
//$account_landing_tile_heading_font_size: null;
$account_landing_tile_heading_font_size: em-calc(20px);
//$account_landing_tile_subheading_font_size: null;
$account_landing_tile_subheading_font_size: em-calc(14px);

//---------------------------------------------------------------
//
// Address Lookup Address List
//
//---------------------------------------------------------------
// $include-html-address-lookup-address-list-classes: false;
// $selectAddressDialogBackground: null;
// $selectAddressListBorder: null;
// $selectAddressManualBackground: null;
// $selectAddressOrSeparatorFontFamily: null;
// $selectAddressManualFontFamily: null;

//---------------------------------------------------------------
//
// Accordion Menu
//
//---------------------------------------------------------------
// $accordionTertieryMenuMobileBackground: transparent;
// $accordionTertieryMenuMobileBorder: 1px solid;
// $accordionMenuBorderBase: 1px solid;
// $topbarCountryLogoSize: 25px;
// $header-search-top: auto;
// $topbar-upper-menu-border: 0;
// $topbar-menu-link-size: null;
// $topbar-menu-heading-link-size: null !default;
// $topbar-heading-font-family: inherit !default;
// $topbar-menu-link-size-desktop: null;
// $include-html-header-region-selector-classes: false;
// $titleAreaBorderBottom: 0;
// $topbarDropdownArrowDown: null;
// $topbarDropdownArrowUp: null;
// $topbarShoppingCartIcon: null;
// $topbarRegionSelectorIndicator: null;
// $topbarSearchIcon: null;
// $include-html-header-search-form-classes: false;
// $topbarSearchWidthMedium: auto;
// $topbarSearchWidthSmall: auto;
// $topbarSearchTopSmall: auto;
// $topbarMainItemHoverBG: null;
// $topbarMainItemHoverColor: null;
// $topbarCartIconHoverColor: inherit;
// $searchButtonColorSmall: inherit;
//
//

//
// Side by Side Layout for Payment Section on Billing Page
// This WCS component that changes the layout of the payment section to a side by side layout
// The Credit Card images drop below the radio buttons and radio buttons are aligned side by side
//
// This layout is enabled via commerce VFStoreconfig property below
// CHECKOUT_BILLING_SIDE_BY_SIDE_LAYOUT_ENABLED = true
//
//

// //This variable must be enabled on brand for module to be activated
// $include-html-side-by-side-payment-layout-classes: false !default;
$include-html-side-by-side-payment-layout-classes: true;
$include-html-side-by-side-payment-layout-paypal-classes: true;

$side_by_side_payment_section_credit_card_image_container_margin_left_small: 40px;

// //PayPal Tooltip
$side_by_side_payment_section_paypal_tooltip_container_margin_top: 5px;

// //PayPal Content
$side_by_side_payment_section_paypal_form_section_help_padding_small: 0;

//PayPal Section
$side_by_side_payment_section_paypal_section_margin_bottom: 5px;
$paypal_checkout_cof_form_section_details_margin_top_small: 30px;

// //Paypal Radio Graphic
$paypal_checkout_cof_form_section_details_margin: 0;
// END SIDE BY SIDE LAYOUT

//---------------------------------------------------------------
// Returns page
//---------------------------------------------------------------
$include-html-returns-page-classes: true;
//$returns-page-order-detail-info-border: null !default;
$returns-page-order-detail-info-border: get-color(twilight-blue);
$returns-page-back-to-items-action-color: get-color(nero);
$returns-page-back-to-items-action-padding-left: 10px;
//$returns-page-date-picker-tiles-background: null !default;
$returns-page-date-picker-tiles-background: get-color(twilight-blue);
//$returns-page-date-picker-date-tile-background: null !default;
$returns-page-date-picker-date-tile-background: $white;
//$returns-page-date-picker-date-tile-border: null !default;
$returns-page-date-picker-date-tile-border: get-color(twilight-blue);
//$returns-page-date-picker-date-tile-disabled-background: null !default;
$returns-page-date-picker-date-tile-disabled-background: get-color(feta);
//$returns-page-date-picker-date-tile-selected-background: null !default;
$returns-page-date-picker-date-tile-selected-background: $white;
//$returns-page-date-picker-date-tile-selected-border: null !default;
$returns-page-date-picker-date-tile-selected-border: get-color(nero);
//$returns-page-date-picker-date-tile-selected-color: null !default;
$returns-page-date-picker-date-tile-selected-color: get-color(nero);
//$returns-page-border: null !default;
$returns-page-border: get-color(twilight-blue);
//$returns-page-vertical-small-space: null !default;
$returns-page-vertical-small-space: $vertSpaceSmall;
//$returns-page-vertical-medium-space:null !default;
//$returns-page-create-return-instructions-background-color: null !default;
// $returns-page-order-item-color: null !default;
$returns-page-order-item-color: $black;
//$returns-page-product-header-color: null !default;
$returns-page-product-header-color: $black;

// Link Order to Account on Order Confirmation Page
// This WCS component that displays a message if the user entered an email address during the checkout process
// that matched a registered account already in the system.
// It gives the user a Call To Action (CTA) to link the order they just placed to their account.
//
// This feature is enabled via commerce VFStoreconfig property below
// CHECKOUT_ADD_ORDER_TO_AN_ACCOUNT_ENABLED = true
//
//This variable must be enabled on brand for module to be activated
// $include-html-link-order-to-account-classes: false !default;
$include-html-link-order-to-account-classes: true;
//
//Link Order to Account Message
// $link_order_to_account_container_background_color: #fff5b2 !default;
// $link_order_to_account_container_padding: 20px 15px 5px !default;
// $link_order_to_account_text_statement_font_size: em-calc(17px) !default;
// $link_order_to_account_text_statement_margin: 0 !default;
// $link_order_to_account_text_statement_text_transform: none !default;
// $link_order_to_account_text_question_font_size: em-calc(17px) !default;
// $link_order_to_account_text_question_font_weight: bolder !default;
// $link_order_to_account_text_question_margin: 5px 0 10px !default;
// $link_order_to_account_text_question_text_transform: none !default;
// $link_order_to_account_login_button_font_weight: normal !default;

// //Link Order to Account Modal
// $link_order_to_account_modal_title_font_family: $header-font-family !default;
// $link_order_to_account_modal_title_font_size: em-calc(17px) !default;
// $link_order_to_account_modal_title_font_weight: bold !default;
// $link_order_to_account_modal_title_margin_bottom: $column-gutter !default;

// //Modal Size Mobile
// $link_order_to_account_modal_width: 80% !default;
// $link_order_to_account_modal_margin_left:  -40% !default;

// //Modal Size Tablet
// $link_order_to_account_modal_width_small: 65% !default;
// $link_order_to_account_modal_margin_left_small:  -30% !default;

// //Modal Size Desktop
// $link_order_to_account_modal_width_medium:  50% !default;
// $link_order_to_account_modal_margin_left_medium:  -25% !default;

// //Modal Size Large
// $link_order_to_account_modal_width_large:  30% !default;
// $link_order_to_account_modal_margin_left_large:  -15% !default;

// //Email
// $link_order_to_account_modal_email_font_weight: bold !default;
// $link_order_to_account_modal_email_margin_bottom: 1px !default;

// //Forgot Password Container
// $link_order_to_account_modal_forgot_password_container_clear: both !default;

// //Error Message
// $link_order_to_account_modal_error_message_float: left !default;
// $link_order_to_account_modal_error_message_margin_bottom: 10px !default;

// //Password Input Container
// $link_order_to_account_modal_password_container_margin_bottom: 0 !default;

// //Password Input
// $link_order_to_account_modal_password_input_display: inline-block !default;
// $link_order_to_account_modal_password_input_float: left !default;
// $link_order_to_account_modal_password_input_margin_bottom: 5px !default;
// $link_order_to_account_modal_password_input_padding_left: 5px !default;
// $link_order_to_account_modal_password_input_width: 100% !default;
// //tablet and up
// $link_order_to_account_modal_password_input_width_small: 70% !default;
// //error message
// $link_order_to_account_modal_password_input_error_message_clear: both !default;
// $link_order_to_account_modal_password_input_error_message_float: left !default;
// //submit container
// $link_order_to_account_modal_password_submit_container_display: inline-block !default;
// $link_order_to_account_modal_password_submit_container_margin_bottom: 0 !default;
// $link_order_to_account_modal_password_submit_container_margin_top: 0 !default;
// $link_order_to_account_modal_password_submit_container_width: 100% !default;
// $link_order_to_account_modal_password_submit_container_width_small: 30% !default;
// //Submit Button Container
// $link_order_to_account_modal_password_submit_button_container_float: none !default;
// $link_order_to_account_modal_password_submit_button_container_margin_bottom: 10px !default;
// $link_order_to_account_modal_password_submit_button_container_padding: 0 !default;
// $link_order_to_account_modal_password_submit_button_container_margin_small: 0 !default;
// //Submit Button
// $link_order_to_account_modal_password_submit_button_font_weight: normal !default;
// $link_order_to_account_modal_password_submit_button_padding: 10px 25px 10px !default;
// $link_order_to_account_modal_password_submit_button_width: 100% !default;

// //Forgot Password Modal
// $forgot_password_modal_width: 80% !default;
// $forgot_password_modal_width_small: 30% !default;
// $forgot_password_modal_margin_left: -40% !default;
// $forgot_password_modal_margin_left_small: -15% !default;
// //Forgot Password Email Display
// $forgot_password_modal_email_font_weight: bold !default;
// $forgot_password_modal_email_margin_bottom: 1px !default;
// //Password Sent Container
// $forgot_password_modal_reset_password_container_width: 100% !default;
// $forgot_password_modal_reset_password_container_width_medium: inherit !default;
// //Password sent successfully text
// $forgot_password_modal_password_sent_container_padding: 0 !default;
// $forgot_password_modal_password_sent_text_font_weight: bold !default;
// $forgot_password_modal_password_sent_text_font_size: em-calc(14px) !default;
// $forgot_password_modal_password_sent_text_line_height: 1.5em !default;
// END OF LINK ORDER TO ACCOUNT MODULE


//Collection Point Results
$collection-point-list-border-top: get-color(feta);
$collection-point-field-set-border: 0;
$collection-point-accordion-open: $icon-minus;
$collection-point-accordion-closed: $icon-plus;
$selected-collection-point-border: 1px solid get-color(nero);
$selected-collection-point-map-border: 2px solid get-color(nero);
$collection-point-results-tooltip-right-large: 25px;
// Power reviews loading indicator
$power-reviews-loading-indicator-color: get-color(primary);

//Required Field Character Color
$formRequiredCharacterColor: get-color(primary);

$find-store-additional-stores-button-icon: $icon-plus !default;
$find-store-additional-stores-button-icon-expanded: $icon-minus !default;
$find-store-button-margin-left: auto;
$in-store-avail-additional-stores-button-color: $black;
$in-store-avail-additional-stores-button-padding: 2% 0% 16% 37%;
$foundStoreHeaders: (
  'font': (
    'font-size': em-calc(16px),
    'font-family': $header-font-family,
    'text-transform': uppercase,
    'color': $header-color
  )
);
$foundStoreNameFontStyle: (
  'font': (
    'font-size': em-calc(18px),
    'font-family': $header-font-family,
    'text-transform': uppercase,
    'color': $header-color,
    'font-weight': bold
  )
);
$foundStoreStatusFontStyleOverride: (
  'font': (
    'text-transform': initial,
    'color': $header-color
  )
);

$foundStoreStatusFontStyle: recursive-map-merge($foundStoreNameFontStyle, $foundStoreStatusFontStyleOverride);



//---------------------------------------------------------------
// favorites Page
//---------------------------------------------------------------
$include-html-favorites-classes: true;
//favorites header
// $favorites_headerWidthColumns: 12 !default;

// //favorites header action buttons
$favorites_headerActionButtonBackgroundColor: transparent;
$favorites_headerActionButtonColor: get-color(eclipse-3);
$favorites_headerActionButtonBorder: 4px solid $favorites_headerActionButtonColor;
$favorites_headerActionButtonFontWeight: bold;
// $favorites_headerActionButtonMarginLeft: $column-gutter !default;
$favorites_headerActionButtonColorHover: get-color(secondary);

// //product blocks on tablet set to 3
// $favorites_productColumnsInBlockTablet: 4 !default;

// //product blocks on desktop set to 4
// $favorites_productColumnsInBlock: 3 !default;
// $favorites_iconFontSize: em-calc(20) !default;
// make this value to right to position icon to right of product block.
$favorites_iconAlignment: right;
$favorites_iconBottomPosition: auto;
// $favorites_iconHorizontalPosition: 0 !default;


//---------------------------------------------------------------
// My Account - Order Detail
//---------------------------------------------------------------
// $orderSummaryColor: $fontGreyDark !default;
$orderSummaryColor: $fontDark;

// =============================================================
// Right Mini Cart
//---------------------------------------------------------------
//$globalCartWishlistPanelMobilePosition: absolute !default;
//$globalCartWishlistPanelMobileZIndex: 202 !default;

///////////////////////////////////////////////////
// Auto suggest
//file location : _shared/sass/widgets/_autocomplete.scss
///////////////////////////////////////////////////
$search-autosuggest-container-background: rgba($black, 0.85);
$search-autosuggest-text-color: $white;
$search-autosuggest-suggestions-title-color: get-color(suva-grey);
$search-autosuggest-item-anchor-color: get-color(suva-grey);
$search-autosuggest-item-anchor-color-hover: get-color(suva-grey);
$search-autosuggest-border-bottom: 1px dotted get-color(dim-gray);
$search-autosuggest-suggestions-list-item-color: $white;
$search-autosuggest-featured-results-title: $white;
$search-autosuggest-container-position-left-medium: -171px;
$search-autosuggest-featured-results-item-title-margin-right: rem-calc(5);
$search-autosuggest-featured-results-item-img-height: auto;


// End of Auto suggest

//----------------------------------------------------
// BOPIS Shopping Cart and Order Process Components
//
// Formats list items colors
//----------------------------------------------------
//$bopis-pickup-details-li-color: #000 !default;
$bopis-pickup-details-li-color: get-color(primary);
$bopis-method-selection-label-font-size: 13px;
$bopis-ship-pickup-description-display: inline;
$bopis-ship-pickup-description-margin-left: 0;
$bopis-ship-pickup-input-margin-bottom: 0;
$bopis-store-selection-modal-link-text-decoration: none;
$bopis-store-selection-border-top: $borderDotted;
$bopis-store-selection-modal-link-text-color: get-color(picton-blue);
$checkout-summary-item-detail-delivery-pickup-font-size: 13px;
//----------------------------------------------------
// BOPIS Your Store Components
//
// Formats spans with the bopis-your-store class
//----------------------------------------------------
// $bopis-your-store-color: #000 !default;
$bopis-your-store-color: get-color(primary);

///////////////////////////////////////////////////
// In-store-availability Auto Complete
///////////////////////////////////////////////////

$include-in-store-availability-autocomplete-classes: true;
$in-store-availability-autocomplete-item-font-family: $mediumFontFamily;
$in-store-availability-autocomplete-container-background: $white;
$in-store-availability-autocomplete-text-color: $medium-gray;
$in-store-availability-autocomplete-item-anchor-color: $medium-gray;
$in-store-availability-autocomplete-item-anchor-color-hover: $medium-gray;
$in-store-availability-autocomplete-border-bottom: 1px solid get-color(dim-gray);
$in-store-availability-autocomplete-list-item-padding-top: 10px;
$in-store-availability-autocomplete-list-item-padding-bottom: 10px;
$in-store-availability-autocomplete-list-item-color: brandColorPalette1;
$in-store-availability-autocomplete-container-position-top-for-small: 158px !default;
$in-store-availability-autocomplete-container-position-top: 275px !default;
$in-store-availability-autocomplete-list-item-margin-left: 25% !default;
$in-store-availability-autocomplete-container-width: 200px !default;
$in-store-availability-autocomplete-container-width: 100% !default;
$in-store-availability-autocomplete-container-width-mobile: 100% !default;

//PRINT VARIABLES
//
$printBrandStoreLogoBackgroundURL: $store-logo;
$printCheckoutItemQuantityWidth: 6.6667%;
$printCheckoutBopisPickupDetailsPaddingLeft: 0;
$printCheckoutBopisPickupDetailsPaddingRight: 0;
$printCheckoutOrderShippingBillingContainerPosition: static;
$printCheckoutContentPaddingRight: 0;
$printCheckoutBopisPickupDetailsInlineListDisplay: flex;
$printCheckoutOrderConfirmationShippingInfoWidth: auto;
//END of Print Variables

//Header Simple
$headerSimpleLogo: $store-logo no-repeat center;
$headerSimpleLogoHeight: 95px;
$headerSimpleLogoWidth: 131px;

//Footer Simple
$footerSimpleLogoWidth: 78px;


////////////////////////////////////////////////
// VFDPS7Viewer
////////////////////////////////////////////////
//$vfdp-s7-viewer-image-proportion: 1, 1; // Common ratios are 1, 1 | 16, 9 | 9, 16 | 3, 4 | 4, 3
//// This is the space available for the swatches.
//// The swatches can be positioned within this area with store config properties
//$vfdp-s7-viewer-icon-font-family: 's7-viewer';
//$vfdp-s7-viewer-swatch-container-size: 0; // Swatch positioning and spacing is set with JS in store config
//$vfdp-s7-viewer-swatch-container-size-medium: 0; // Container size will be auto set with a 10px spacing
//$vfdp-s7-viewer-swatch-container-size-large: 0; // Container size will be auto set with a 10px spacing
//$vfdp-s7-viewer-preload-split-padding: true; // Split the padding around the hero during initial laod, true=shift to fit, false=shrink to fit
//$vfdp-s7-viewer-spacing-between-swatches-and-viewer: 0;
//$vfdp-s7-viewer-zoom-button-size: 2em; // These are square, only one size needed.
//$vfdp-s7-viewer-zoom-button-margin: null;
//$vfdp-s7-viewer-zoom-button-padding: null; // Use ems for proper spacing and centering
//$vfdp-s7-viewer-zoom-button-color: $body-font-color;
//$vfdp-s7-viewer-zoom-button-color-reversed: #fff;
//$vfdp-s7-viewer-zoom-button-color-hover: null;
//$vfdp-s7-viewer-zoom-button-zoom-in-content: $s7-viewer-icon-add;
//$vfdp-s7-viewer-zoom-button-zoom-out-content: $s7-viewer-icon-minus;
//$vfdp-s7-viewer-zoom-button-zoom-reset-content: $s7-viewer-icon-refresh;
//$vfdp-s7-viewer-zoom-button-enter-fullscreen-content: $s7-viewer-icon-fullscreen-enter;
//$vfdp-s7-viewer-zoom-button-exit-fullscreen-content: $s7-viewer-icon-fullscreen-exit;
//$vfdp-s7-viewer-video-play-button: $s7-viewer-icon-play-arrow;
//$vfdp-s7-viewer-video-pause-button: $s7-viewer-icon-pause;
//$vfdp-s7-viewer-video-mute-button: $s7-viewer-icon-volume-off;
//$vfdp-s7-viewer-video-unmute-button: $s7-viewer-icon-volume-up;
//$vfdp-s7-viewer-video-closed-caption-button: $s7-viewer-icon-closed-caption;
//$vfdp-s7-viewer-spinview-button: $s7-viewer-icon-3d_rotation;
//$vfdp-s7-viewer-spin-right-content: $s7-viewer-icon-rotate_right;
//$vfdp-s7-viewer-spin-left-content: $s7-viewer-icon-rotate_left;
//$vfdp-s7-viewer-thumb-width: 50px;
$vfdp-s7-viewer-thumb-width: 64px;
//$vfdp-s7-viewer-thumb-height: $vfdp-s7-viewer-thumb-width; // Default to square
//$vfdp-s7-viewer-thumb-width-medium: $vfdp-s7-viewer-thumb-width; // Default to the smaller size
//$vfdp-s7-viewer-thumb-height-medium: $vfdp-s7-viewer-thumb-width-medium; // Default to square
//$vfdp-s7-viewer-thumb-width-large: $vfdp-s7-viewer-thumb-width-medium; // Default to the smaller size
//$vfdp-s7-viewer-thumb-height-large: $vfdp-s7-viewer-thumb-width-large; // Default to square
//$vfdp-s7-viewer-thumb-margin: null;
$vfdp-s7-viewer-thumb-margin: 5px 16px 5px 5px;
//$vfdp-s7-viewer-thumb-default-border: 0; // default to 0 to not show a border
$vfdp-s7-viewer-thumb-default-border: 2px solid transparent;
//$vfdp-s7-viewer-thumb-over-border: 0;
$vfdp-s7-viewer-thumb-over-border: 2px solid get-color(primary);
//$vfdp-s7-viewer-thumb-selected-border: 0;
$vfdp-s7-viewer-thumb-selected-border: 2px solid get-color(primary);
//$vfdp-s7-viewer-thumb-overlay-color: get-color(primary);
//$vfdp-s7-viewer-thumb-overlay-color-reversed: get-color(secondary);
//$vfdp-s7-viewer-thumb-overlay-size: null;
//$vfdp-s7-viewer-thumb-overlay-container-size: 1.2em;
//$vfdp-s7-viewer-swatch-scroll-button-size: 2em;
//$vfdp-s7-viewer-swatch-scroll-button-color: null;
//$vfdp-s7-viewer-swatch-scroll-button-color-hover: null;
//$vfdp-s7-viewer-swatch-scroll-button-color-disabled: null;
//$vfdp-s7-viewer-swatch-scroll-button-left-content: $s7-viewer-icon-indicator-left;
//$vfdp-s7-viewer-swatch-scroll-button-right-content: $s7-viewer-icon-indicator-right;
//$vfdp-s7-viewer-swatch-scroll-button-up-content: $s7-viewer-icon-indicator-up;
//$vfdp-s7-viewer-swatch-scroll-button-down-content: $s7-viewer-icon-indicator-down;
//$vfdp-s7-viewer-fullscreen-background: #fff;
//$vfdp-s7-viewer-promo-badge-x: 'right', 0;
//$vfdp-s7-viewer-promo-badge-y: 'top', 0;
//$vfdp-s7-viewer-videoplayer-background: transparent; // Needed to remove S7 default black
//$vfdp-s7-viewer-controlbar-background: transparent; // Needed to remove S7 default grey
//$vfdp-s7-viewer-controlbar-zoomview-background: $vfdp-s7-viewer-controlbar-background;
//$vfdp-s7-viewer-controlbar-flyout-background: $vfdp-s7-viewer-controlbar-background;
//$vfdp-s7-viewer-controlbar-videoplayer-background: $vfdp-s7-viewer-controlbar-background;
//$vfdp-s7-viewer-s7zoomview-s7iconeffect-standard: null;
$vfdp-s7-viewer-s7zoomview-s7iconeffect-standard: none;
//$vfdp-s7-viewer-s7zoomview-s7iconeffect-multitouch: null;
$vfdp-s7-viewer-s7zoomview-s7iconeffect-multitouch: none;
//$vfdp-s7-viewer-s7videoplayer-s7iconeffect-play: null;
//$vfdp-s7-viewer-s7videoplayer-s7iconeffect-replay: null;
//$vfdp-s7-viewer-s7spinview-s7iconeffect-1d: null;
//$vfdp-s7-viewer-s7spinview-s7iconeffect-2d: null;
//$vfdp-s7-viewer-hero-image-container-border: null;
$vfdp-s7-viewer-hero-adjust-height: false;
$swiper-viewer-swatch-cta-container-bottom: rem-calc(55);
$mol-media-gallery-image-wrapper-height: auto;
$mol-media-gallery-image-wrapper-img-height: auto;
$mol-media-gallery-image-wrapper-img-left: 0;
//BUY NOW CHECKOUT
$checkout_buy_now_button_width: 194px;

//APPLE PAY MINI CART
$applePayMiniCartApplePayButtonMaxWidth: 147px;
$applePayMiniCartPaypalButtonMaxWidth: 147px;
$applePayMiniCartButtonContainerPadding: 0 50px;
$applePayMiniCartOrTextMargin: 0;
$applePayMiniCartPaypalButtonMarginBottom: 10px;
//END APPLE PAY MINI CART
//APPLE PAY SIDE BY SIDE LAYOUT
$include-html-side-by-side-payment-layout-applepay-classes: true;
$shopcart_applepay_paypal_button_container_width: 100%;
$shopcart_applepay_button_float: left;
$shopcart_applepay_button_max_width: 194px;
$shopcart_applepay_button_padding_small: 18px;
$shopcart_applepay_button_padding: 30px;
$shopcart_applepay_button_padding_medium: 22.5px;
$shopcart_applepay_button_width: 100%;
$shopcart_applepay_button_margin: 0 auto;
$shopcart_applepay_button_margin_small: 0 auto;
$shopcart_applepay_button_margin_medium: 0 auto;
$shopcart_applepay_button_container_padding_small: 0 30px;
$shopcart_applepay_button_container_padding_medium: 0 40px;

//Billing
$billing_applepay_logo_bg_color: $white;
$billing_applepay_logo_color: $black;
$billing_applepay_logo_font_size: 15px;
$billing_applepay_cart_total_button_width: 100%;
$billing_applepay_cart_total_button_container_padding: 0;
$billing_applepay_cart_total_button_margin: 0 0 10px 0;
$billing_applepay_cart_total_button_max_width: 500px;
$billing_applepay_cart_total_button_display: inline-block;
$billing_applepay_cart_total_bottom_button_display: inline-block;
$billing_applepay_content_section_margin_left: 0;

//END APPLE PAY SIDE BY SIDE LAYOUT

//PDP Product Info
$productTitleH1Style: (
  'font': (
    'font-size': 2.5em,
    'letter-spacing': 0.05em,
    'text-transform': uppercase,
    'line-height': normal
  )
);

//Quick view Modal Title
$quickModalTitleH1Style: (
  'font': (
    'font-family': get-font('franklin-gothic'),
    'font-size': rem-calc(20),
    'font-weight': bold,
    'height': rem-calc(48),
    'line-height': rem-calc(24),
    'letter-spacing': rem-calc(0.1),
    'text-transform': uppercase,
    'width': rem-calc(255)
  )
);

//Switch Foundation Variables
$switch-background: get-color(very-light-grey);
$switch-background-active: rgba(get-color(primary), 0.5);
$switch-height: 1rem;
$switch-radius: 15px;
$switch-paddle-radius: 15px;
$switch-paddle-offset: -0.25rem;

//Vans Switch Component Variables
$vans-switch-width: 2.5rem;
$vans-switch-paddle-background-active: get-color(primary);
$vans-switch-paddle-height: 1.5rem;
$vans-switch-paddle-width: 1.5rem;
$vans-switch-paddle-left-active: 1.25rem;
$vans-switch-box-shadow: 0 0.25rem 0.5rem rgba($black, 0.28);

//Invoice Billing
$billingInvoiceRadioBtnContainerMargin: 15%;
$billingInvoiceCancelBtnWidthSmall: 39%;
$billingInvoiceCancelBtnWidthLarge: 14%;
$billingInvoiceCancelBtnMarginLarge: 14px;

//Gift Card My Account Variables
$giftCardMyAccountFontFamily: get-font('franklin-gothic');
$giftCardFooterColor: $white;
$giftCardSectionBackgoundColor: $white;
$giftCardTransactionSeperatorColor: 1px solid get-color(light-grey);
$transactionCardBackgroundColor: $white;
$giftCardNumberSeparator: 1px solid get-color(light-grey);
$giftCardDetailsNumberFont: $body-font-family;
$giftCardNumberErrorMargin: 0 0 2% 5%;
$giftCardInputMargin: 5%;
$giftCardInputWidth: 90%;
$giftCardSubmitMarginLarge: 5%;
$giftCardBalanceInputMargin: $giftCardInputMargin;
$giftCardBalanceInputWidth: $giftCardInputWidth;
$giftCardBalanceInputWidthLarge: 50%;
$giftCardSectionBorderColor: $black;
$giftCardSectionBorderWidth: 2px 0 0 0;
$giftCardBalanceSectionBackgoundColor: $white;
$giftCardSectionRecaptchaMargin: 5%;
$giftCardSectionRecaptchaMarginLarge: 0 5% 5% 5%;
$giftCardSectionErrorMargin: 5%;
$giftCardSectionErrorMarginLarge: 0 5% 10px 5%;

//Gift Card PDP variables
$giftCardTeaserBlockMarginSmall: 15px;
$giftCardTeaserBlockMarginMedium: 30px;
$giftCardHeadingCase: uppercase;
$giftCardSubHeadingCase: uppercase;
$giftCardSelectedSwatchBorder: 2px solid get-color(primary);
$giftCardAmountInputFontWeight: 900;
$giftCardTitleColor: get-color(secondary);
$giftCardSwatchesBorder: 1px solid get-color(secondary);
$giftCardTeaserTitleSeparator: block;
$giftCardTeaserTitleFontWeight: bold;
$giftCardpdpHeaderPadding: 15px;
$giftCardFontFamily: get-font('franklin-gothic');
$giftCardLetterSpacing: 0.07em;
$giftCardSubHeadingLetterSpacing: 0.05em;
$giftCardDetailsFontSize: em-calc(16);
$giftCardSectionTitleFontSize: em-calc(16);

//////////////////////////////////////
// Global Footer
//////////////////////////////////////
$footerBackground: get-color(nero);
$footerColor: get-color(submarine);
$footerMargin: $vertSpace 0 0 0;
$footerPadding: $vertSpaceLarge 0;
$footerSectionPadding: 0 0 30px 0;
$footerUtilSectionPadding: 20px 0 0;

$footerHeadingColor: $white;
$footerHeadingFont: get-font('franklin-gothic');
$footerHeadingPadding: 0 0 20px 0;
$footerHeadingPaddingMedium: 0 0 column-gutter()/2 0;
$footerHeadingSize: rem-calc(22px);
$footerSubheadingColor: $white;
$footerSubheadingSize: rem-calc(12px);
$footerSubheadingWeight: 700;
$footerSubheadingPadding: 10px column-gutter()/2 4px;
$footerSubheadingPaddingMedium: 0 0 4px 0;

$footerAccordionItemBorder: 1px solid get-color(night-rider);
$footerAccordionItemPadding: 8px column-gutter()/2;
$footerAccordionItemPaddingMedium: $footerSubheadingPaddingMedium;
$footerAccordionIcon: $icon-arrow-up-indicator;
$footerAccordionIconClosed: $icon-arrow-down-indicator;
$footerAccordionIconSize: 1em;

$footerNavItemPadding: 0 column-gutter()/2 4px;
$footerNavItemPaddingMedium: $footerSubheadingPaddingMedium;
$footerNavItemSize: em-calc(12px);
$footerNavListMargin: 0 0 1rem 0;

$footerLogoBgPosition: 0 center;
$footerLogoMargin: 5px;
$footerLogoPath: $store-logo-white;
$footerLogoWidth: 132px;
$footerLogoHeight: 50px;

$footerSocialIconBackground: get-color(eclipse);
$footerSocialIconMargin: 0 0 4px;
$footerSocialMarginBottom: 20px;

$footerRegionFontSize: rem-calc(12px);
$footerRegionMargin: 1em auto;
$footerRegionMarginMedium: 1em 5px 0 auto;
$footerRegionWidth: 75%;
$footerRegionFlagMargin: 0 0 0 10px;
$footerRegionSelectedBackground: get-color(eclipse);
$footerRegionSelectedColor: $white;
$footerRegionSelectedFlagMargin: 0 0 0 9px;
$footerRegionSelectedIconMargin: 0 10px 0 4px;
$footerRegionSelectedNamePadding: 9px 1px 10px 0;
$footerRegionSelectedPadding: 0 15px;
$footerRegionItemPadding: 0.5em 81px 0.5em 0.1em;
$footerRegionItemColor: get-color(secondary);
$footerRegionItemFontSize: em-calc(14px);
$footerRegionItemHoverBg: get-color(whisper);

$footerEmailSignupLegalTextColor: get-color(suva-grey);
$footerEmailSignupLegalErrorColor: get-color(suva-grey);

//Shop the Look Modal (PDP/AEM)
$stlModalBackground: '#F1F2ED';
$stlModalAddToCartBtnBkgdColor: get-color(primary);
$stlModalAddToCartBtnColor: $white;
$stlModalAddToCartBtnDisabledBkgdColor: get-color(suva-grey);
$stlModalAddToCartBtnDisabledColor: $white;
$stlModalCloseButtonColor: '#707070';
$stlModalControlsFlexDirection: column;
$stlModalControlsMargin: rem-calc(5) 0 0 0 !default;
$stlModalControlsSelectDisabledColor: null;
$stlModalControlsSelectDisabledBackground: transparent;
$stlModalControlsSelectDisabledTextTransform: capitalize;
$stlModalControlsSelectDisabledTextAlign: center;
$stlModalControlsSelectDisabledBorder: none;
$stlModalControlsSelectDisabledPadding: 0;
$stlModalControlsSelectMargin: 0 0 rem-calc(10) 0;
$stlModalControlsSelectMaxWidth: 100%;
$stlModalControlsSelectTextAlign: left;
$stlModalControlsSelectOptionDisabledDisplay: none;
$stlModalControlsSelectOptionSelectedBackground: get-color(very-light-grey);
$stlModalHeadingIconDisplay: none;
$stlModalMainContainerAddToCartBtnContainerMargin: 0;
$stlModalMainContainerAddToCartBtnContainerPadding: rem-calc(10);
$stlModalMainContainerAddToCartBtnContainerWidth: 100%;
$stlModalMainContainerAddToCartBtnContainerWidthMedium: auto;
$stlModalProductsJustifyContent: left;
$stlModalProductsMargin: rem-calc(15) rem-calc(5);
$stlModalProductsMarginMedium: rem-calc(10) rem-calc(25) rem-calc(40) rem-calc(25);
$stlModalProductsMarginXLarge: rem-calc(10) rem-calc(25) rem-calc(40) rem-calc(25);
$stlModalProductsOriginalPriceColor: get-color(secondary);
$stlModalProductsOriginalPriceFontSize: rem-calc(14);
$stlModalProductsPadding: rem-calc(5);
$stlModalProductsPaddingMedium: rem-calc(15);
$stlModalProductsPaddingXLarge: rem-calc(15);
$stlModalProductsPriceAlignItems: center;
$stlModalProductsPriceFontSize: rem-calc(15);
$stlModalProductsPriceFontWeight: 600;
$stlModalProductsPriceMargin: rem-calc(8) 0 0 0;
$stlModalProductsPriceColor: $black;
$stlModalProductsPromoTextColor: get-color(secondary);
$stlModalProductsPromoTextFontWeight: bold;
$stlModalProductsSalePriceBkgdColor: transparent;
$stlModalProductsSalePriceBorderRadius: rem-calc(11);
$stlModalProductsSalePriceColor: get-color(primary);
$stlModalProductsSalePriceFontSize: rem-calc(14);
$stlModalProductsSalePriceFontWeight: 600;
$stlModalProductsSalePriceLineHeight: rem-calc(18);
$stlModalProductsSalePricePadding: 0;
$stlModalProductsTitleColor: $black;
$stlModalProductsTitleFontFamily: get-font('franklin-gothic');
$stlModalProductsTitleFontSize: rem-calc(13);
$stlModalProductsTitleFontWeight: normal;
$stlModalProductsTitleLineHeight: rem-calc(19);
$stlModalProductsTitleTextDecoration: none;
$stlModalProductsTitleTextTransform: uppercase;
$stlModalProductsWidth: calc(50% - #{$stlModalProductsPadding*2}) !default;
$stlModalProductsWidthLarge: calc(50% - #{$stlModalProductsPaddingMedium*2});
$stlModalProductsWidthMedium: calc(50% - #{$stlModalProductsPaddingMedium*2});
$stlModalProductsWidthXLarge: calc(50% - #{$stlModalProductsPaddingXLarge*2});
$stlModalShopTheLookControlsDisplay: flex;
$stlModalShopTheLookControlsFlexDirection: column;
$stlModalTopContainerDisplay: block;
$stlModalTopContainerHeight: auto;
$stlModalTopContainerH2Color: $black;
$stlModalTopContainerH2FontSize: rem-calc(40);
$stlModalTopContainerH2FontSizeMedium: rem-calc(40);
$stlModalTopContainerH3Color: $black;
$stlModalTopContainerH3FontSize: rem-calc(40);
$stlModalTopContainerH3FontWeight: normal;
$stlModalTopContainerH3LineHeight: rem-calc(40);
$stlModalTopContainerH3FontSizeMedium: rem-calc(40);
$stlModalTopContainerH2FontWeight: bold;
$stlModalTopContainerH3MarginMedium: 0 0 rem-calc(-3) 0;
$stlModalTopContainerPadding: 0;
$stlModalTopContainerPicturePosition: static;
$stlModalTopContainerPictureHeight: rem-calc(350);
$stlModalTopContainerContentsMargin: rem-calc(22) rem-calc(17);
$stlModalTopSubtotalContainerDisplay: none;
$stlModalWidth: 96%;
$stlModalMargin: rem-calc(10) auto rem-calc(150) auto;
$stlModalWidthDesktop: 75%;
$stlModalMarginDesktop: 0 auto;
$stlModalViewMoreLooksLinkPadding: 0 rem-calc(10);
$stlModalViewMoreLooksLinkBorder: rem-calc(1) solid get-color(suva-grey);
$stlModalViewMoreLooksLinkTextAlign: center;
$stlModalViewMoreLooksLinkColor: $black;
$stlModalViewMoreLooksLinkFontFamily: get-font('franklin-gothic');
$stlModalMobileCloseLinkMargin: rem-calc(80) rem-calc(10) rem-calc(10) rem-calc(10);
$stlModalMobileCloseLinkBackgroundColor: $black;
$stlModalMobileCloseLinkColor: $white;
$stlModalMobileCloseLinkTextAlign: center;
$stlModalMobileCloseLinkFontFamily: get-font('franklin-gothic');
$stlModalViewMoreLooksLinkAnchorPadding: rem-calc(16);
$stlModalMobileCloseLinkAnchorPadding: rem-calc(16);
$stlModalMobileCloseLinkDisplay: block;
$stlModalViewMoreLooksLinkDisplay: block;
$stlModalViewMoreLooksLinkTextTransform: uppercase;
$stlModalMobileCloseLinkTextTransform: uppercase;
$stlModalMobileCloseLinkWidth: 100%;
$stlModalViewMoreLooksLinkWidth: 100%;
$stlModalMobileCloseLinkPadding: 0 rem-calc(10);

$stlModalMessageColor: $black;
$stlModalMessageTextAlign: center;
$stlModalMessageTextTransform: uppercase;
$stlModalMessageFontFamily: get-font('franklin-gothic');
$stlModalMessageFontSize: rem-calc(11);
$stlModalMessageFontWeight: 600;

$stlModalSubtotalFontFamily: get-font('franklin-gothic');
$stlModalSubtotalFontSize: rem-calc(18);
$stlModalSubtotalAmountFontSize: rem-calc(18);
$stlModalSubtotalLineHeight: rem-calc(23);
$stlModalSubtotalTextTransform: uppercase;
$stlModalSubtotalDisplay: none;
$stlModalSubtotalProductSelectedDisplay: flex;
$stlModalSubtotalProductSelectedDisplayLarge: block;

//SHOP THE LOOK - Modal Desktop
$stlModalTopContainerBackgroundImageDisplayMedium: none;
$stlModalTopButtonContainerDisplayMedium: none;
$stlModalTopSubtotalContainerDisplayMedium: none;
$stlModalMessageColorDisplayMedium: none;
$stlProductsListScrollable: true;
$stlModalTopContainerPaddingMedium: rem-calc(30) 0;
$stlModalTopContainerPaddingLarge: $stlModalTopContainerPaddingMedium;
$stlModalTopContainerPaddingXLarge: $stlModalTopContainerPaddingMedium;
$stlModalMainContainerPaddingMedium: 0;
$stlModalProductsPaddingXLarge: 0;
$stlModalProductsPaddingMedium: rem-calc(5);
$stlModalProductsContPaddingMedium: 0 rem-calc(15);
$stlModalProductsPaddingXLarge: $stlModalProductsContPaddingMedium;
$stlModalProductsWidthMedium: calc(40% - #{rem-calc(30)});
$stlModalProductsWidthLarge: calc(34% - #{rem-calc(30)});
$stlModalProductsWidthXLarge: calc(34% - #{rem-calc(30)});
$stlModalProductsMarginMedium: 0 rem-calc(10);
$stlModalProductsMarginXLarge: $stlModalProductsMarginMedium;
$stlModalProductsJustifyContentMedium: flex-start;
$stlModalHeadingContainerMarginMedium: 0;
$stlModalHeadingContainerDisplayMedium: flex;
$stlModalHeadingContainerjustifyContentMedium: center;
$stlModalHeadingContainerFlexWrapMedium: wrap;
$stlModalHeadingContainerH3DesktopSeparator: true;
$stlModalTopContainerBackgroundMedium: get-color(secondary);
$stlModalTopContainerH2ColorMedium: $white;
$stlModalTopContainerH2LineHeightMedium: 1;
$stlModalTopContainerH3ColorMedium: $white;
$stlModalTopContainerH3LineHeightMedium: 1;
$stlModalCloseButtonTopMedium: rem-calc(40);
$stlModalCloseButtonRightMedium: rem-calc(20);
$stlModalCloseButtonColorMedium: $white;
$stlModalBackgroundMedium: $white;
$stlModalShopTheLookControlsAddToCartMarginMedium: 0 0 0 rem-calc(15);
$stlModalShopTheLookControlsBackgroundMedium: get-color(sugar-cane);
$stlModalShopTheLookControlsDisplayMedium: flex;
$stlModalShopTheLookControlsFlexDirectionMedium: row;
$stlModalShopTheLookControlsAlignItemsMedium: flex-start;
$stlModalShopTheLookControlsAddToCartOrdernMedium: 5;
$stlModalViewMoreLooksLinkWidthMedium: 47%;
$stlModalViewMoreLooksLinkMarginLarge: 0;
$stlModalViewMoreLooksLinkBorderRadius: rem-calc(4);
$stlModalViewMoreLooksLinkMarginMedium: 0 auto;
$stlModalViewMoreLooksLinkMaxWidth: rem-calc(190);
$stlModalShopTheLookControlSubtotalDisplayMedium: none;
$stlModalShopTheLookControlSubtotalPaddingMedium: 0;
$stlModalShopTheLookControlSubtotalOrderMedium: 3;
$stlModalShopTheLookControlSubtotalProductSelectedDisplayMedium: block;
$stlModalShopTheLookControlSubtotalWidthMedium: 27%;
$stlModalShopTheLookControlsPaddingMedium: rem-calc(15) rem-calc(20);
$stlModalShopTheLookControlsModalMessageDisplay: none;
$stlModalShopTheLookControlsModalMessageWidth: 31%;
$stlModalShopTheLookControlsModalMessageDisplayMedium: flex;
$stlModalShopTheLookControlsModalMessageJustifyContentMedium: flex-end;
$stlModalShopTheLookControlsModalMessagePTextAlignMedium: left;
$stlModalShopTheLookControlsModalMessagePFontSizeMedium: rem-calc(16);
$stlModalShopTheLookControlsModalMessagePWidth: rem-calc(150);
$stlModalShopTheLookControlsAddToCartMinWidthMedium: rem-calc(140);
$stlModalViewMoreLooksLinkPaddingMedium: 0;
$stlBundleProductFormDisplayMedium: flex;
$stlBundleProductFormFlexDirectionMedium: row;
$stlBundleProductFormFlexWrapMedium: wrap;
$stlBundleProductFormBackgroundImageWidthMedium: 47%;
$stlBundleProductFormInnerContainerWidthMedium: 53%;
$stlBundleProductFormShopTheLookControlsWidthMedium: 100%;
$stlBundleProductFormProductScrollableMaxHeightMedium: rem-calc(680);
$stlBundleProductFormProductScrollableOverflowYMedium: auto;

//---------------------------------------------------------------
// Klarna
//---------------------------------------------------------------
$klarnaMessageMarginMedium: rem-calc(14) rem-calc(14) rem-calc(14) 0;
$klarnaLogoMargin: 0 0 0 rem-calc(3);
$klarnaLogoBackgroundColor: transparent;

$FranklinCondFontStyle: $header-font-family;

//---------------------------------------------------------------
// Smart Gift
//---------------------------------------------------------------
$smartGiftLauncherButtonColor: $white;
$smartGiftLauncherButtonBackground: get-color(secondary);
$smartGiftLauncherFlexBasisMedium: auto;
$smartGiftLauncherButtonBorderColor: transparent;

// Float Labels
$floatLabelTopAfter: 1.6em;
$floatLabelActiveTop: -1.6em;
$floatLabelFormValidationIconMarginLeft: rem-calc(-30);
$floatLabelCollectionPointInputContainerFloat: left;
$floatLabelCollectionPointInputContainerWidth: 100%;
$floatLabelCollectionPointInputContainerWidthMedium: grid-column(8);
$floatLabelSalutationFloat: left;
$floatLabelValidationErrorColor: $form-label-color-invalid;
$floatLabelErrorDivPaddingBottom: 15px;
$floatLabelErrorDivPaddingBottomMedium: 0;
$floatLabelPhoneInputWidthMedium: 262px;
$floatLabelProfileLabelEmailContainerWidth: 100%;
$floatLabelProfileLabelEmailContainerWidthMedium: 100%;
$floatLabelProfileNewPasswordtoggleMarginRight: 27px;
$floatLabelProfileSelectPaddingTop: rem-calc(12);
$floatLabelProfilePaddingBottom: 0;
$floatLabelProfileIconFloatRight: right;
$floatLabelProfilePaddingRight: rem-calc(5);
$floatLabelProfilePosition: inherit;
$floatLabelInputErrorPaddingTop: rem-calc(10);
$floatLabelRegistrationClear: both;
$floatLabelOrderLookupPaddingTop: rem-calc(15);
$floatLabelOrderNumberContainerPaddingTop: rem-calc(15);
$price-total-font-family: get-font('franklin-gothic');
$price-total-min-width-small: auto;
$price-total-min-width-medium: auto;
$price-total-margin-right-small: rem-calc(4);
$price-total-margin-right-medium: rem-calc(8);
$history-lowest-price-show-font-size-small: rem-calc(14) !important;
$price-total-percentage-font-weight-small: 700 !important;
$history-price-color: get-color(secondary);
$history-lowest-price-show-title-min-width-small: auto;
$history-lowest-price-show-title-min-width-medium: auto;
$history-lowest-price-show-title-margin-right-small: rem-calc(5);
$history-lowest-price-show-title-margin-right-medium: rem-calc(8);
$product-price-span-display: inline-flex !important;
$history-current-price-line-height-small: rem-calc(20) !important;
$price-total-line-height-small: rem-calc(20) !important;
$history-current-price-line-height-medium: rem-calc(24) !important;
$price-total-line-height-medium: rem-calc(24) !important;
$price-total-font-weight-small: 700 !important;
$plp-sticky-sidebar__inner-transform: translate3d(0, 0, 0);
$plp-sticky-sidebar__inner-will-change: position, transform;
$history-current-price-font-size-small: rem-calc(18) !important;
$history-lowest-price-show-display: flex !important;
$history-lowest-price-pdp-klarna-padding-small: 0 0 rem-calc(18) 0;
$history-lowest-price-pdp-klarna-padding-medium: 0;
$history-lowest-price-pdp-klarna-margin-medium: 0 0 rem-calc(10) 0 !important;
$product-block-views-container-min-height: rem-calc(199);
$product-block-views-container-min-width: rem-calc(190);
$plpProductBlockShowArrowsOnHoverOnly: true;
$swiper-viewer-placeholder-height: auto;
$pdpBreadCrumPadding: rem-calc(24) rem-calc(10) rem-calc(4) rem-calc(10);
$pdpBreadCrumPaddingMedium: rem-calc(20);
$icon-checkmark: $icon-checkmark-inside-circle;
$drawer-close-btn-container-float: right;
$drawer-close-btn-container-width: auto;
$range-slider-margin: rem-calc(20) rem-calc(13);
$filterLinkTextTransform: uppercase;
$product-reviews-container-font-family: get-font('franklin-gothic');
$range-slider-margin: rem-calc(20) rem-calc(13);
$topnav-accordion-display__medium: none;
$topnav-accordion-background__medium: $white;
$topnav-utility--float__medium: none;
$topnav-utility-item-label-display-medium: block;
$topnav-utility-item-float-medium: unset;
$topnav-cart-height__medium: auto;
$topnav-accordion-indicator-display-medium: inline-block;
$topnav-accordion-l1-medium-width: 100%;
$topnav-accordion--width__medium: 100%;
$topnav-dropdown-display: block;
$topnav-dropdown-width-medium: 100% !important;
$topnav-accordion-l2--padding__medium: rem-calc(10);
$topnav-search-container-flex__medium: 100%;
$topnav-search-container-display__medium: none;
$topnav-cart-height__tablet: rem-calc(53);
$topnav-dropdown-close-btn-display-tablet: none;
$topnav-utility-item-height--medium: rem-calc(24) !important;
$topnav-accordion-l1-tablet-display: 100%;
$pdpCrossSellOriginalPriceTextDecoration: unset;
$pdpCrossSellOriginalPriceStriked: line-through;
$pdpCrossSellPriceFontSize: rem-calc(13);
$pdpCrossSellPriceOrder: unset;
// DO NOT ADD ANYTHING BELOW THIS IMPORT
// See _variables-alt.scss for a detailed
// explanation
// -------------------------------------
@import 'variables-alt';
// ------------------------------------
