// PayPal Button Styles
// Inspired by styling set here https://www.paypal.com/buttons/smart

$paypalButtonBorder: null !default;
$paypalButtonMaxWidth: rem-calc(200) !default;
$paypalButtonPadding: rem-calc(10) 0 !default;
$paypalButtonTextColor: null !default;
$paypalButtonTextVerticalAlign: null !default;
$paypalButtonTextDecoration: null !default;
$paypalButtonTextFontFamily: null !default;
$paypalButtonTextFontSize: null !default;
$paypalButtonTextFontSizeMobile: null !default;
$paypalButtonTextFontWeight: null !default;
$paypalButtonTextLetterSpacing: null !default;
$paypalButtonTextLetterSpacingMobile: null !default;
$paypalTextTransform: null !default;

@mixin paypal-button {

// set PayPal colors
$paypalColors: (
    'silver': #eeeeee,
    'gold': #ffc439,
    'blue': #0070ba,
    'black': #000000,
    'white': #ffffff
  );

    .paypal-button {
        border: $paypalButtonBorder;
        display: block;
        padding: $paypalButtonPadding;
        text-align: center;
        text-decoration: $paypalButtonTextDecoration;

        // loop, to style Paypal Buttons
        @each $name, $color in $paypalColors {
            // Paypal Button Colors
            &-#{$name} {
                background-color: $color;

                @if ($name == 'blue' or $name == 'black') {
                    color: $white;
                } @else {
                    color: $black;
                }

                &:active,
                &:focus,
                &:hover {
                    background-color: darken($color, 5);
                    color: $paypalButtonTextColor;
                }
            }
        }

        // Paypal Button Shape
        &-pill {
            border-radius: 23px;
        }

        &-text {
            color: $paypalButtonTextColor;
            font-family: $paypalButtonTextFontFamily;
            font-size: $paypalButtonTextFontSizeMobile;
            font-weight: $paypalButtonTextFontWeight;
            letter-spacing: $paypalButtonTextLetterSpacingMobile;
            text-transform: $paypalTextTransform;
            vertical-align: $paypalButtonTextVerticalAlign;

            @include breakpoint (medium) {
                font-size: $paypalButtonTextFontSize;
                letter-spacing: $paypalButtonTextLetterSpacing;
            }
        }
    }

    // For Customs
    .mini-cart-action-paypal-button {
        &.paypal-button {
            // loop, to style Paypal Buttons
            @each $name, $color in $paypalColors {
                // Paypal Button Colors
                &-#{$name} {
                    background-color: $color;
                }
            }
        }
    }

    // PayPal Button on Billing page PayPal Section
    .paypal-display-container .paypal-button {
        @include breakpoint (medium) {
            max-width: $paypalButtonMaxWidth;
        }
    }
}
