// ==========================================================================
//	 Article Detail Page layout
//	 Fancy 2 column-right with an extra left gutter area
//	 CMS CSS @ Rosetta
// ==========================================================================


	// ================
	//  Main Wrap
	// ================
	#cd-main-wrap { // mobile first
		@include clearfix;
		padding-top: 0;
		padding-bottom: 35px;

		@include breakpoint(medium) { // tablet and up
			padding-top: 25px;
      		padding-bottom: 35px;
		}

		@include breakpoint(large) { // desktop and up
			padding-top: 50px;
	  		padding-bottom: 50px;
		}
	}

	// ================
	//  Content Wrap
	// ================
	#cd-content-wrap {
		@include breakpoint(medium) {
			// styled border for medium and up screens
			border-right: 1px solid get-color(feta);
		}
	}

	// ================
	//  Content
	// ================
	#cd-content {
		@include breakpoint(large) { // Extra padding for desktop
			padding-right:	$column-gutter*3;
		}

		.cd-read-more {
		  margin-bottom: 35px;

		  div {
		  	  border-bottom: 1px dotted get-color(light-grey);
		  	  border-top: 1px dotted get-color(light-grey);
			  font-size: em-calc(12);
			  padding: 15px 0;
			  text-align: center;

			  a {
				 &:after {
				 	padding-left: 10px;
					 @include icomoonFont($icon-arrow-down-indicator);
				 }
			  }
		  }
		}
	}

	// ================
	//  SideBar
	// ================
	#cd-sidebar {
		.sidebar {
			margin-left: 20px;
			margin-right: 20px;
		}
	}

	// ================
	//  Gutter
	// ================
	#cd-gutter {
		.other-content {
			margin-top: 210px;
		}
	}

	// ================
	//  Hero
	// ================

	.cd-hero {
	  margin-bottom: 25px;

	  img {
		  width: 100%;
		  height: auto;
	  }

	  #cat-hero {
	      padding: 0px;
	      width: 100%;
	  }
	}
