@import 'global/z-indexes';
// ****
// * PdP Video player
// * This is for the alt-images and product-image Video
// *
// ****

$include_html_wcs_video_player_classes: false !default;

$wcs_video_player_icon_color: #e72b1e !default;
$wcs_video_player_max_width: 72px !default;
$wcs_video_player_max_height: 72px !default;
$wcs_video_player_margin_top: 1px !default;
$wcs_video_player_margin_left: 1px !default;
$wcs_video_player_tablet_margin_left: 1px !default;
$wcs_video_player_icon_font_size: 46px !default;
$wcs_video_player_icon_Line_Height: 74px !default;
$wcs_video_player_container_top: 0 !default;
$wcs_video_player_container_top_mobile: 0 !default;
$wcs_video_player_tablet_max_width: 72px !default;
$wcs_video_player_tablet_min_width: 72px !default;

// YouTube Video button display
$wcs_video_player_mobile_button_float: right !default;
$wcs_video_player_mobile_button_padding_right: 1em !default;
$wcs_video_player_mobile_button_margin_top: -25px !default;
$wcs_video_player_mobile_button_font_size: 40px !default;
$wcs_video_player_mobile_button_color: #7f7f7f !default;
$wcs_video_player_margin_bottom: null !default;
$wcs_video_player_margin_right: null !default;
$wcs_video_player_text_align: null !default;
$wcs_video_player_mobile_positioning: null !default;
$wcs_video_player_mobile_zindex: null !default;
$wcs_video_player_mobile_right_position: null !default;
$wcs_video_player_button_640_plus_display: none !default;
$wcs_video_player_button_1024_plus_display: none !default;
$wcs_video_player_button_display: null !default;

$wcs_video_player_desktop_button_margin_top: 5px !default;
$wcs_video_player_desktop_button_font_size: 50px !default;

$wcs_video_player_control_bar_display_property: null !default;

$wcs_video_player_background_color_mobile: null !default;
$wcs_video_player_background_color: null !default;
$wcs_video_player_container_background: #000 !default;

$wcs_video_player_thumbnail_image_background_size: 100% !default;
$wcs_video_player_thumbnail_image_display: block !default;
$wcs_video_player_thumbnail_image_position: relative !default;
$wcs_video_player_thumbnail_icons_background: rgba(0, 0, 0, 0.3) !default;
$wcs_video_player_thumbnail_icons_display: block !default;
$wcs_video_player_thumbnail_icons_opacity: 0.9 !default;
$wcs_video_player_thumbnail_icons_text_align: center !default;
$wcs_video_player_thumbnail_icons_hover_background: rgba(0, 0, 0, 0.1) !default;
$wcs_video_player_thumbnail_icons_hover_opacity: 1 !default;
$wcs_video_player_icons_cursor: pointer !default;
$wcs_video_player_youtube_container_margin_top: 22% !default;
$wcs_video_player_mobile_youtube_container_margin_top: 22% !default;
$wcs_video_player_icons_close_width: null !default;
$wcs_video_player_icons_close_bkgd: null !default;
$wcs_video_player_icons_close_align: null !default;

$wcs_video_player_close_button_margin: 0 !default;

@mixin wcs_video_player_background_color {

		background-color: $wcs_video_player_background_color_mobile;

		@include breakpoint(large) {
			background-color: $wcs_video_player_background_color;
		}
	}

		@mixin wcs_video_player_youtube_container {
			box-sizing: border-box;
			position: absolute;
			top: $wcs_video_player_container_top_mobile;
			z-index: z-index(carousels) + 5; // We have to make it above the .s7controlbar which has z-index(carousels) + 4

			@include breakpoint(medium) {
				top: $wcs_video_player_container_top;
			}

			.vjs-control-bar {
				display: $wcs_video_player_control_bar_display_property;
			}

			#youtube-product-video-container,
			.youtube-product-video-container-dimensions,
			.youtube-product-video-container {
				margin-top: $wcs_video_player_mobile_youtube_container_margin_top;

				@include breakpoint(medium) {
					margin-top: $wcs_video_player_youtube_container_margin_top;
				}

				&.vjs-fullscreen {
                    margin-top: 0;
                }
			}
		}

		@mixin wcs_video_player_pdp_background_color {
			background: $wcs_video_player_container_background;
		}

			@mixin wcs_video_player_thumbnail_image {
				background-size: $wcs_video_player_thumbnail_image_background_size;
				display: $wcs_video_player_thumbnail_image_display;
				position: $wcs_video_player_thumbnail_image_position;
			}

			@mixin wcs_video_player_thumbnail_icons {
				background: $wcs_video_player_thumbnail_icons_background;
				color: $wcs_video_player_icon_color;
				display: $wcs_video_player_thumbnail_icons_display;
				font-size: $wcs_video_player_icon_font_size;
				opacity: $wcs_video_player_thumbnail_icons_opacity;
				text-align: $wcs_video_player_thumbnail_icons_text_align;

				&:hover {
					background: $wcs_video_player_thumbnail_icons_hover_background;
					opacity: $wcs_video_player_thumbnail_icons_hover_opacity;
				}
			}

			@mixin wcs_video_player_button {
				@include breakpoint(medium) {
					display: $wcs_video_player_button_640_plus_display;
				}

				@include breakpoint(large) {
			    	display: $wcs_video_player_button_1024_plus_display;
			    }
			}

			@mixin wcs_video_player_icons {
			    color: $wcs_video_player_mobile_button_color;
			    cursor: $wcs_video_player_icons_cursor;
			    display: $wcs_video_player_button_display;
			    float: $wcs_video_player_mobile_button_float;
			    font-size: $wcs_video_player_mobile_button_font_size;
			    margin-bottom: $wcs_video_player_margin_bottom;
			    margin-right: $wcs_video_player_margin_right;
			    margin-top: $wcs_video_player_mobile_button_margin_top;
			    padding-right: $wcs_video_player_mobile_button_padding_right;
			    position: $wcs_video_player_mobile_positioning;
			    right: $wcs_video_player_mobile_right_position;
			    text-align: $wcs_video_player_text_align;
			    z-index: $wcs_video_player_mobile_zindex;

			    @include breakpoint(medium) {
			    	margin-top: $wcs_video_player_desktop_button_margin_top;
			    	font-size: $wcs_video_player_desktop_button_font_size;
			    }
			}



@if $include_html_wcs_video_player_classes != false {
	.video-js .vjs-tech {
		@include wcs_video_player_background_color;
	}

	#product-views-container,
	#quickview-box {
		.youtube-video-container,
		.youtube-video-container .video-js {
			@include wcs_video_player_youtube_container;
		}

		.pdp-video-container {
			@include wcs_video_player_pdp_background_color;
		}

		.product-video-thumb {
			> .product-video-thumb-img {
				@include wcs_video_player_thumbnail_image;
			}

			span.icon-youtube,
			span.icon-arrow-right-indicator {
				@include wcs_video_player_thumbnail_icons;
			}
		}
	}

	.product-views-container {
		.product-video-button {
			@include wcs_video_player_button;

			&.icon-close,
			&.icon-youtube,
			&.icon-arrow-right-indicator {
				@include wcs_video_player_icons;
			}

			&.icon-close {
				background-color: $wcs_video_player_icons_close_bkgd;
				margin: $wcs_video_player_close_button_margin;
				padding: 0;
				text-align: $wcs_video_player_icons_close_align;
				width: $wcs_video_player_icons_close_width;
			}
		}
	}
}
