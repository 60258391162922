@import 'global/rightpanels';

#global-cart-wishlist-panels .notification::before,
#global-cart-wishlist-panels::before {
	right: 126px;
}

.grid {
	#global-cart-wishlist-panels .notification::before,
	#global-cart-wishlist-panels::before {
		right: 180px;
	}
}


.icon-shopcart {
	font-size: 12px;
}

.icon-wishlist {
	font-size: 9px;
}
