@import 'global/e-spot';

.page-util {
	#espot-header {
	//@include grid-column-f4($columns:12, $float:none);

		.espot-container {
			padding: 0;
		}
	}
}

// PROMO BANNER
.promo-content {

	.controls {

        .prev,
        .next {
            &::before {
                @include icomoonFont();
                display: block;
                font-size: 1em;
                left: 0;
                margin-top: -0.5em;
                position: absolute;
                text-align: center;
                top: 50%;
                width: 100%;
            }
        }

        .prev {
            &::before {
                @include icomoonFont($icon-arrow-left-indicator-open, false); //icon-arrow-left-indicator-open
            }
		}

        .next {

            &:before {
                @include icomoonFont($icon-arrow-right-indicator-open, false); //icon-arrow-right-indicator-open
            }
		}
	}
}
