
// Standard styling for the <h3> tag on most components
@mixin component-headline() {
	.component-title {
		.header {
			@include setFontStyle($FranklinCondFontStyle);
			font-size: em-calc(40);
			margin-bottom: 40px;
			text-transform: uppercase;
			line-height: em-calc(40, 40);
			letter-spacing: em-calc(3.60, 40);
		}

		.subheader {

		}

		.accent {
			@include setFontStyle($FranklinCondFontStyle);
			color: get-color(primary);
			font-size: em-calc(14);
			text-transform: uppercase;
			font-weight: normal;
			margin-bottom: 0px;
		}
	}
}
