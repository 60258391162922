// Related Content Component

@mixin vans-related-content {
	section.relatedcontent {

		.cd-related-item {
			img {
				width: 100%; // Control image sizes with columns
			}

			//Thumbnail view
			&.thumbnail {
				margin-bottom: $column-gutter;
			}
		}

		// Vertical layout
		&.vertical {

			.garnish-full-width {
				&.top-pad-default {
					padding-top: 0;
				}

				&.bottom-pad-default {
					padding-bottom: 0;
				}
			}

			text-align: left;
		}

		// Default Horizontal 4 layout
		&.horizontal-4 {

		}

	}
}
