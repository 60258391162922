//
// ApplePay for Mini Cart
// This WCS component adds an ApplePay button to the layout of the Mini Cart
// The ApplePay button will be displayed underneath Checkout Button by default
//
// This Button is enabled via commerce VFStoreconfig properties below
//
//APPLEPAY.ENABLED = false
//MINI_SHOP_CART_APPLEPAY_ENABLED = false
//
//This variable must be enabled on brand for module to be activated
$include-applepay-minicart-classes: $include-html-classes !default;
//Apple Pay Button MiniCart
$applePayMiniCartOrTextMargin: null !default;
$applePayMiniCartButtonContainerMargin: null !default;
$applePayMiniCartButtonContainerPadding: null !default;
$applePayMiniCartPaypalButtonMaxWidth: null !default;
$applePayMiniCartApplePayButtonMaxWidth: null !default;
$applePayMiniCartApplePayButtonPadding: rem-calc(27px) !default;
$applePayMiniCartPaypalButtonMarginBottom: null !default;
$applePayMiniCartVisaCheckoutButtonContainerMarginBottom: null !default;

@mixin applepay_on_minicart {
        .cart-actions-item #orText {
            margin: $applePayMiniCartOrTextMargin;
        }

        .mini-cart-applepay-button-container {
            float: left;
            margin: $applePayMiniCartButtonContainerMargin;
            padding: $applePayMiniCartButtonContainerPadding;
            position: relative;
            text-align: center;
            width: 100%;

            .mini-cart-paypal-btn {
                margin-bottom: $applePayMiniCartPaypalButtonMarginBottom;
                max-width: $applePayMiniCartPaypalButtonMaxWidth;
                width: 100%;

                #paypalPaymentButtonMiniCart.mini-cart-action-paypal-button {
                    padding: 0;
                }
            }

            //Visa Checkout
            .checkout-visacheckout-button-container {
                margin-bottom: $applePayMiniCartVisaCheckoutButtonContainerMarginBottom;
            }

            .apple-pay-button {
                display: none;
                height: 100%;
                margin: 0 auto;
                max-width: $applePayMiniCartApplePayButtonMaxWidth;
                padding: $applePayMiniCartApplePayButtonPadding;
                width: 100%;

               &.applepay-only {
                height: auto;
                position: relative;
                width: 100%;
               }
            }

            @include _applepay_button_defaults;
        }
}

// This mixin provides the overall styling for ApplePay Button on Mini Cart
@if $include-applepay-minicart-classes !=false {
    .mini-cart-actions .mini-cart-actions-item {
        @include applepay_on_minicart;
    }
}
