@import 'global/z-indexes';

$findStoreButtonBGColor: $brandColorPalette1 !default;
$in-store-avail-additional-stores-button-background-color: transparent !default;
$in-store-avail-additional-stores-button-color: null !default;
$in-store-avail-additional-stores-button-padding: convert-to-em(40) convert-to-em(30) convert-to-em(20) !default;
$find-in-store-icomoon-font-family: null !default;
$findStoreHeaderFontStyleMedium: $findStoreHeaderFontStyle !default;
$storeFinderColor: null !default;
$BGSectionMarginBottom: convert-to-em(30) !default;
$BGSectionPaddingBottom: convert-to-em(30) !default;
$BGSectionPaddingTop: convert-to-em(30) !default;
$store-finder-title-font-family: null !default;
$fontLabelSize: null !default;
$use-my-location-button-margin-top: null !default;
$store-details-direction-color: null !default;
$store-details-direction-font-weight: null !default;

.find-store-container {
    .store-finder-title {
        font-family: $store-finder-title-font-family;
    }
    @import 'catalog/color-swatches';
    @include grid-row;
    position: relative;
    margin-top: 40px;
    padding-top: 40px;
    border-top: $BGSectionSeperatorB;
    clear: both;

    form {
        @include clearfix;
    }

    .store-finder {
        @include grid-row;
		padding: 20px;

        .button {
			width: 100%;

			@include breakpoint(large) {
				@include grid-column(2);
				margin-bottom: 0;
				width: auto;
			}
        }
    }

    .input-set-wrap {
		@include breakpoint(medium) {
			float: none;
		 }

		label {
			@include grid-column(4);
            font-size: $fontLabelSize;
			line-height: get-form-input-height();
			text-align: center;
		}

        &.address label {
            display: none;
        }

        .zip-input-wrapper {
			float: left;
			width: 65%;

            .cityStateZip-city-state-error-text,
            .cityStateZip-zip-error-text {
                color: #ed1b2a;
                font-size: 0.8em;
                float: left;
            }

            .cityStateZip-error-msg,
            .zipcode-error-msg {
                color: #ed1b2a;
                font-size: 0.8em;
                margin-bottom: 0;
                clear: both;
            }

			#zipcode {
				width: 100%;
			}

			.error {
				clear: both;
			}
        }
    }

    .store-results-count {
        color: $fontGreyDark;
        font-size: 0.8em;
    }

    .store-results-list {
        margin: 30px 0;
        border-top: 1px $borderSolid;
        padding-top: 30px;

        .error {
            margin-top: convert-to-em(20);
        }

        aside {
            overflow: hidden;

            dl {
                margin-bottom: 0;
            }

            dt {
                @include setFontStyle($foundStoreDetailHeaders);
                font-size: convertPxToVw(header-font-size(h5, small), 320);
                @include breakpoint(medium) {
                    font-size: 1em;
                }
            }

            dd {
                color: $fontGreyDark;
                font-size: 0.8em;
                display: block;
                clear: both;
            }
        }
    }

    .store-locations {
        // mobile first
        @include grid-column(12, 0);

        dl {
            @include grid-column(12);
            padding: 32px 10px;

            &.selected {
                background-color: #fff;
            }
        }

        dt {
            float: left;
            width: 45%;
            text-align: left;
        }
        @include breakpoint(medium) {
            @include grid-column(6, 0);

            dt {
                float: left;
                width: 45%;
            }
        }
    }

    .store-details {
        @include grid-column($columns: 12);
        margin-left: 0;
        margin-top: 20px;

        dl {
            padding-bottom: 20px;
        }

        dd {
            overflow: hidden;
        }

        .direction,
        .tel {
            float: left;
            width: 50%;
        }

        .tel a {
            color: $anchor-color;

            &:focus,
            &:hover {
                color: $anchor-color-hover;
            }
            @include breakpoint(medium) {
                color: $fontGreyDark;

                &:focus,
                &:hover {
                    color: $fontGreyDark;
                }
            }
        }
        @include breakpoint(medium) {
            @include grid-column-collapse;
            float: $global-left;
            margin-left: grid-column(1);
            margin-top: 0;
            width: grid-column(4);
        }

        dl.hidden {
            & + dl.store-message-section {
                width: 100%;
            }
        }
    }
}
$findInStoreModelEnabled: false !default;
@if ($findInStoreModelEnabled) {
    $find-store-modal-spacing: convert-to-em(30) 0 !default;
    $find-store-modal-spacing-from-modal-edge: convert-to-em(30) !default;
    $find-store-modal-icon-size: 2em !default;
    $find-store-modal-second-col-left-spacing: convert-to-em(30) !default;
    $find-store-accordion-arrow: $icon-arrow-down-indicator-open !default;
    $find-store-accordion-arrow-opened: $icon-arrow-up-indicator-open !default;
    $find-store-accordion-arrow-color: #2a2a2a !default;
    $find-store-accordion-arrow-width: 24px !default;
    $find-store-accordion-arrow-height: 24px !default;
    $find-store-accordion-arrow-right: 30px !default;
    $find-store-or-BGColor: #eee !default;
    $find-store-additional-stores-button-icon: $icon-plus !default;
    $find-store-additional-stores-button-icon-expanded: $icon-minus !default;
    $find-store-button-margin-left: 0 !default;

    //hopup section

    .find-in-store-container {
        &.find-in-store-modal {
            padding-top: 3em;
        }

        #find-store-container {

            .store-finder {
                .input-set-wrap {
                    &.ship-to-store-radius {
                        @include grid-column(5, 0);
                        @include breakpoint(large) {
                            @include grid-column(2);
                        }

                        &.use-my-location-button-disabled {
                            @include grid-column-gutter();
                            float: $global-left;
                            margin-left: 0;
                            width: grid-column(12);

                            @include breakpoint(medium) {
                                width: 50%;
                            }

                            .ship-to-store-radius-lable {
                                width: 50%;
                            }
                        }
                    }

                    &.ship-to-store-zip {
                        @include grid-column(7, 0);
                        @include breakpoint(large) {
                            @include grid-column(3);

                            label {
                                @include grid-column(3, 0);
                            }

                            .zip-input-wrapper {
                                @include grid-column(9);

                                #zipcode {
                                    width: 100%;
                                }
                            }
                        }
                        @include breakpoint(large) {
                            width: 29%;
                        }

                        &.use-my-location-button-disabled {
                            @include grid-column-gutter();
                            float: $global-left;
                            margin-left: 0;
                            width: grid-column(12);

                            @include breakpoint(medium) {
                                width: 30%;
                            }

                            label {
                                width: 50%;
                                @include breakpoint(medium) {
                                    width: 25%;
                                }
                            }
                        }
                    }
                }

                .find-store-button {
                    margin-left: $find-store-button-margin-left;

                    @include breakpoint(large) {
                        width: 15%;
                    }

                    &.show-as-link {
                        background-color: transparent;
                        color: $anchor-color;
                        margin-bottom: 0;

                        &:focus,
                        &:hover {
                            color: $anchor-color-hover;
                        }
                    }
                }
            }

            .store-results-list {
                @include clearfix;
                float: left;
                margin: 0;
                padding-top: 0;
                width: 100%;
            }

            .additional-stores-button {
                background-color: $in-store-avail-additional-stores-button-background-color;
                color: $in-store-avail-additional-stores-button-color;
                padding: $in-store-avail-additional-stores-button-padding;
                display: inline-block;
                cursor: pointer;
                @include setFontStyle($additionalStoresButtonFontStyle);

                span.icon {
                    padding-left: 10px;
                }

                &.show-more {
                    span.icon::before {
                        @include icomoonFont($find-store-additional-stores-button-icon);
                    }
                }

                &.show-less {
                    span.icon::before {
                        @include icomoonFont($find-store-additional-stores-button-icon-expanded);
                    }
                }
            }

            .num-store-results {
                @include clearfix;
                padding: convert-to-em(30) $find-store-modal-spacing-from-modal-edge convert-to-em(20);
            }
        }
    }

    .find-in-store-accordion {
        @include grid-column(12, 0);

        & > section {
            border-bottom: $BGSectionSeperatorB;
            @include clearfix;

            .store-details {
                transition: opacity 1s ease-out;
                opacity: 0;
                height: 0;
                overflow: hidden;
            }

            &.opened {
                .store-details {
                    opacity: 1;
                    height: auto;
                }
            }
        }
    }

    .find-store-header {
        padding-left: $find-store-modal-spacing-from-modal-edge;
        padding-right: $find-store-modal-spacing-from-modal-edge;

        #store-finder-header {
            @include setFontStyle($findStoreHeaderFontStyle);
            color: $storeFinderColor;
            padding-bottom: 10px;
            @include breakpoint(medium) {
                @include setFontStyle($findStoreHeaderFontStyleMedium);
            }
        }

        p {
            padding-bottom: 10px;
        }
    }

    .find-store-result-section-title {
        border-bottom: $BGSectionSeperatorB;
        @include grid-column(12, 0);
        padding-left: $find-store-modal-spacing-from-modal-edge;
        padding-right: $find-store-modal-spacing-from-modal-edge;

        div {
            display: none;
            @include breakpoint(medium) {
                display: block;
                padding-bottom: convert-to-em(8);
            }
            @include grid-column(6, 0);

            &.find-store-result-status {
                padding-left: $find-store-modal-second-col-left-spacing;
            }
        }
    }

    //overwrites of mobile
    .find-in-store-container {
        #find-store-container {
            .store-details {
                float: $global-left;
                padding-left: $find-store-modal-spacing-from-modal-edge;
                padding-right: $find-store-modal-spacing-from-modal-edge;
                margin-left: 0;
                margin-top: 0;
                width: grid-column(12);

                .direction {
                    width: 100%;

                    a {
                        color: $store-details-direction-color;
                        font-weight: $store-details-direction-font-weight;
                        padding-left: 0;

                        span {
                            padding-right: convert-to-em(5);
                        }
                    }
                }

                //shows on mobile only
                .show-for-small-only {
                    .direction {
                        a {
                            margin-top: convert-to-em(10);
                            margin-bottom: convert-to-em(10);
                            padding: convertPxToVw(24.5px, $vwunit-base) convertPxToVw(30px, $vwunit-base);
                        }
                    }
                }

                dl {
                    padding-bottom: 0;
                }

                dt {
                    @include setFontStyle($foundStoreHeaders);
                }
            }

            .store-finder {
                .input-set-wrap {
                    label {
                        &.use-my-location-alternate-text {
                            width: 100%;
                            text-align: center;
                            border-bottom: $BGSectionSeperatorB;
                            line-height: 0.1em;
                            margin-bottom: 2rem;

                            span {
                                background: $find-store-or-BGColor;
                                padding: 0 convert-to-em(10);
                            }
                            @include breakpoint(large) {
                                width: 10%;
                                border-bottom: 0;
                                line-height: get-form-input-height();
                            }
                        }
                    }
                }

                .button.use-my-location-button {
                    @include breakpoint(large) {
                        margin-top: $use-my-location-button-margin-top;
                        width: 22.66667%;
                    }
                }
            }
        }

        .first,
        .last {
            @include breakpoint(medium) {
                @include grid-column(6, 0);
            }
        }

        .first {
            @include breakpoint(medium) {
                padding-right: convert-to-em(30);
                border-right: $BGSectionSeperatorB;
            }
        }

        .last {
            @include breakpoint(medium) {
                padding-left: $find-store-modal-second-col-left-spacing;
            }
        }

        .store-title {
            .first {
                padding-top: convert-to-em(15);
                padding-bottom: convert-to-em(15);
            }

            .last {
                padding-top: convert-to-em(15);
                padding-bottom: convert-to-em(15);
                @include breakpoint(medium) {
                    width: 43%;
                }
            }
        }

        .find-retail-store-product {
            @include grid-column(12, 0);
            margin-bottom: $BGSectionMarginBottom;
            border-bottom: $BGSectionSeperatorB;
            border-top: $BGSectionSeperatorB;
            padding-top: $BGSectionPaddingTop;
            padding-bottom: $BGSectionPaddingBottom;
            @include breakpoint(medium) {
                border-bottom: 0;
                border-top: 0;
                padding-top: convert-to-em(0);
                padding-bottom: convert-to-em(0);
            }

            .find-retail-store-product-image {
                @include grid-column(4, 0);
                display: table-cell;
                vertical-align: top;
                padding-right: convert-to-em(10);
            }

            .find-retail-store-product-description {
                @include grid-column(8, 0);
                display: table-cell;
                vertical-align: top;
            }
        }
    }

    section {
        > .store-title {
            cursor: pointer;
            overflow: auto;
            padding: convert-to-em(15) convert-to-em(30);
            position: relative;

            > .expand-icon {
                &::before {
                    @include icomoonFont($find-store-accordion-arrow);
                    padding: rem-calc(5);
                    font-family: $find-in-store-icomoon-font-family;
                }

                color: $find-store-accordion-arrow-color;
                font-size: $find-store-modal-icon-size/1.5;
                height: $find-store-accordion-arrow-height/1.2;
                position: absolute;
                right: rem-calc(5);
                top: 30px;
                @include breakpoint(medium) {
                    font-size: $find-store-modal-icon-size;
                    height: $find-store-accordion-arrow-height;
                    top: calc(50% - 24px / 2);
                    width: $find-store-accordion-arrow-width;
                }

                @include breakpoint(large) {
                    right: $find-store-accordion-arrow-right;
                }
            }
        }

        &.opened {
            > .store-title {
                padding-bottom: convert-to-em(0);

                > .expand-icon {
                    &::before {
                        @include icomoonFont($find-store-accordion-arrow-opened);
                        font-family: $find-in-store-icomoon-font-family;
                    }
                }
            }

            > .store-details {
                padding-bottom: convert-to-em(15);
            }


        }
    }

    .find-in-store-accordion > section > .store-title a {
        display: inline;
        white-space: initial;
    }

    .found-store-location-details {
        @include grid-row;
        padding-top: convert-to-em(18);

        .found-store-address,
        .found-store-distance {
            @include grid-column(6, 0);
        }
    }

    .found-store-name {
        @include setFontStyle($foundStoreNameFontStyle);
        padding-right: rem-calc(30px);
        @include breakpoint(medium) {
            padding-right: 0;
        }

    }

    .found-store-inventory-status {
        @include setFontStyle($foundStoreStatusFontStyle);
        @include breakpoint(medium) {
            padding-top: convert-to-em(18);
        }
    }
}
// end $findInStoreModelEnabled
