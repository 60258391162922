/////////////////////////////////////////////////////
// Autocomplete, extended version: three-area layout
// including category list, visual product list
// and banner
/////////////////////////////////////////////////////

$search-autosuggest-extended-dropdown-background-color: $white !default;
$search-autosuggest-extended-dropdown-box-shadow: 0 2px 3px rgba($black, 0.05) !default;
$search-autosuggest-extended-dropdown-font-size: em-calc(13px) !default;
$search-autosuggest-extended-dropdown-margin-left: 10px !default;
$search-autosuggest-extended-dropdown-padding: 25px 25px 45px !default;
$search-autosuggest-extended-dropdown-right: 10px !default;
$search-autosuggest-extended-dropdown-top: auto !default;
$search-autosuggest-extended-dropdown-top--medium: auto !default;
$search-autosuggest-extended-dropdown-z-index: z-index(topnav) + 1 !default;
$search-autosuggest-extended-categories-display: block !default;
$search-autosuggest-extended-categories-padding-right: 25px !default;
$search-autosuggest-extended-categories-width: 260px !default;
$search-autosuggest-extended-products-display: block !default;
$search-autosuggest-extended-products-width: auto !default;
$search-autosuggest-extended-products-width--medium: 350px !default;
$search-autosuggest-extended-terms-display: block !default;
$search-autosuggest-extended-terms-padding-right: 25px !default;
$search-autosuggest-extended-terms-width: $search-autosuggest-extended-categories-width !default;
$search-autosuggest-extended-terms-padding-left: $search-autosuggest-extended-terms-padding-right !default;
$search-autosuggest-extended-term-margin-top: 10px !default;
$search-autosuggest-extended-product-margin-top: 15px !default;
$search-autosuggest-extended-product-image-container-height: 110px !default;
$search-autosuggest-extended-product-image-container-vertical-align: middle !default;
$search-autosuggest-extended-product-image-container-width: 110px !default;
$search-autosuggest-extended-product-image-height: 100% !default;
$search-autosuggest-extended-product-image-width: 100% !default;
$search-autosuggest-extended-product-info-color: $black !default;
$search-autosuggest-extended-product-info-padding-left: 15px !default;
$search-autosuggest-extended-category-color: $black !default;
$search-autosuggest-extended-category-font-size: em-calc(15) !default;
$search-autosuggest-extended-category-line-height: em-calc(17) !default;
$search-autosuggest-extended-category-margin-top: 20px !default;
$search-autosuggest-extended-product-title-line-height: em-calc(17) !default;
$search-autosuggest-extended-product-title-text-transform: none !default;
$search-autosuggest-extended-product-price-display: block !default;
$search-autosuggest-extended-product-price-font-size: em-calc(14) !default;
$search-autosuggest-extended-product-price-font-weight: normal !default;
$search-autosuggest-extended-product-price-padding-top: 15px !default;
$search-autosuggest-extended-product-price-current-color: get-color(primary) !default;
$search-autosuggest-extended-product-price-original-margin-right: 5px !default;
$search-autosuggest-extended-header-font-size: em-calc(18) !default;
$search-autosuggest-extended-header-font-weight: bold !default;
$search-autosuggest-extended-header-text-align: left !default;
$search-autosuggest-extended-header-text-transform: uppercase !default;
$search-autosuggest-extended-term-list-font-size: em-calc(15) !default;
$search-autosuggest-extended-term-list-line-height: em-calc(17) !default;
$search-autosuggest-extended-category-counter-color: get-color(primary) !default;
$search-autosuggest-extended-category-counter-margin-left: 5px !default;
$search-autosuggest-extended-category-counter-after-content: ')' !default;
$search-autosuggest-extended-category-counter-before-content: '(' !default;
$search-autosuggest-extended-highlight-text-font-weight: bold !default;
$search-autosuggest-extended-search-all-bottom: 15px !default;
$search-autosuggest-extended-search-all-color: $black !default;
$search-autosuggest-extended-search-all-display: block !default;
$search-autosuggest-extended-search-all-font-size: em-calc(16) !default;
$search-autosuggest-extended-search-all-position: absolute !default;
$search-autosuggest-extended-search-all-text-transform: none !default;

@mixin search-autosuggest-extended {
	#AutoComplete {
		display: none;
	}

	.search-term-autocomplete-container {
		.search-term-autocomplete- {
			&dropdown {
				background-color: $search-autosuggest-extended-dropdown-background-color;
				box-shadow: $search-autosuggest-extended-dropdown-box-shadow;
				display: flex;
				flex-direction: row;
				font-size: $search-autosuggest-extended-dropdown-font-size;
				margin-left: $search-autosuggest-extended-dropdown-margin-left;
				padding: $search-autosuggest-extended-dropdown-padding;
				position: absolute;
				right: $search-autosuggest-extended-dropdown-right;
				top: $search-autosuggest-extended-dropdown-top;
				z-index: $search-autosuggest-extended-dropdown-z-index;

				@include breakpoint(medium) {
					top: $search-autosuggest-extended-dropdown-top--medium;
				}
			}

			&categories {
				display: $search-autosuggest-extended-categories-display;
				padding-right: $search-autosuggest-extended-categories-padding-right;
				width: $search-autosuggest-extended-categories-width;
			}

			&products {
				display: $search-autosuggest-extended-products-display;
				width: $search-autosuggest-extended-products-width;

				@include breakpoint(medium) {
					width: $search-autosuggest-extended-products-width--medium;
				}
			}

			&terms {
				display: $search-autosuggest-extended-terms-display;
				padding-left: $search-autosuggest-extended-terms-padding-left;
				width: $search-autosuggest-extended-terms-width;
			}

			&term {
				cursor: pointer;
				margin-top: $search-autosuggest-extended-term-margin-top;
			}

			&product_list,
			&term_list {
				display: flex;
				flex-direction: column;
			}

			&product {
				display: table;
				margin-top: $search-autosuggest-extended-product-margin-top;
			}

			&product-link {
				display: table-row;
			}

			&product-image-container,
			&product-info {
				display: table-cell;
			}

			&product-info {
				vertical-align: middle;
			}

			&product-image-container {
				height: $search-autosuggest-extended-product-image-container-height;
				vertical-align: $search-autosuggest-extended-product-image-container-vertical-align;
				width: $search-autosuggest-extended-product-image-container-width;
			}

			&product-image {
				height: $search-autosuggest-extended-product-image-height;
				width: $search-autosuggest-extended-product-image-width;
			}

			&product-info {
				color: $search-autosuggest-extended-product-info-color;
				padding-left: $search-autosuggest-extended-product-info-padding-left;
			}

			&category {
				color: $search-autosuggest-extended-category-color;
				display: block;
				font-size: $search-autosuggest-extended-category-font-size;
				line-height: $search-autosuggest-extended-category-line-height;
				margin-top: $search-autosuggest-extended-category-margin-top;
			}

			&product-title,
			&product-price {
				display: block;
			}

			&product-title {
				line-height: $search-autosuggest-extended-product-title-line-height;
				text-transform: $search-autosuggest-extended-product-title-text-transform;
			}

			&product-price {
				display: $search-autosuggest-extended-product-price-display;
				font-size: $search-autosuggest-extended-product-price-font-size;
				font-weight: $search-autosuggest-extended-product-price-font-weight;
				padding-top: $search-autosuggest-extended-product-price-padding-top;

				&-current {
					color: $search-autosuggest-extended-product-price-current-color;
				}

				&-original {
					margin-right: $search-autosuggest-extended-product-price-original-margin-right;

					&-discounted {
						text-decoration: line-through;
					}
				}
			}

			&category-header,
			&product-header,
			&term-header {
				font-size: $search-autosuggest-extended-header-font-size;
				font-weight: $search-autosuggest-extended-header-font-weight;
				text-align: $search-autosuggest-extended-header-text-align;
				text-transform: $search-autosuggest-extended-header-text-transform;
			}

			&category-title,
			&category-list,
			&product-title,
			&product-list,
			&term-title,
			&term-list {
				width: 100%;
			}


			&term-list {
				font-size: $search-autosuggest-extended-term-list-font-size;
				line-height: $search-autosuggest-extended-term-list-line-height;
			}

			&category-counter {
				color: $search-autosuggest-extended-category-counter-color;
				margin-left: $search-autosuggest-extended-category-counter-margin-left;

				&:after {
					content: $search-autosuggest-extended-category-counter-after-content;
				}

				&:before {
					content: $search-autosuggest-extended-category-counter-before-content;
				}
			}

			&highlight-text {
				font-weight: $search-autosuggest-extended-highlight-text-font-weight;
			}

			&search-all {
				bottom: $search-autosuggest-extended-search-all-bottom;
				color: $search-autosuggest-extended-search-all-color;
				display: $search-autosuggest-extended-search-all-display;
				font-size: $search-autosuggest-extended-search-all-font-size;
				position: $search-autosuggest-extended-search-all-position;
				text-transform: $search-autosuggest-extended-search-all-text-transform;
			}
		}
	}
}
