// ******
// * Graphic seperator strips
// ******
$feature-heading-width: 102; //raw width in pixels
$feature-heading-height: 65; //raw height in pixels

.garnish-wrapper {
	position: relative; // necessary for the absolute positioning of the garnish elements

	.hero-module {
		margin: auto 0 0;
	}
}

.feature-footing,
.feature-heading,
.feature-heading-shop {
  	display: table;
	height: #{$feature-heading-height}px;
	left: calc(50% - #{($feature-heading-width /2)}px);
	margin: auto;
	position: absolute;
	top: -#{($feature-heading-height / 2)}px;
	width: #{$feature-heading-width}px;
	z-index: 101;

	span {
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		color: $white;
		text-transform: uppercase;

		p {
			color: inherit;
			margin: auto;
			font-size: convert-to-em(12);
	        line-height: convert-to-em(16);
			position: relative;
			float: center;
			max-width: 69%;
			@include setFontStyle($FranklinCondFontStyle);
		}
	}
}

.feature-footing {
	top: initial;
	bottom: -#{($feature-heading-height / 2)}px;
}
