@mixin findInStoreModel() {
    $in-store-availability-autocomplete-item-font-family: null !default;
    $in-store-availability-autocomplete-item-font-size: null;
    $in-store-availability-autocomplete-container-background: null !default;
    $in-store-availability-autocomplete-text-color: $body-font-color !default;
    $in-store-availability-autocomplete-border-bottom: $topbar-border-bottom !default;
    $in-store-availability-autocomplete-list-item-padding-top: 0 !default;
    $in-store-availability-autocomplete-list-item-padding-bottom: 0 !default;
    $in-store-availability-autocomplete-list-item-padding-left: 0.76923em !default;
    $in-store-availability-autocomplete-list-item-padding-right: 0.76923em !default;
    $in-store-availability-autocomplete-list-item-margin-left: 25% !default;
    $in-store-availability-autocomplete-list-item-color: null !default;
    $in-store-availability-autocomplete-results-item-line-height: null !default;
    $in-store-availability-autocomplete-container-position-left: inherit !default;
    $in-store-availability-autocomplete-container-position-top: 255px !default;
    $in-store-availability-autocomplete-container-position-top-for-medium: 235px !default;
    $in-store-availability-autocomplete-container-position-top-for-small: 129px !default;
    $in-store-availability-autocomplete-container-zindex: 202 !default;
    $in-store-availability-autocomplete-container-width: 100% !default;
    $in-store-availability-autocomplete-container-width-mobile: 69% !default;

    //overwrites of mobile
    .in-store-availability-autocomplete {
        &-results {
            display: block;
            left: $in-store-availability-autocomplete-container-position-left;
            max-width: none;
            min-width: auto;
            @include grid-column(7);
            padding-bottom: 20px;
            position: absolute;
            top: $in-store-availability-autocomplete-container-position-top;
            z-index: 202;
            @include breakpoint(medium) {
                top: $in-store-availability-autocomplete-container-position-top-for-medium;
            }
            @include breakpoint(large) {
                @include grid-column(3);
                top: $in-store-availability-autocomplete-container-position-top-for-small;
            }
        }

        &-container {
            background: $in-store-availability-autocomplete-container-background;
            color: $in-store-availability-autocomplete-text-color;
            width: $in-store-availability-autocomplete-container-width-mobile;
            margin-left: 0;
            @include breakpoint(medium) {
                margin-left: calc(#{$in-store-availability-autocomplete-list-item-margin-left} + #{$in-store-availability-autocomplete-list-item-padding-left});
                width: $in-store-availability-autocomplete-container-width;
            }
        }

        &-list {
            @include grid-column-f4($columns: 12, $float: false);
            font-family: $in-store-availability-autocomplete-item-font-family;
            border-bottom: $in-store-availability-autocomplete-border-bottom;
            margin: 0;
            padding-bottom: $column-gutter/2;
            padding-top: $column-gutter/2;
            font-size: $in-store-availability-autocomplete-item-font-size;
            line-height: $in-store-availability-autocomplete-results-item-line-height;
            margin-bottom: 5px;
            list-style-type: none;

            &-item {
                @include grid-column-f4($columns: 12, $float: false, $collapse: true);
                color: $in-store-availability-autocomplete-list-item-color;
                cursor: pointer;
            }
        }
    }
}
