// ********************************
// *
// * Article Teaser Grid Component
// *
// * Displays CMS articles in a 4-across grid with
// * AJAX loadinging of additional rows when user
// * clicks on the CTA.
// *
// * Replaces The Wall (Small Layout)
// *
// ********************************

@import "cms/components/article-teaser-grid"; // Get shared component

@mixin vans-article-teaser-grid {
	section.article-teaser-grid {

		.articleteaser-filters {

			ul {
					float: left;
					padding: 0;
					margin: 0;
			}

			li {
					float: left;
					line-height: 40px;
			}
		}
	}
}
