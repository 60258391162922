// ==========================================================================
//   e-spot.scss
//   CSS for Marketing Spots or Content Spots
// ==========================================================================

$espotFullGridDescFontColor: #666 !default;
$espotSecondaryDescFontColor: #64686B !default;
$espotSplitBG: #F7F7F7 !default;
$espotFullGridDescFontSize: 13px !default;
$espotSplitDescFontSize: 15px !default;

.espot-container {
	clear: both;
}

#espot-header {
	@include grid-row();
	line-height: normal;
	text-align: center;

	.espot-container {
		vertical-align: middle;
		width: inherit;
	}
}

// =================
// Page Utility
// -----------------
.page-util {
	text-transform: uppercase;
	color: $promoBannerFontColor;
	background-color: $promoBannerBgColor;

	&:hover {
		color: $promoBannerFontColorHover;
		background-color: $promoBannerBgColorHover;

		#espot-header .controls {
			span {
				color: $promoBannerFontColor;
				background-color: $promoBannerBgColorHover;

				&:hover {
					background-color: $promoBannerControlBgHover;
				}
			}
		}
	}

	.offer { // red underline area
		font-weight: 700;
		border-bottom: 1px solid $brandColorPalette1;
		padding-bottom: 2px;
	}

	&-container {
		@include grid-row();
		overflow: hidden;
	}
}


// PROMO BANNER
.promo-content {
	@include setFontStyle($pageUtilNavContent);
	font-size: rem-calc($espotFullGridDescFontSize);
	letter-spacing: 0.08em;
	position: relative;
	padding: 0 $promoBannerControlWidth;

	// Styling used for when the espot is an owl carousel
	.item {

		a {
			background: $topbar-dropdown-bg-transparent;
			border-bottom: 1px solid $brandColorPalette1;
			color: $fontLink;
			font-style: inherit;
			padding: 0;

			&:hover {
				color: $fontLinkHover;
			}
		}
	}

	.controls {
		span {
			position: absolute;
			width: $promoBannerControlWidth;
			top: 0;
			bottom: 0;
			background-repeat: no-repeat;
			display: block;
			cursor: pointer;
			z-index: 1;
			line-height: $promoBannerLineHeightMobile;
			color: $promoBannerBgColor;

			@include breakpoint(medium) {
				line-height: $promoBannerLineHeight;
			}
		}

		.prev {
			left: 0;
		}

		.next {
			right: 0;
		}
	}
}

.espot-product-recommendation,
.espot-recently-viewed-otw {
	text-align: center;
	background-color: $white;
	float: none !important;
	margin-top: 80px;
	padding: 0 0 50px 0;
	position: relative;

	.espot-item-container {
		@include grid-row;
		margin: 0 auto;
		margin-top: 50px;
		display: inline-block;
	}

	.espot-item {
		@include grid-column-f4($columns: 5.9, $collapse: collapse, $float: none);
		display: inline-block;
		overflow: hidden;

		@include breakpoint(medium) {
			@include grid-column-f4($columns: 3.9, $collapse: collapse, $float: none);
		}

		@include breakpoint(large) {
			@include grid-column-f4($columns: 1.9, $collapse: collapse, $float: none);
		}



		a {
			display: block;
		}

		img {
			width: 95%;
		}
	}

	h3 {
		margin-top: -92px;
	}

	h6 {
		font-family: Helvetica, Arial, sans-serif;
		font-size: 0.85em;
		font-weight: normal;
		margin-top: $vertSpaceSmall;
	}

	.product-price {
		font-size: 0.85em;
		color: $brandColorPalette1;
	}

	.recommendation-title {
		@include grid-column($columns: 12);
		margin: 25px 0;
		top: 25px;
		@include setFontStyle($espotRecommendTitleFontStyle);
	}
}

.espot-product-recommendation {
	.espot-item-container {
		.espot-container {
			clear: none !important;
		}
	}
}

// =================
// E-Marketing Spot
// Storetelling Module
// -----------------
.espot-storytelling {
	display: block;
	float: none !important;
	padding: 0;
	margin: 0;
	position: relative;

	.bg {
		// Set rules to fill background
		min-height: 100%;
		min-width: 1280px;

		// Set up proportionate scaling
		width: 100%;
		height: auto;

		// Set up positioning
		position: relative;
		top: -10%;
	}

	article {
		@include setFontStyle($espotStoryTellingFontStyle);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 9;
		text-align: center;

		p {
			font-size: 1.2em;
			width: 90%;

			@include breakpoint(medium) {
				font-size: 1.4em;
				width: 90%;
			}

			@include breakpoint(large) {
				width: 45%;
            }

		}
	}
}

// **********************************
// * E-Marketing Spots for the Grid *
// **********************************

#catalog-container {
	.espot-customer-favorites {
		@include breakpoint(medium) {
			.feature-heading {
				position: relative;
				left: auto;
			}
		}
	}

	.grid-espot-container {
		@include grid-column(6);
		padding: 0;

		@include breakpoint(large) {
			@include grid-column(4);
		}
	}

	.catalog-results-small-set .espot-small-block {
		@include grid-column-f4($columns: 6, $collapse: collapse);
	}

	.espot-full-grid-block {
		@include grid-column($columns: 12);
		margin: 38px 0 38px 0;
		text-align: center;

		h4 {
			text-transform: uppercase;
			font-family: $header-font-family;
			font-size: convert-to-em(26);
			letter-spacing: convert-to-em(1.6);
		}

		p {
			@include grid-column-f4($columns: 10, $center: true);
			font-size: $espotFullGridDescFontSize;
			color: $espotFullGridDescFontColor;
			text-transform: none;
		}
	}
}

.espot-grid-split-recommendation {
	text-align: center;

	span {
		display: none;
	}
}

.not-available-online,
.customized-products {
	@include grid-column($columns: 12);
	text-align: center;
	text-transform: uppercase;

	@include breakpoint(medium) {
		@include grid-column($columns: 6);
	}

	img {
		@include grid-column-f4($columns: 8, $center: true);
	}

	.content {
		padding-top: 45px;
		padding-bottom: 45px;
		background-color: $espotSplitBG;

		h4 {
			font-family: $header-font-family;
			text-transform: uppercase;
			font-size: convert-to-em(26);
			letter-spacing: convert-to-em(1.6);
			font-weight: 700;
		}
	}

	h5 {
		font-family: $header-font-family;
		color: $espotFullGridDescFontColor;
		font-size: convert-to-em($espotSplitDescFontSize);
		font-weight: 400;
	}
}

.not-available-online {
	margin-bottom: 20px;

	@include breakpoint(medium) {
		margin-bottom: 10px;
	}

	img {
		@include grid-column-f4($columns: 10, $center: true);
		margin-bottom: 28px;
	}

	.secondary-desc {
		font-family: $secondaryFont;
		color: $espotSecondaryDescFontColor;
		font-size: $espotFullGridDescFontSize;
		text-transform: none;
		font-weight: normal;
	}
}

.dropdown {
	.page-util {
		background-color: $topbar-dropdown-bg-transparent;
		margin: 20px auto 0 auto;
		margin-bottom: - $topbar-dropdown-padding-bottom + 10px;
	}
}

.espot-clone {
	@include grid-row();
	@include grid-column-f4($columns: 12, $collapse: true, $float: false);
	clear: left;
	color: $white;
	display: none;
	text-align: center;

	@include breakpoint(medium) {
		display: block;
	}

	.promo-content {
		@include setFontStyle($promoContentFontStyle);

		.item {
			width: auto;
			padding: 0;
			color: $promoBannerFontColor;
			height: $promoBannerHeight;
			line-height: $promoBannerLineHeight;

			a {
				font-style: italic !important;
				border-bottom: 1px solid $brandColorPalette1;
			}
		}
	}

	.offer {
		font-weight: 700;
		border-bottom: 1px solid $brandColorPalette1;
		padding-bottom: 2px;
	}

	a {
		background: $topbar-dropdown-bg-transparent !important;
		color: $fontLink !important;
		font-style: inherit;
		padding: 0 !important;
		border-bottom: 1px solid $brandColorPalette1;

		&:hover {
			color: $fontLinkHover !important;
		}
	}
}

//product recommendations on pdp page.
.product-recommendations-container {
	display: none;

	@include breakpoint(medium) {
		display: block;
		position: relative;
	}

    .product-recommendations {
	    overflow: hidden;
	  	position: absolute;
    }
}

