@mixin vans-page-tags {
	.section.pagetags {
		h3 {
			font-family: get-font('franklin-gothic');
			font-size: em-calc(16);
			font-weight: normal;
			line-height: em-calc(22, 16);
			text-transform: capitalize;
		}
	}

	.tag {
		list-style: disc;
		list-style-position: inside;
		margin-bottom: 5px!important;
		margin-left: 0;

		li {
			color: get-color(primary);
			display: block;
			font-family: get-font('franklin-gothic');
			font-size: em-calc(16);
			font-weight: normal;
			letter-spacing: 0.08em;
			line-height: em-calc(22, 16);
			margin-right: 8px;

			a::after {
				color: $black;
				content: ',';
			}
		}

		li:last-of-type {
			a::after {
				content: '';
			}
		}
	}
}
