$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts/icomoon/fonts" !default;

$icon-pixelated-chevron-right: "\e94a";
$icon-pixelated-chevron-left: "\e94b";
$icon-chevron-up: "\e949";
$icon-right: "\e947";
$icon-left: "\e948";
$icon-new-chevron-down: "\e945";
$icon-new-menu: "\e946";
$icon-shopcart: "\e931";
$icon-full-screen: "\e941";
$icon-share: "\e600";
$icon-mail: "\e601";
$icon-facebook: "\e602";
$icon-twitter: "\e603";
$icon-tumblr: "\e604";
$icon-pinterest: "\e605";
$icon-googleplus: "\e606";
$icon-instagram: "\e607";
$icon-youtube: "\e608";
$icon-vimeo: "\e609";
$icon-play: "\e60a";
$icon-print: "\e60b";
$icon-arrow-right-indicator: "\e60c";
$icon-arrow-left-indicator: "\e60d";
$icon-arrow-down-indicator: "\e60e";
$icon-arrow-up-indicator: "\e60f";
$icon-close: "\e610";
$icon-search-old: "\e611";
$icon-arrow-down-indicator-open: "\e612";
$icon-arrow-up-indicator-open: "\e613";
$icon-arrow-right-indicator-open: "\e614";
$icon-arrow-left-indicator-open: "\e615";
$icon-flickr: "\e616";
$icon-picasa: "\e617";
$icon-wordpress: "\e618";
$icon-blogger: "\e619";
$icon-star: "\e61a";
$icon-shopcart-old: "\e61b";
$icon-menu: "\e61c";
$icon-vine: "\e61d";
$icon-account-old: "\e61e";
$icon-help-old: "\e61f";
$icon-wishlist-old: "\e620";
$icon-circle-with-plus: "\e621";
$icon-circle-with-minus: "\e622";
$icon-location-old: "\e623";
$icon-plus: "\e624";
$icon-check: "\e625";
$icon-wanelo: "\e626";
$icon-mobile: "\e900";
$icon-minus: "\e901";
$icon-button-full: "\e902";
$icon-lock: "\e903";
$icon-info: "\e904";
$icon-credit-card: "\e905";
$icon-edit: "\e906";
$icon-Exclamation-Triangle: "\e907";
$icon-map: "\e908";
$icon-handset: "\e909";
$icon-address: "\e90a";
$icon-payment: "\e90b";
$icon-favorite-filled: "\e90c";
$icon-favorite: "\e90d";
$icon-link: "\e90e";
$icon-applepay: "\e90f";
$icon-weibo: "\e910";
$icon-qq: "\e911";
$icon-chat: "\e912";
$icon-kakao: "\e913";
$icon-wechat: "\e914";
$icon-line: "\e915";
$icon-Check-Circle: "\e916";
$icon-bookmark: "\e917";
$icon-add-user: "\e918";
$icon-reset: "\e919";
$icon-camera: "\e91a";
$icon-zoom-in: "\e91b";
$icon-zoom-out: "\e91c";
$icon-background-color: "\e91d";
$icon-camera1: "\e91e";
$icon-crop: "\e91f";
$icon-flip: "\e920";
$icon-popart: "\e921";
$icon-redo: "\e922";
$icon-rotate: "\e923";
$icon-tile: "\e924";
$icon-tint: "\e925";
$icon-undo: "\e926";
$icon-zoom: "\e927";
$icon-line-logo: "\e928";
$icon-randomizer-check: "\e929";
$icon-x-inside-circle: "\e92a";
$icon-checkmark-inside-circle: "\e92b";
$icon-accessibility: "\e92c";
$icon-Logo-Customs: "\e92d";
$icon-long-arrow-right: "\e92e";
$icon-feather-loader: "\e92f";
$icon-question: "\e930";
$icon-gift: "\e932";
$icon-location: "\e933";
$icon-help: "\e934";
$icon-search: "\e935";
$icon-truck: "\e936";
$icon-account: "\e937";
$icon-vansfamilystar: "\e938";
$icon-wishlist: "\e939";
$icon-sign-out: "\e93a";
$icon-vans-finger: "\e93b";
$icon-vans-lock: "\e93c";
$icon-vans-present: "\e93d";
$icon-clear-search: "\e93e";
$icon-slim-search: "\e93f";
$icon-close-fat: "\e940";
$icon-syte-camera: "\e942";
$icon-gift-old: "\e99f";
$icon-bin: "\e9ac";
$icon-warning: "\ea07";
$icon-cross: "\ea0f";
$icon-sign-in: "\ea13";
$icon-sign-out-old: "\ea14";
$icon-success: "\f058";
$icon-error: "\f06a";
$icon-sort: "\e944";
$icon-filter: "\e943";

