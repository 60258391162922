$wallFiltersMarginTop: $vertSpace !default;
$formInputMarginBottom: 0 !default;
$articleListHeaderMarginTop: $vertSpace !default;
$radioControlsMarginBottomMobile: $vertSpace !default;
$radioControlsMarginBottom: 0 !default;
$searchSummaryMarginBottomMobile: $vertSpace !default;
$searchSummaryMarginBottom: 0 !default;

@mixin cms-search-results() {
  #article-list-header-content {
    margin-top: $articleListHeaderMarginTop;

    .articles-found-results-summary {
      .radio-controls-container,
      .article-search-input {
        @include flex-vertical-align-center;
        justify-content: center;
        margin-bottom: $radioControlsMarginBottomMobile;

        @include breakpoint(medium) {
          margin-bottom: $radioControlsMarginBottom;
        }

        input {
          margin-bottom: 0;
        }
      }
    }

    .search-results-summary {
      margin-bottom: $searchSummaryMarginBottomMobile;

      @include breakpoint(medium) {
        margin-bottom: $searchSummaryMarginBottom;
      }
    }

    .row:first-child {
      @include flex-vertical-align-center;
      flex-direction: column;
      justify-content: space-between;

      @include breakpoint(medium) {
        flex-direction: row;
      }

      &:before,
      &:after {
        content: none;
      }
    }

    .sort-by-section {
      .sort-by {
        .collapse {
          @include flex-vertical-align-center;
          flex-direction: row;
        }

        #sort-by {
          margin-bottom: $formInputMarginBottom;
        }
      }
    }

    .wall-filters {
      margin-top: $wallFiltersMarginTop;

      // inherit styles applied to the <li> instead of default link styles
      a {
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
      }
    }
  }
}
