@import "widgets/tooltipster";

$checkout-header-logo-alert-button-container-button-padding: null !default;
$checkout-header-logo-alert-button-container-button-text-transform: null !default;
$checkout-header-logo-alert-button-box-shadow: null !default;

.tooltipster-default {
  .tooltipster-content {
  	background-color: get-color(twilight-blue);
  	color: $medium-gray;
  }
}

.tooltipster-base {
	z-index: $reveal-zindex + 1;
}


.checkout {
	.tooltipster-shadow {

		box-shadow: $checkout-header-logo-alert-button-box-shadow;

		.tooltipster-content {
			.checkout-header-logo-alert-button-container {
				.button {
					padding: $checkout-header-logo-alert-button-container-button-padding;
					text-transform: $checkout-header-logo-alert-button-container-button-text-transform;
				}
			}
		}
	}
}
