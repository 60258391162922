
@import 'pikaday';

.pika-label {
    &::after {
      @include icomoonFont($icon-arrow-down-indicator);

      font-size: rem-calc(10);
      margin-left: rem-calc(5);
    }
  }