// ***
// * Team Members component
// ***

@mixin vans-team-members {
  section.teammembers {
    #cat-team-wrap {
      text-align: center;

      .vans-off-wall-label {
        display: block;
        margin: -10px auto 0 auto;
        position: relative;
        z-index: 100;
      }

      h3 {
        text-transform: uppercase;
        font-size: em-calc(40);
        color: get-color(secondary);
        text-align: center;
        letter-spacing: 0.1em;
      }

      .team-navigation {
        display: flex;
        flex-wrap: wrap;
        font-size: em-calc(12.48);
        justify-content: center;
        margin-bottom: 20px;

        a {
          border-bottom: 1px solid get-color(regent-st-blue);
          border-top: 1px solid get-color(regent-st-blue);
          color: $medium-gray;
          display: block;
          padding: 14px 0 14px 20px;

          &.active,
          &:hover {
            color: $black;
          }

          &:after {
            content: "/";
            color: get-color(feta);
            padding-left: 21px;

            @include breakpoint(medium) {
              padding-left: 35px;
            }
          }

          &:last-child {
            padding-right: 0;
            margin-right: 0;

            &:after {
              content: "";
            }
          }

          @include breakpoint(medium) {
            padding: 14px 0 14px 40px;
          }
        }
      }

      .team {
        margin-bottom: 30px;
      }

      .member {
        margin-bottom: $column-gutter;
        position: relative;

        &.hide {
          display: none;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
        }

        .name-wrap {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 0 $column-gutter / 2;
        }

        .name-content {
          display: table;
          height: 100%;
          width: 100%;
          text-align: center;
        }

        .name {
          display: table-cell;
          vertical-align: middle;
          text-transform: uppercase;
        }

        h6 {
          color: white;
          font-size: em-calc(15.56);
          line-height: em-calc(21.85, 15.56);
          margin: 0;
        }

        h5 {
          color: white;
          font-weight: 500;
          letter-spacing: 0.1em;
          font-size: em-calc(23.93);
          line-height: em-calc(21.85, 23.93);
          padding-left: $column-gutter/2;
          padding-right: $column-gutter/2;
          margin: 0;
        }
      }

      a.member {
        &:last-child {
          float: none;
        }
      }

      .cat-see-more {
          @include grid-column-f4($columns: 12, $float: false);
            text-align: center;
        margin-bottom: 40px;

          a {
            display: block;
            width: 168px;
            height: 42px;
            line-height: 42px;
            color: white;
            vertical-align: middle;
            text-align: center;
            margin: 0 auto;
            padding: 0!important;
          }
      }
      }
  }
}
