@import 'product/in-store-availability';

// Common style not being loaded
.found-store-location-details,
.store-details dd,
.find-retail-store-product-description {
    p {
        margin: 0;
    }
}

#find-store-container {
    @include grid-column-collapse;
    float: none;
    width: grid-column(12);

    @if ($findInStoreModelEnabled) {
        @include findInStoreModel();
    }

    border-top: 0;
    padding-top: 0;

    .store-results-list {
        border-top: 0;
    }

    .store-details {
        dd {
            address {
                font-style: normal;
            }
        }

        dl {
           @include grid-column(6, 0);
        }

        .store-hours-js {
            @include grid-column(12, 0);
        }

        .tel,
        .direction {
            @include grid-column(12, 0);
        }

        .show-for-small-only {
            .direction {
                a {
                    margin-left: 0 !important;
                }
            }
        }
    }

    .store-finder {
        margin-bottom: rem-calc(50);
    }

}
