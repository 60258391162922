// ================
//	Upcoming Events
// ================

@mixin vans-event-listing {
	section.eventslisting {

		//@include component-headline();

		//Event listing
		.cd-event-item-wrap {
			border-top: 1px solid get-color(feta);

			&:first-child {
				border: 0;
			}

			.cd-event-item-content {
				padding: 40px 11px;
				@include clearfix;
			}

			.event-image {
				@include grid-column($columns: 3);
				display: none;
				padding: 0;
				min-height: 260px;

				@include breakpoint(medium) {
					display: block;
				}
			}

			.store-short-description {
				@include grid-column-f4($columns: 12, $offset: 0);
				padding: 0 40px;

				@include breakpoint(medium) {
					@include grid-column-f4($columns: 5, $offset: 0.5);
					padding: 0;
				}

				.mobile-dates {
					display: inline;
					font-size: em-calc(18);
					line-height: em-calc(33, 18);

					@include breakpoint(medium) {
						display: none;
					}
				}

				.full-dates {
					display: none;

					@include breakpoint(medium) {
						display: block;
					}
				}

				.dates {
					font-size: em-calc(18);
					line-height: em-calc(39, 18);
					text-transform: uppercase;
					color: get-color(primary);

				sup {
					position: relative;
					top: -4px;

					@include breakpoint(medium) {
						top: -3px;
					}
				}

					.full-dates {
						font-size: em-calc(18);
					}
				}

				p {
					display: none;
					font-size: em-calc(13);
					line-height: em-calc(24, 13);
					color: $freeText;
					margin-bottom: 20px;

					@include breakpoint(medium) {
						display: block;
					}
				}

				.details-button {
					color: $white;
					display: none;

					@include breakpoint(medium) {
						display: block;
					}
				}
			}

			.event-location-time {
				@include grid-column-f4($columns: 12, $offset: 0);
				padding: 0 40px;

				.social-media {
					display: none;
				}

				@include breakpoint(medium) {
					@include grid-column-f4($columns: 2.5, $offset: 1);
					padding: 0;

					.social-media {
						display: block;
					}
				}

				.location-time {
					color: $freeText;
				}

				.location-time,
				a {
					font-size: em-calc(22);
					line-height: em-calc(37, 22);

					@include breakpoint(medium) {
						font-size: em-calc(13);
						line-height: em-calc(24, 13);
					}
				}

				a {
					&.show-on-map-link {
						&:after {
							@include icomoonFont($icon-location);
						}

						padding-left: 15px;

						&:hover {
							color: get-color(primary);
						}
					}
				}

				.details-button {
					display: inline-block;
					margin-top: 20px;
					font-size: em-calc(14, 13);
					width: 100%;

					@include breakpoint(medium) {
						display: none;
					}
				}
			}
		}

		.call-out-wrap {
			padding: 0;
			position: relative;
			background: black;
			overflow: hidden;
			border: 0 !important;

			.background {
				position: absolute;
				top: 0;
				width: 100%;
				height: 342px;
				opacity: 0.35;

				img {
					position: absolute;
					left: 50%;
					margin-left: -635px;
					width: 1270px!important;
					max-width: 1270px!important;
				}
			}

			.heading {
				color: $white;
			}

			.store-short-description {
				h3 {
					color: $white!important;
				}

				p {
					color: get-color(very-light-grey-2) !important;
				}
			}

			.location-time {
				color: get-color(very-light-grey-2) !important;
			}
		}

		.heading {
			@include setFontStyle($FranklinCondFontStyle);
			font-size: em-calc(36);
			line-height: em-calc(37, 36);
			margin-bottom: 0;

			@include breakpoint(medium) {
				font-size: em-calc(30);
				line-height: em-calc(34, 30);
				text-transform: uppercase;
			}
		}
	}

	#cd-event-list-wrap {
		padding: 0 0 80px;
		position: relative;
	}
}
