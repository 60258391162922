$giftCardNumberSeparator: null !default;
$giftCardCheckGroupTitleSize: 1.4em !default;
$giftCardCheckBalanceCardPadding: null !default;
$giftCardNumberErrorMargin: null !default;
$giftCardNumberErrorWidth: auto !default;
$giftCardBalanceInputMargin: 5% 0 !default;
$giftCardBalanceInputWidth: null !default;
$giftCardBalanceInputWidthLarge: 60% !default;
$giftCardHeadingFontSize: 1.4em !default;
$giftCardBalanceSectionBackgoundColor: null !default;
$giftCardBalanceContainerWidth: grid-column(6) !default;
$giftCardBalanceContainerMargin: 0 auto !default;
$giftCardInputMargin: 5% 0 !default;
$giftCardInputMarginLarge: 5% !default;
$giftCardInputWidth: null !default;
$giftCardInputWidthLarge: 50% !default;
$giftCardCheckShowPaddingLarge: null !default;
$giftCardSectionErrorMargin: null !default;
$giftCardSectionErrorMarginLarge: null !default;
$giftCardSectionRecaptchaMargin: null !default;
$giftCardSectionRecaptchaMarginLarge: null !default;
$giftCardSectionBackgoundColor: null !default;
$giftCardSectionBorderColor: null !default;
$giftCardSectionBorderWidth: null !default;
$giftCardSectionPadding: null !default;
$giftCardSectionPaddingLarge: null !default;
$giftcardCheckBalanceContainerPadding: 10px !default;
$gift-card-check-balance-show-expiry-cont-padding-left: 10% !default;
$gift-card-check-balance-show-expiry-text-font-size: null !default;
$giftCardHeadingPaddingRight: 10% !default;
$gift-card-check-balance-show: 5% !default;

@mixin gift-card-check-balance {
    .gift-card-check-balance {
        background-color: $giftCardBalanceSectionBackgoundColor;

        &-title {
            font-size: $giftCardCheckGroupTitleSize;
            margin-bottom: 10px;
        }

        &-input {
            float: left;
            height: 50px;
            margin: $giftCardBalanceInputMargin;
            width: $giftCardBalanceInputWidth;
        }

        &-submit {
            height: 50px;
            margin: 0;
        }

        &-show {
            align-items: center;
            display: flex;
            padding: $gift-card-check-balance-show;

            span {
                width: auto;
            }

            &-amount {
                font-size: $giftCardHeadingFontSize;
                margin-bottom: 15px;
                padding-right: $giftCardHeadingPaddingRight;
            }

            &-expiry-cont {
                flex-grow: 1;
                height: 50px;
            }
        }

        &-close {
            background: transparent;
            color: $black;
            cursor: pointer;
            margin: 0;
            padding: 0;

            &:hover {
                background: transparent;
                color: $black;
            }
        }
    }

    #giftCardNumber-error.error, #giftCardPIN-error.error {
        clear: both;
        float: left;
        line-height: 1.2;
        margin: $giftCardNumberErrorMargin;
        width: $giftCardNumberErrorWidth;
    }

    @include breakpoint(large) {
        @if $giftCardNumberSeparator {
            .gift-card-check-balance-show-expiry-cont {
                border-left: $giftCardNumberSeparator;
                padding-left: $gift-card-check-balance-show-expiry-cont-padding-left;
                .gift-card-check-balance-show-expiry-text {
                    font-size: $gift-card-check-balance-show-expiry-text-font-size;
                }
            }
        }

        .gift-card-check-balance {
            @include disable-input-box-number-spinners;

            &-container {
                max-width: $giftCardBalanceContainerWidth;
                margin: $giftCardBalanceContainerMargin;
            }

            &-input {
                margin-right: 5%;
                width: $giftCardBalanceInputWidthLarge;
            }

            &-submit {
                margin: 5% 0;
                width: 35%;
            }

            &-show {
                padding: $giftCardCheckBalanceCardPadding;
            }
        }

        #giftCardNumber-error.error {
            width: $giftCardNumberErrorWidth;

            span {
                padding: 0;
            }
        }
    }
}

@mixin check-gift-card-balance-cms {
    .wroot .gift-card-check-balance-container {
        padding: $giftcardCheckBalanceContainerPadding;

        .gift-card {
            &-check-balance {
                background-color: $giftCardSectionBackgoundColor;
                border-color: $giftCardSectionBorderColor;
                border-style: solid;
                border-width: $giftCardSectionBorderWidth;
                padding: $giftCardSectionPadding;

                &-submit {
                    height: 50px;
                    margin: 0;
                }

                &-error {
                    color: get-color(alert);
                    margin: $giftCardSectionErrorMargin;
                    line-height: 1.4;
                }
            }

            &-input {
                float: left;
                height: 50px;
                margin: $giftCardInputMargin;
                width: $giftCardInputWidth;
            }
        }

        .card {
            &-group {
                background-color: $giftCardSectionBackgoundColor;
                border-color: $giftCardSectionBorderColor;
                border-style: solid;
                border-width: $giftCardSectionBorderWidth;
                padding: $giftCardSectionPadding;
            }
        }

        .google-recaptcha-container {
            margin: $giftCardSectionRecaptchaMargin;
        }

        @include breakpoint(large) {
            width: 50%;
            margin: 0 auto;

            .card {
                &-group {
                    padding: $giftCardSectionPaddingLarge;
                }
            }

            .gift-card {
                &-check-balance {
                    padding: $giftCardSectionPaddingLarge;

                    &-submit {
                        margin: 5% 0;
                        width: 35%;
                    }

                    &-show {
                        padding: $giftCardCheckShowPaddingLarge;
                    }

                    &-error {
                        margin: $giftCardSectionErrorMarginLarge;
                    }
                }

                &-input {
                    margin: $giftCardInputMarginLarge;
                    width: $giftCardInputWidthLarge;
                }
            }

            .google-recaptcha-container {
                margin: $giftCardSectionRecaptchaMarginLarge;
            }
        }
    }
}
